import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./index.scss";
import Navbar from "./Components/Navbar/Navbar";
import Dashboard from "./Pages/Dashboard";
import WorkOrders from "./Pages/WorkOrders";
import AddWorkOrder from "./Pages/AddWorkOrder";
import RequestedOrder from "./Pages/RequestedOrder";
import AddPMCaregory from "./Pages/AddPMCaregory";
import PumpCalibration from "./Pages/PumpCalibration";
import InspectionCategory from "./Pages/InspectionCategory";
import Team from "./Pages/Team";
import Login from "./Pages/Login";
import ForgotPasswords from "./Pages/ForgotPassword";
import CreateInspectionCategory from "./Pages/CreateInspectionCategory";
import WashroomInspection from "./Pages/WashroomInspection";
import F3Washroom from "./Pages/F3Washroom";
import PageNotFound from "./Pages/PageNotFound";
import InspectionsResponses from "./Pages/InspectionsResponses";
import RoutineInspectionCategoryContextProvider from "./Context/Inspections/RoutineInspectionCategoryContextProvider";
import InspectionDetailContextProvider from "./Context/Inspections/InspectionDetailsContextProvider";
import { ProtectedRoute } from "./utls/protectedRouje";
import EditCategoryInspection from "./Components/InspectionCategory/Edit/EditCategoryInspection";
import EditInspectionCategory from "./Pages/EditInspectionCategory";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewRequest from "./Pages/NewRequest";
import Requests from "./Pages/Requests";
import PumpCalibration1000001 from "./Pages/ViewRequest";
import NewWork from "./Components/WorkOrders/NewWork";
import OngoingWork from "./Components/WorkOrders/OngoingWork";
import PendingClosure from "./Components/WorkOrders/PendingClosure";
import ClosedWork from "./Components/WorkOrders/ClosedWork";
import UpcomingWork from "./Components/WorkOrders/UpcomingWork";
import WorkOrderAssets from "./Components/WorkOrders/AddWorkOrder/WorkOrderAssets";
import WorkOrderDetails from "./Components/WorkOrders/AddWorkOrder/WorkOrderDetails";
import WorkOrderInformation from "./Components/WorkOrders/AddWorkOrder/WorkOrderInformation";
import WorkOrderSummary from "./Components/WorkOrders/AddWorkOrder/WorkOrderSummary";
import ViewWorkOrder from "./Pages/ViewWorkOrder";
import ViewSummary from "./Components/WorkOrders/ViewWorkOrder/ViewSummary";
import ViewAssets from "./Components/WorkOrders/ViewWorkOrder/ViewAssets";
import ViewCostings from "./Components/WorkOrders/ViewWorkOrder/ViewCostings";
import ViewJob from "./Components/WorkOrders/ViewWorkOrder/ViewJob";
import PreventiveWorks from "./Pages/PreventiveWorks";
import PreventiveNewWork from "./Components/PreventiveWorks/Table";
import PreventiveOngoingWorks from "./Components/PreventiveWorks/OngoingWork";
import PreventivePendingClouser from "./Components/PreventiveWorks/PendingClosure";
import PreventiveClosedWorks from "./Components/PreventiveWorks/ClosedWork";
import PreventiveUpcommingWorks from "./Components/PreventiveWorks/UpcomingWork";
import AddPmWork from "./Pages/AddPmWork";
import CreatePMWorkAssets from "./Components/PreventiveWorks/AddPmWorks/CreatePMWorkAssets";
import PMWorkDetails from "./Components/PreventiveWorks/AddPmWorks/PMWorkDetails";
import PMOrderInformation from "./Components/PreventiveWorks/AddPmWorks/PMOrderInformation";
import PMOrderSummary from "./Components/PreventiveWorks/AddPmWorks/PMOrderSummary";
import PMWorkScheduling from "./Components/PreventiveWorks/AddPmWorks/PMWorkScheduling";
import NoSchedule from "./Components/PreventiveWorks/AddPmWorks/WorkScheduling/NoSchedule";
import CreateSchedule from "./Components/PreventiveWorks/AddPmWorks/WorkScheduling/CreateSchedule";
import UseSchedule from "./Components/PreventiveWorks/AddPmWorks/WorkScheduling/UseSchedule";
import ScheduleWorks from "./Pages/WorkScheduleTemplates";
import CreateTemplate from "./Pages/CreateTemplate";
import WorkScheduleSummary from "./Components/WorkScheduleTemplate/CreateWorkSchedule/TemplateSummary";
import ScheduleTemplateDetails from "./Components/WorkScheduleTemplate/CreateWorkSchedule/TemplateDetails";
import WorkViewTemplate from "./Pages/WorkViewTemplate";
import EditTemplate from "./Pages/EditTemplate";
import ViewPmWorkOrders from "./Pages/ViewPmWork";
import ViewPmSummary from "./Components/PreventiveWorks/ViewPm/ViewPmSummary";
import ViewPmAssets from "./Components/PreventiveWorks/ViewPm/ViewPmAssets";
import ViewPmCostings from "./Components/PreventiveWorks/ViewPm/ViewPmCostings";
import ViewPmJobcards from "./Components/PreventiveWorks/ViewPm/VIewPmJobcards";
import ViewRequests from "./Pages/ViewRequest";
import ViewRequestSummary from "./Components/Requests/ViewRequests/ViewRequestSummary";
import ViewRequestDoagnosis from "./Components/Requests/ViewRequests/ViewRequestDoagnosis";
import ViewRequiestParts from "./Components/Requests/ViewRequests/ViewRequiestParts";
import ViewRequestQuotes from "./Components/Requests/ViewRequests/ViewRequestQuotes";
import DiagnosisQuotes from "./Pages/diagnosisQuotes";
import ViewDiagnosisQuotes from "./Pages/ViewDiagnosisQuotes";
import ViewDiagnosisQuotesSummary from "./Components/Diagnosis-Quotes/ViewRequests/ViewDiagnosisQuotesSummary";
import ViewDiagnosis from "./Components/Diagnosis-Quotes/ViewRequests/ViewDiagnosis";
import ViewDiagnosisQuotesParts from "./Components/Diagnosis-Quotes/ViewRequests/ViewDiagnosisQuotesParts";
import ViewDiagnosisQuotesQ from "./Components/Diagnosis-Quotes/ViewRequests/ViewDiagnosisQuotesQ";
import Locations from "./Pages/Locations";
import CreateLocation from "./Pages/CreateLocation";
import LocationDetails from "./Components/Locations/CreateNewLocations/LocationDetails";
import LocationSummary from "./Components/Locations/CreateNewLocations/LocationSummary";
import WorkCosts from "./Pages/WorkCosts";
import ViewWorkCostsSummary from "./Components/Work-Costs/ViewRequests/ViewWorkCostsSummary";
import ViewWorkCosts from "./Pages/ViewWorkCosts";
import ViewWorkCosting from "./Components/Work-Costs/ViewRequests/ViewWorkCosting";
import Reports from "./Components/Reports/Reports";
import PMCMReports from "./Components/Reports/PMCPMReport";
import UserPmPerformance from "./Components/Reports/UserPmPerformance";
import EquipmentPmPerformance from "./Components/Reports/EquipmentPmPerformance";
import WorkCostsReport from "./Components/Reports/WorkCostsReport";
import CategoryWorkCosts from "./Components/Reports/CategoryWorkCosts";
import EquipmentWorkCosts from "./Components/Reports/EquipmentWorkCosts";
import LocationWorkCosts from "./Components/Reports/LocationWorkCosts";
import ContractorWorkCosts from "./Components/Reports/ContraactorWorkCosts";
import ContractorPerformance from "./Components/Reports/ContractorPerformance";
import CorrectiveWorkStatus from "./Components/Reports/CorrectiveWorkStatus";
import PreventiveWorkStatus from "./Components/Reports/PreventiveWorkStatus";
import PMCMCategory from "./Components/Reports/PMCMCategory";
import OrgContractor from "./Components/Reports/OrgContractor";
import ContractorPerformanceSummary from "./Components/Reports/ContractorSummary";
import Assets from "./Pages/Assets";
import AssetsContent from "./Components/Assets/AssetsContent";
import AssetCategories from "./Components/Assets/AssetCategories";
import AssetTransfers from "./Components/Assets/AssetTransfers";
import CreateNewAsset from "./Pages/CreateNewAsset";
import CreateAssets from "./Components/Assets/CreateNewAsset/CreateAssets";
import CreateDocuments from "./Components/Assets/CreateNewAsset/CreateDocuments";
import CreateSummary from "./Components/Assets/CreateNewAsset/CreateSummary";
import AssetProfile from "./Pages/AssetProfile";
import ProfileSummary from "./Components/Assets/AssetsProfile/ProfileSummary";
import ProfileParts from "./Components/Assets/AssetsProfile/ProfileParts";
import ProfileWork from "./Components/Assets/AssetsProfile/ProfileWork";
import Assignment from "./Components/Assets/AssetsProfile/Assignment";
import RegionalPerformance from "./Components/Reports/RegionalPerformance";
import ViewLocation from "./Pages/ViewLocation";
import ViewLocationSummary from "./Components/Locations/ViewLocation/ViewSummary";
import ViewLocationAssets from "./Components/Locations/ViewLocation/ViewAssets";
import ViewStakeHolders from "./Components/Locations/ViewLocation/ViewStakeholders";
import ViewWork from "./Components/Locations/ViewLocation/ViewWork";
import RejectedWork from "./Components/WorkOrders/RejectedWork";
import WorkOrderDiagnosisPage from "./Components/WorkOrders/ViewWorkOrder/WorkOrderDiagnosis/WorkOrderDiagnosisPage";
import WorkOrderConfirmWorkPage from "./Components/WorkOrders/AddWorkOrder/WorkOrderConfirmWork/WorkOrderConfirmWorkPage";
import MyWork from "./Pages/MyWork";
import MyWorkNewRequests from "./Components/WorkOrders/MyWorkNewRequests";
// import WorkOrderDiagnosisPage from "./Components/WorkOrders/AddWorkOrder/WorkOrderDiagnosis/WorkOrderDiagnosisPage";

function App() {
  const [token, setToken] = useState(null);
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
      setToken(window.localStorage.getItem("bearerToken"));
      setTimeout(() => {
        document.documentElement.scrollTo(0, 0);
      }, 0);
    }, [location.pathname, location.search]);
    return children;
  };

  return (
    <RoutineInspectionCategoryContextProvider>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route
              //index
              path="/sign-in"
              element={
                <>
                  <Login />
                </>
              }
            />

            <Route path="/forgot-password" element={<ForgotPasswords />} />
            {/* Work Schedule Templates */}
            <Route
              path="/work-schedule-templates"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <ScheduleWorks />
                </div>
              }
            />
            <Route
              path="/work-schedule-templates/:id"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <WorkViewTemplate />
                </div>
              }
            />
            {/*  */}
            <Route
              path="/work-schedule-templates/create-template"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <CreateTemplate />
                </div>
              }
            >
              <Route
                index
                path="/work-schedule-templates/create-template"
                element={<ScheduleTemplateDetails />}
              />
              <Route
                path="/work-schedule-templates/create-template/summary"
                element={<WorkScheduleSummary />}
              />
            </Route>
            <Route
              path="/work-schedule-templates/edit-template/:id"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <EditTemplate />
                </div>
              }
            ></Route>
            {/* ------Locations------------ */}

            <Route
              path="/locations"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <Locations />
                </div>
              }
            />

            {/* -------Create-Locations */}

            <Route
              path="/locations"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <Locations />
                </div>
              }
            />

            <Route
              path="/locations/new-location"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <CreateLocation />
                </div>
              }
            >
              <Route
                index
                path="/locations/new-location"
                element={<LocationDetails />}
              />
              <Route
                path="/locations/new-location/summary"
                element={<LocationSummary />}
              />
            </Route>

            {/* -------View-Locations */}
            {/* -------View-Locations */}

            <Route
              path="/locations/view-location/:id"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <ViewLocation />
                </div>
              }
            >
              <Route
                index
                path="/locations/view-location/:id"
                element={<ViewLocationSummary />}
              />
              <Route
                index
                path="/locations/view-location/:id/assets"
                element={<ViewLocationAssets />}
              />
              <Route
                index
                path="/locations/view-location/:id/stakeholders"
                element={<ViewStakeHolders />}
              />
              <Route
                index
                path="/locations/view-location/:id/work"
                element={<ViewWork />}
              />
            </Route>

            <Route
              path="*"
              element={
                <>
                  <PageNotFound />
                </>
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route
                path="/"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <Dashboard />
                  </div>
                }
              />
              <Route
                index
                path="/requests"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    {/* <InspectionCategory /> */}
                    <Requests />
                  </div>
                }
              />
              <Route
                path="/my-work"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <MyWork />
                  </div>
                }
              />
              <Route
                path="/"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <WorkOrders />
                  </div>
                }
              >
               
                <Route
                  path="/work-orders"
                  element={
                    <div className="root-wrapper">
                      <Navbar />
                      <WorkOrders />
                    </div>
                  }
                />
                <Route index path="/work-orders/" element={<NewWork />} />
                <Route
                  path="/work-orders/ongoing-work"
                  element={<OngoingWork />}
                />
                <Route
                  path="/work-orders/pending-closure"
                  element={<PendingClosure />}
                />
                <Route
                  path="/work-orders/closed-work"
                  element={<ClosedWork />}
                />
                <Route
                  path="/work-orders/rejected-work"
                  element={<RejectedWork />}
                />
                <Route
                  path="/work-orders/upcoming-work"
                  element={<UpcomingWork />}
                />
              </Route>

              {/* my work */}
              <Route
                path="/"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <MyWork />
                  </div>
                }
              >
                {/* <Route index path="/" element={<NewWork />} /> */}

                <Route
                  path="/my-work"
                  element={
                    <div className="root-wrapper">
                      <Navbar />
                      <MyWork />
                    </div>
                  }
                />
                <Route
                  index
                  path="/my-work-new-requests/"
                  element={<MyWorkNewRequests />}
                />
                <Route path="/my-work/new-cm-work" element={<NewWork />} />
                <Route
                  path="/my-work/new-pm-work"
                  element={<PreventiveNewWork />}
                />
                <Route
                  path="/my-work/cm-pending-closure"
                  element={<PendingClosure />}
                />
                <Route
                  path="/my-work/rejected-cm-work"
                  element={<RejectedWork />}
                />
                <Route
                  path="/work-orders/rejected-work"
                  element={<RejectedWork />}
                />
                <Route
                  path="/work-orders/upcoming-work"
                  element={<UpcomingWork />}
                />

                  
              </Route>
              {/* //end my work */}

              <Route
                path="/"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <MyWork />
                  </div>
                }
              >
                {/* <Route index path="/" element={<NewWork />} /> */}

                <Route
                  path="/my-work"
                  element={
                    <div className="root-wrapper">
                      <Navbar />
                      <MyWork />
                    </div>
                  }
                />
                  <Route
                    index
                    path="/my-work/preventive-works"
                    element={<PreventiveNewWork />}
                  />
                  <Route
                    path="/my-work/preventive-works/ongoing-work"
                    element={<PreventiveOngoingWorks />}
                  />
                  <Route
                    path="/my-work/preventive-works/pending-closure"
                    element={<PreventivePendingClouser />}
                  />
                  <Route
                    path="/my-work/preventive-works/closed-work"
                    element={<PreventiveClosedWorks />}
                  />
                  <Route
                    path="/my-work/preventive-works/upcoming-work"
                    element={<PreventiveUpcommingWorks />}
                  />
              </Route>

              <Route
                path="/"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <MyWork />
                  </div>
                }
              >
                {/* <Route index path="/" element={<NewWork />} /> */}

                <Route
                  path="/my-work"
                  element={
                    <div className="root-wrapper">
                      <Navbar />
                      <MyWork />
                    </div>
                  }
                />
                  <Route index path="/my-work/work-orders" element={<NewWork />} />
                <Route
                  path="/my-work/work-orders/ongoing-work"
                  element={<OngoingWork />}
                />
                <Route
                  path="/my-work/work-orders/pending-closure"
                  element={<PendingClosure />}
                />
                <Route
                  path="/my-work/work-orders/closed-work"
                  element={<ClosedWork />}
                />
                <Route
                  path="/my-work/work-orders/rejected-work"
                  element={<RejectedWork />}
                />
                <Route
                  path="/my-work/work-orders/upcoming-work"
                  element={<UpcomingWork />}
                />
              </Route>

              <Route
                path="/work-orders/add-work-order"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <AddWorkOrder />
                  </div>
                }
              >
                <Route
                  index
                  path="/work-orders/add-work-order"
                  element={<WorkOrderAssets />}
                />
                <Route
                  path="/work-orders/add-work-order/details"
                  element={<WorkOrderDetails />}
                />
                <Route
                  path="/work-orders/add-work-order/information"
                  element={<WorkOrderInformation />}
                />
                <Route
                  path="/work-orders/add-work-order/summary"
                  element={<WorkOrderSummary />}
                />
              </Route>
              <Route
                path="/preventive-works/create-pm-work"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <AddPmWork />
                  </div>
                }
              >
                <Route
                  index
                  path="/preventive-works/create-pm-work"
                  element={<PMWorkScheduling />}
                />
                <Route
                  index
                  path="/preventive-works/create-pm-work/assets"
                  element={<CreatePMWorkAssets />}
                />
                <Route
                  path="/preventive-works/create-pm-work/details"
                  element={<PMWorkDetails />}
                />
                <Route
                  path="/preventive-works/create-pm-work/information"
                  element={<PMOrderInformation />}
                />
                <Route
                  path="/preventive-works/create-pm-work/summary"
                  element={<PMOrderSummary />}
                />
              </Route>
              <Route
                path="/preventive-works/create-pm-work/schedule"
                element={<NoSchedule />}
              />
              <Route
                path="/preventive-works/create-pm-work/create-schedule"
                element={<CreateSchedule />}
              />
              <Route
                path="/preventive-works/create-pm-work/use-schedule"
                element={<UseSchedule />}
              />
              {/* Preventive Maintenance (PM) Work  */}
              <Route
                path="/preventive-works"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <PreventiveWorks />
                  </div>
                }
              >
                <Route
                  index
                  path="/preventive-works/"
                  element={<PreventiveNewWork />}
                />
                <Route
                  path="/preventive-works/ongoing-work"
                  element={<PreventiveOngoingWorks />}
                />
                <Route
                  path="/preventive-works/pending-closure"
                  element={<PreventivePendingClouser />}
                />
                <Route
                  path="/preventive-works/closed-work"
                  element={<PreventiveClosedWorks />}
                />
                <Route
                  path="/preventive-works/upcoming-work"
                  element={<PreventiveUpcommingWorks />}
                />
              </Route>
              <Route
                path="/work-orders/work-requested"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <RequestedOrder />
                  </div>
                }
              />
              <Route
                path="/requests"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <Requests />
                  </div>
                }
              />
              <Route
                path="/requests/new-request"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <NewRequest />
                  </div>
                }
              />
              {/* use id to navigate : id */}
              <Route
                path="/requests/view-request/:id"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <ViewRequests />
                    {/*was  <PumpCalibration1000001 /> */}
                  </div>
                }
              >
                <Route
                  index
                  path="/requests/view-request/:id"
                  element={<ViewRequestSummary />}
                />
                <Route
                  index
                  path="/requests/view-request/:id/diagnosis/"
                  element={<ViewRequestDoagnosis />}
                />
                <Route
                  index
                  path="/requests/view-request/:id/parts"
                  element={<ViewRequiestParts />}
                />
                <Route
                  index
                  path="/requests/view-request/:id/quotes"
                  element={<ViewRequestQuotes />}
                />
              </Route>
              <Route
                path="/work-orders/work-view/:id"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <ViewWorkOrder />
                  </div>
                }
              >
                <Route
                  index
                  path="/work-orders/work-view/:id"
                  element={<ViewSummary />}
                />
                <Route
                  index
                  path="/work-orders/work-view/:id/assets"
                  element={<ViewAssets />}
                />
                <Route
                  index
                  path="/work-orders/work-view/:id/costings"
                  element={<ViewCostings />}
                />
                <Route
                  index
                  path="/work-orders/work-view/:id/job-cards"
                  element={<ViewJob />}
                />
                {/* <Route
                  index
                  path="/work-orders/work-view/:id/diagnosis"
                  element={<WorkOrderDiagnosisPage />}
                /> */}
                <Route
                  index
                  path="/work-orders/work-view/:id/confirm-work"
                  element={<WorkOrderConfirmWorkPage />}
                />
              </Route>

              <Route
                path="/add-pm-category"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <AddPMCaregory />
                  </div>
                }
              />
              <Route
                path="/work-orders/pmwork-view/:id"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <ViewPmWorkOrders />
                  </div>
                }
              >
                <Route
                  index
                  path="/work-orders/pmwork-view/:id"
                  element={<ViewPmSummary />}
                />
                <Route
                  index
                  path="/work-orders/pmwork-view/:id/assets"
                  element={<ViewPmAssets />}
                />
                <Route
                  index
                  path="/work-orders/pmwork-view/:id/costings"
                  element={<ViewPmCostings />}
                />
                <Route
                  index
                  path="/work-orders/pmwork-view/:id/job-cards"
                  element={<ViewPmJobcards />}
                />
              </Route>
              <Route
                path="/pump-calibration"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <PumpCalibration />
                  </div>
                }
              />
              <Route
                path="/inspection-category"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <InspectionCategory />
                  </div>
                }
              />
              <Route
                path="/reports"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <Reports />
                  </div>
                }
              />
              <Route
                path="/pm-cm-report"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <PMCMReports />
                  </div>
                }
              />
              <Route
                path="/pm-cm-category"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <PMCMCategory />
                  </div>
                }
              />
              <Route
                path="/user-pm-performance"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <UserPmPerformance />
                  </div>
                }
              />

              <Route
                path="/equipment-pm-performance"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <EquipmentPmPerformance />
                  </div>
                }
              />
              <Route
                path="/work-costs-report"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <WorkCostsReport />
                  </div>
                }
              />
              <Route
                path="/work-costs-category"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <CategoryWorkCosts />
                  </div>
                }
              />
              <Route
                path="/work-costs-equipment"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <EquipmentWorkCosts />
                  </div>
                }
              />
              <Route
                path="/work-costs-location"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <LocationWorkCosts />
                  </div>
                }
              />
              <Route
                path="/work-costs-contractor"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <ContractorWorkCosts />
                  </div>
                }
              />
              <Route
                path="/contractor-performance"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <ContractorPerformance />
                  </div>
                }
              />
              <Route
                path="/regional-performance"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <RegionalPerformance />
                  </div>
                }
              />
              <Route
                path="/contractor-performance-summary"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <ContractorPerformanceSummary />
                  </div>
                }
              />
              <Route
                path="/org-contractor-performance"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <OrgContractor />
                  </div>
                }
              />
              <Route
                path="/corrective-work-status"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <CorrectiveWorkStatus />
                  </div>
                }
              />
              <Route
                path="/preventive-work-status"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <PreventiveWorkStatus />
                  </div>
                }
              />
              <Route
                path="/inspection-category/create"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <CreateInspectionCategory />
                  </div>
                }
              />
              <Route
                path="/inspection-category/editCategoryInspection"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <EditInspectionCategory />
                  </div>
                }
              />
              <Route
                path="/inspection-category/washroom"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <WashroomInspection />
                  </div>
                }
              />
              <Route
                path="/inspection-category/inspections/:id"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <F3Washroom />
                  </div>
                }
              ></Route>
              <Route
                path="/inspections/responses/:id"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <InspectionsResponses />
                  </div>
                }
              ></Route>
              <Route
                path="/team"
                element={
                  <div className="root-wrapper">
                    <Navbar />
                    <Team />
                  </div>
                }
              />
            </Route>
            {/* Diagnosis & Quotes */}
            <Route
              path="/diagnosis-quotes"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <DiagnosisQuotes />
                </div>
              }
            />
            {/* View Diagnosis & Quotes */}
            <Route
              path="/diagnosis-quotes/view/:id"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <ViewDiagnosisQuotes />
                </div>
              }
            >
              <Route
                index
                path="/diagnosis-quotes/view/:id"
                element={<ViewDiagnosisQuotesSummary />}
              />
              <Route
                index
                path="/diagnosis-quotes/view/:id/diagnosis"
                element={<ViewDiagnosis />}
              />
              <Route
                index
                path="/diagnosis-quotes/view/:id/parts"
                element={<ViewDiagnosisQuotesParts />}
              />
              <Route
                index
                path="/diagnosis-quotes/view/:id/quotes"
                element={<ViewDiagnosisQuotesQ />}
              />
            </Route>
            {/* Work Costs */}
            <Route
              path="/work-costs"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <WorkCosts />
                </div>
              }
            />
            {/* View Work Costs */}
            <Route
              path="/work-costs/view/:id"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <ViewWorkCosts />
                </div>
              }
            >
              <Route
                index
                path="/work-costs/view/:id"
                element={<ViewWorkCostsSummary />}
              />
              <Route
                index
                path="/work-costs/view/:id/costing"
                element={<ViewWorkCosting />}
              />
            </Route>
            {/* Assets */}
            <Route
              path="/assets"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <Assets />
                </div>
              }
            >
              <Route index path="/assets/" element={<AssetsContent />} />
              <Route
                path="/assets/assets-categories"
                element={<AssetCategories />}
              />
              {/* <Route path="/assets/assets-transfers" element={<AssetTransfers />} /> */}
            </Route>
            <Route
              path="/assets/create-new-asset"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <CreateNewAsset />
                </div>
              }
            >
              <Route
                index
                path="/assets/create-new-asset"
                element={<CreateAssets />}
              />
              <Route
                path="/assets/create-new-asset/documents"
                element={<CreateDocuments />}
              />
              <Route
                path="/assets/create-new-asset/summary"
                element={<CreateSummary />}
              />
            </Route>
            <Route
              path="/assets/work-view/:id"
              element={
                <div className="root-wrapper">
                  <Navbar />
                  <AssetProfile />
                </div>
              }
            >
              <Route
                index
                path="/assets/work-view/:id"
                element={<ProfileSummary />}
              />
              <Route
                index
                path="/assets/work-view/:id/parts"
                element={<ProfileParts />}
              />
              <Route
                index
                path="/assets/work-view/:id/work"
                element={<ProfileWork />}
              />
              <Route
                index
                path="/assets/work-view/:id/assignment"
                element={<Assignment />}
              />
            </Route>
          </Routes>
          <ToastContainer />
        </Wrapper>
      </BrowserRouter>
    </RoutineInspectionCategoryContextProvider>
  );
}

export default App;
