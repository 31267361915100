import React, { useEffect, useState } from "react";
import { Dropdown, Nav, Spinner, Tab } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";
import usefetchData from "../../../hooks/useFetchData";
import { get } from "../../../Services/ApiHelper";
import { GET_MILEAGE } from "../../../utls/constants";
import AddNewItemCostsModal from "./AddNewItemCostsModal";

const NewAddItemCostsModal = ({
  show,
  onHide,
  categoryId,
  currency,
  locationId,
}) => {
  const [selectedPartPart, setselectedPartPart] = useState(
    "Select item from list"
  );
  const [selectedCost, setselectedCost] = useState("Select cost category");
  const [quantity, setQuantity] = useState("");
  const [comments, setComments] = useState("");
  const [itemTypeId, setItemTypeId] = useState(0);
  const [description, setDescription] = useState("");
  const [unitCost, setUnitCost] = useState(0);
  const [addNewItemCostModalShow, setAddNewItemCostModalShow] = useState(false);

  //search inputs
  const [partPartSearch, setPartPartSearch] = useState("");
  const [costSearch, setCostSearch] = useState("");

  const { id } = useParams();
  const [eventKey, setEventKey] = React.useState("");

  const ticketId = Number(id);
  const LocationId = locationId;

  const { data: partData, isLoading: isLoadingPricelist } = usefetchData(
    ["price-list", categoryId],
    `Tickets/GetPriceListItemsByCategory/${categoryId}`,
    {},
    "Couldn't get",
    !!categoryId
  );

  const { data: costData, isLoading: isLoadingcostData } = usefetchData(
    ["items-type", categoryId],
    `TicketCostings/GetAllTicketCostItemsTypes`,
    {},
    "Couldn't get",
    true
  );

  const { data: mileage, isLoading: isLoadingMileage } = usefetchData(
    ["category-id", categoryId],
    `Tickets/GetMileageByLocationCategory`,
    { LocationId: locationId, CategoryId: categoryId },
    "Couldn't get  mileage data",
    !!locationId && !!categoryId
  );

  //filter part location data  by search input
  const filteredPartPartData =
    partData?.length > 0 &&
    partData?.filter((item) =>
      item?.name.toLowerCase().includes(partPartSearch.toLowerCase())
    );

  const filteredCostData =
    costData?.length > 0 &&
    costData?.filter((item) =>
      item?.ticketCostItemTypeName
        ?.toLowerCase()
        .includes(costSearch.toLowerCase())
    );

  const handlePartPartSelect = (eventKey) => {
    const part = partData.find((item) => item?.name === eventKey);

    setselectedPartPart(eventKey);
    setPartPartSearch("");
    setItemTypeId(part.id);
    setDescription(part.name);
    setUnitCost(part.price);
  };

  const handleCostSelect = (eventKey) => {
    const cost = costData.find(
      (item) => item?.ticketCostItemTypeName === eventKey
    );
    setselectedCost(eventKey);
    setCostSearch("");
    setItemTypeId(cost.id);
    setCostState(true);
  };

  const { mutate, isLoading } = useMutateData({
    url: `Tickets/AddTicketPriceListCostedItem`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      // console.log("data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        onHide();
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });
  const [selectedItemType, setSelectedItemType] = useState("first");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [costErr, setCostErr] = useState("");
  const [quantityErr, setQuanityErr] = useState("");
  const [commentsErr, setCommentsErr] = useState("");
  const [costState, setCostState] = useState(false);
  const [eventkeyErr, setEventkeyErr] = useState("");

  console.log("co", "costState");

  const onSubmit = () => {
    if (description === "") {
      setDescriptionErr("  * item is required");
      return;
    }

    if (costState === false) {
      setCostErr("  * cost is required");
      return;
    }

    if (quantity === "") {
      setQuanityErr("  * quantity is required");
      return;
    }

    // if (comments === "") {
    //   setCommentsErr("  * comment is required");
    //   return;
    // }

    let requestBody = {};

    requestBody = {
      ticketId: ticketId,
      itemTypeId: itemTypeId,
      description: description,
      remarks: comments,
      quantity: parseFloat(quantity),
      unitCost: parseFloat(unitCost),
    };

    mutate(requestBody);
  };

  const [mileages, setMileage] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  useEffect(() => {
    setDescription("");
    setDescriptionErr("");
    setQuantity("");
    setComments("");
    setselectedPartPart("Select item from list");
    setselectedCost("Select cost category");
    setUnitCost(0);
    setQuanityErr("");
    setCommentsErr("");
    setCostErr("");
    setEventkeyErr("");
    const category = costData?.find(
      (item) => item?.ticketCostItemTypeName === "Mileage"
    );
    if (category) {
      get(
        GET_MILEAGE(LocationId, category?.id),
        localStorage.getItem("bearerToken")
      ).then((response) => {
        setMileage(response);
      });
    }
  }, [show]);
  // const [mileage, setMileage] = useState(0);
  // useEffect(() => {
  //   get(GET_MILEAGE(2, 3), localStorage.getItem("bearerToken")).then(
  //     (response) => {
  //       setMileage(response);
  //     }
  //   );
  // }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal add-asset-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Add Item Costs</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
                Add items that have pre-registered rates in the system
              </div>
            </div>

            <div className="mt-4">
              <div className="d-flex align-item-center justify-content-between w-100">
                <label className="fw-medium pb-2 fs-14">Select item</label>
                <button
                  className="pb-2"
                  style={{
                    color: "#D57D2A",
                    fontSize: "14px",
                    textDecoration: "underline",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    onHide();
                    setAddNewItemCostModalShow(true);
                  }}
                >
                  Add New Item
                </button>
              </div>
              <span style={{ color: "red", fontStyle: "italic" }}>
                {descriptionErr}
              </span>
              <Dropdown
                className="select__form"
                onSelect={handlePartPartSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPartPart !== "Select item from list"
                      ? "selected"
                      : ""
                  }`}
                  style={{ height: "fitContent", fontSize: "14px", textAlign: "left" }}
                >
                  <p
                    style={{
                      width: "100%",
                      whiteSpace: "wrap",
                      display: "flex",
                      
                    }}
                  >
                    {selectedPartPart}
                  </p>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partPartSearch}
                      onChange={(e) => setPartPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartPartData &&
                      filteredPartPartData?.map((item, index) => (
                        <Dropdown.Item
                          className="fs-14"
                          key={index}
                          eventKey={item?.name}
                        >
                          {item?.name}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="fs-14 mt-4">
              <b>Unit Rate/ Cost:</b> {currency} {unitCost}
              <span style={{ fontStyle: "italic" }}>
                {" "}
                (Automatically picked from system)
              </span>
            </div>

            <div className="mt-4">
              <label className="fw-medium pb-2 fs-14">Cost Category</label>
              <span style={{ color: "red", fontStyle: "italic" }}>
                {costErr}
              </span>
              <Dropdown className="select__form" onSelect={handleCostSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedCost !== "Select cost category" ? "selected" : ""
                  }`}
                  style={{ height: "50px", fontSize: "14px" }}
                >
                  {selectedCost}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={costSearch}
                      onChange={(e) => setCostSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredCostData &&
                      filteredCostData?.map((item, index) => (
                        <Dropdown.Item
                          className="fs-14"
                          key={index}
                          eventKey={item?.ticketCostItemTypeName}
                          onChange={() => setSelectedCategoryId(item?.id)}
                        >
                          {item?.ticketCostItemTypeName}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mt-4">
              <label className="fw-medium pb-2 fs-14">
                Quantity to be Costed
              </label>
              <span style={{ color: "red", fontStyle: "italic" }}>
                {quantityErr}
              </span>
              <input
                className="modal-input-box"
                onChange={(e) => setQuantity(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                  fontSize: "14px",
                }}
                placeholder="Enter quantity"
              />
            </div>

            <div className="mt-4">
              <label className="fw-medium pb-2 fs-14">Comments</label>
              <span style={{ color: "red", fontStyle: "italic" }}>
                {commentsErr}
              </span>
              <textarea
                className="modal-input-box"
                onChange={(e) => setComments(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "100px",
                  borderRadius: "5px",
                  padding: "20px 15px",
                  fontSize: "14px",
                }}
                placeholder="Enter Comments"
              ></textarea>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              <button className="delate-btn" onClick={() => onSubmit()}>
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AddNewItemCostsModal
        show={addNewItemCostModalShow}
        onHide={() => setAddNewItemCostModalShow(false)}
        // partData={pricelist}
        costData={costData}
        currency={currency}
        ticketId={ticketId}
        mileage={mileage}
        categoryId={categoryId}
        locationId={locationId}
      />
    </>
  );
};

export default NewAddItemCostsModal;
