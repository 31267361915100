import React, { useEffect, useState } from "react";
import "../Components/Dashboard/Dashboard.scss";
import { useNavigate } from "react-router-dom";
import OverallWork from "../Components/Dashboard/Overall";
import CorrectiveWork from "../Components/Dashboard/CorrectiveWork";
import PreventiveWork from "../Components/Dashboard/PreventiveWork";
import PerformanceStats from "../Components/Dashboard/PerformanceStats";
import MonthlyPerformance from "../Components/Dashboard/PerformanceGraph";
import { useDispatch, useSelector } from "react-redux";
import { setTabChangeState } from "../redux/woCostSlice";

const quickLinks = [
  {
    title: "All Requests",
    type: "view",
    icon: "/Vector(7).svg",
    link: "/my-work-new-requests",
    claim: "Can_View_Request",
    bg: "#F5B44E",
    tabChangeState: "request_work"
  },
  {
    title: "New Requests",
    type: "create",
    icon: "/Vector(8).svg",
    link: "/requests/new-request",
    claim: "Can_Add_Request",
    bg: "#F5B44E",
  },
  {
    title: "All CM Work",
    type: "view",
    icon: "/Vector(10).svg",
    link: "/my-work/work-orders",
    claim: "Can_View_Ticket",
    bg: "#D57D2A",
    tabChangeState: "cm_work"
  },
  {
    title: "New CM Work",
    type: "create",
    icon: "/Vector(8).svg",
    link: "/work-orders/add-work-order",
    claim: "Can_Add_Ticket",
    bg: "#D57D2A",
  },
  {
    title: "All PM Work",
    type: "view",
    icon: "/Vector(9).svg",
    // link: "/my-work/preventive-works",
    claim: "Can_View_Ticket",
    bg: "#6C5B51",
    tabChangeState: "pm_work_not"
  },
  {
    title: "New PM Work",
    type: "create",
    icon: "/Vector(8).svg",
    // link: "/preventive-works/create-pm-work",
    claim: "Can_Add_Ticket",
    bg: "#6C5B51",
  },
];

const Dashboard = () => {
  const [userClaims, setUserClaims] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const tabChange = useSelector((state) => state.woCost);

  useEffect(() => {
    // Get claims from localStorage
    const claims = localStorage.getItem("claims");
    if (claims) {
      try {
        setUserClaims(JSON.parse(claims));
      } catch (error) {
        console.error("Error parsing claims:", error);
        setUserClaims([]);
      }
    }
  }, []);

  // Filter quick links based on user claims
  const visibleQuickLinks = quickLinks.filter((link) =>
    userClaims.includes(link.claim)
  );

  return (
    <div className="dashboard-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="fs-15 text-white dashboard-title">
              Hello,{" "}
              <span className="fw-semibold">
                {JSON.parse(localStorage.getItem("user")).userFirstName}
              </span>
            </div>
          </div>
        </div>
        <div
          className="bg-body mb-4 rounded"
          style={{
            height: "auto",
            minHeight: "140px",
            padding: "20px 40px",
            border: "1px solid #DFE0EB",
            margin: "0 auto",
          }}
        >
          <h2 className="fs-4 fw-bold mb-3">Quick Links</h2>
          <div
            className="row row-cols-1 row-cols-md-3 row-cols-lg-6 g-3"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {visibleQuickLinks.map((item, index) => (
              <React.Fragment key={index}>
                <div className="col d-flex" onClick={
                  () => {
                    if(item?.tabChangeState === "request_work"){
                      dispatch(setTabChangeState("request_work"));
                    } else if(item?.tabChangeState === "pm_work"){
                      dispatch(setTabChangeState("pm_work"));
                    }else if(item?.tabChangeState === "cm_work"){
                      dispatch(setTabChangeState("cm_work"));
                    }
                    
                  }
                }>
                  {index > 0 && (
                    <div
                      className="border-end mx-2 d-none d-md-block"
                      style={{ height: "100%" }}
                    ></div>
                  )}
                  <div
                    onClick={() => {
                      if(item?.title === "All PM Work" || item?.title === "New PM Work"){
                        console.log("no redirect")
                      }else{
                        navigate(item.link)
                      }
                      
                    }}
                    className="btn d-flex flex-row align-items-center w-100"
                    style={{ cursor: "pointer", padding: "0px" }}
                  >
                    <div
                      className="position-relative d-flex justify-content-center align-items-center"
                      style={{
                        minWidth: "44px",
                        height: "44px",
                        background: item.bg,
                        borderRadius: "4px",
                        marginRight: "15px",
                      }}
                    >
                      <img
                        src={item.icon}
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column align-items-start">
                      <span className="fs-12 text-muted">
                        {item.type === "view" ? "View" : "Create"}
                      </span>
                      <span
                        className="fs-14 fw-bold"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.title}
                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="row justify-content-center row-gap">
          <div className="col-lg-4 col-md-6">
            <OverallWork />
          </div>
          <div className="col-lg-4 col-md-6">
            <CorrectiveWork />
          </div>
          <div className="col-lg-4 col-md-6">
            <PreventiveWork />
          </div>
        </div>
        <div className="row row-gap">
          <div className="col-lg-12">
            <div className="request-content">
              <div
                className="request-item"
                style={{ borderRadius: "0.375rem", overflow: "hidden" }}
              >
                <MonthlyPerformance />
              </div>
              <div className="reminders-item">
                <PerformanceStats />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
