import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mileageDefaultList: [], 
  tabChangeState: ""
};

const woCostSlice = createSlice({
  name: 'woCost',
  initialState,
  reducers: {
    setMileageDefaultList(state, action) {
      state.mileageDefaultList = action.payload; 
    },
    setTabChangeState(state, action) {
      state.tabChangeState = action.payload; 
    },
    resetMileageDefaultList(state) {
      state.mileageDefaultList = []; 
    },
  },
});

export const {
  setMileageDefaultList,
  resetMileageDefaultList,
  setTabChangeState,
} = woCostSlice.actions;

export default woCostSlice.reducer;
