import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import {
  ACCEPTREJECT_WORK,
  ADD_COMMENT,
  GET_WORKORDER_DETAILS_BY_ID,
} from "../../../utls/constants";
import { Spinner } from "react-bootstrap";
import { get, post } from "../../../Services/ApiHelper";
import { useParams } from "react-router-dom";

const AddCommentModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const { id } = useParams();
  const token = localStorage.getItem("bearerToken");
  const [reason, setReason] = useState("");
  const onSubmit = () => {
    const body = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
      spareComment: reason,
    };
    setLoading(true);

    post(ADD_COMMENT, body, token).then((data) => {
      if (data.succeeded === true) {
        const newComment = {
            id: data?.id, 
            sparesComment: body?.spareComment,
          };
    
         
          setTicketSummary((prevSummary) => ({
            ...prevSummary,
            workOrderSummary: {
              ...prevSummary?.workOrderSummary,
              ticketSparePartCommentList: [
                ...(prevSummary?.workOrderSummary?.ticketSparePartCommentList || []),
                newComment, 
              ],
            },
          }));
    
        props.onHide();
        setReason("");
        setLoading(false);
        toast.success("Comment Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      } else {
        setLoading(false);
        setReason("");
        props.onHide();
        toast.error("Comment Add failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Parts Comments</span>
            <span className="fs-14 ps-3">
              Add comments on used/ returned parts
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2 fs-14">Comment</label>
              <textarea
                name=""
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter comment"
              ></textarea>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <>
              <button className="cancel-btn" onClick={() => props.onHide()}>
                Cancel
              </button>
              {loading ? (
                <button className="cancel-btn" disabled={loading}>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button className="delate-btn" onClick={onSubmit}>
                  Add
                </button>
              )}
            </>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCommentModal;
