import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import { Link } from "react-router-dom";
import AddIcon from "../../Assets/Icons/AddIcon";
import exportToExcel from "../../utls/exportToExcel";
import exportToExcelMain from "../../utls/exportToExecelMain";
import { useDispatch } from "react-redux";
import { resetForm } from "../../redux/pmFormSlice";
import { setTabChangeState } from "../../redux/woCostSlice";

const PreventWorkHeader = ({ report }) => {
  const dispatch = useDispatch()
  const hancleClick = () => {
    dispatch(resetForm())
    dispatch(setTabChangeState("pm_work"));
    // dispatch(rese)
  }
  return (
    <div className="work-header">
      <div className="fs-20">Preventive Maintenance (PM) Work</div>
      <div className="dropdown select-dropdown">
        <button className="select-title" data-bs-toggle="dropdown">
          <span className="fs-15 d-flex align-items-center gap-2">
            Actions <DownIcon />
          </span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end select-menu">
          <li>
            <Link to="/preventive-works/create-pm-work" onClick={hancleClick}>
              <AddIcon /> New PM Work
            </Link>
          </li>
          <li onClick={() => exportToExcelMain(report, "work-orders")}>
            <Link to="#">
              <AddIcon /> Export to Excel
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PreventWorkHeader;
