import React from "react";
import { Link } from "react-router-dom";

const NewRequestNavbar = () => {
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li> 
        <li>
          <Link to="/my-work-new-requests">Requests</Link>
        </li> 
        <li>
          <Link> New Request</Link>
        </li>
      </ul>
    </div>
  );
};

export default NewRequestNavbar;
