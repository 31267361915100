export  const overallWork = {
    title: "OVERALL WORK CARD",
    list: [
      {
        id: 1,
        point: "Summary",
        description:
          "This section shows a combination of Preventive & Corrective Work Stats. By default, the stats are based on only work that is relevant to you.",
      },
      {
        id: 2,
        point: "Stats Basis",
        description:
          "All the statistics shown i.e. Work Items, Overdue Work, New, Ongoing and Closed work are based on the filter chosen i.e Period & Location.",
      },
      {
        id: 3,
        point: "Period Filter",
        description:
          "The choosen period will filter the work items to only show stats for work that has been created within the chosen period. E.g. Last 7 days will only consider work that has been created within the last 7 days while current will consider all created work.",
      },
      {
        id: 4,
        point: "Location Filter",
        description:
          "This is used to filter work based on the chosen locations. Selecting ALL shows data for all locations while selecting specific locations shows data for only the selected locations. ",
      },
      {
        id: 5,
        point: "Work Items",
        description:
          "This stats shows all work created within the chosen period , chosen location and is overdue",
      },
      {
        id: 6,
        point: "Overdue Work",
        description:
          "This stats shows all work created within the chosen period , chosen location and is overdue i.e. Is past due date and has not been closed. ",
      },
      {
        id: 7,
        point: "New",
        description:
          "This stats shows all work created within the chosen period , chosen location and is new i.e. Work has not begun.",
      },
      {
        id: 8,
        point: "Ongoing",
        description:
          "This stats shows all work created within the chosen period , chosen location and is ongoing i.e. Work has begun.",
      },
      {
        id: 9,
        point: "Closed",
        description:
          "This stats shows all work created within the chosen period , chosen location and is closed i.e. Work has been completed.",
      },
    ],
  };

export  const correctiveWork = {
    title: "CORRECTIVE WORK CARD",
    list: [
      {
        id: 1,
        point: "Summary",
        description:
          "This section shows only Corrective Work Stats. By default, the stats are based on only work that is relevant to you.",
      },
      {
        id: 2,
        point: "Stats Basis",
        description:
          " All the statistics shown i.e. Work Items, Overdue Work, New, Ongoing and Closed work are based on the filter chosen i.e Period & Location.",
      },
      {
        id: 3,
        point: "Period Filter",
        description:
          " The choosen period will filter the corrective work items to only show stats for work that has been created within the chosen period. E.g. Last 7 days will only consider work that has been created within the last 7 days while current will consider all created work.",
      },
      {
        id: 4,
        point: "Location Filter",
        description:
          "This is used to filter corrective work based on the chosen locations. Selecting ALL shows data for all locations while selecting specific locations shows data for only the selected locations.  ",
      },
      {
        id: 5,
        point: "Work Items",
        description:
          "This stats shows all corrective work created within the chosen period , chosen location and is overdue.",
      },
      {
        id: 6,
        point: "Overdue Work",
        description:
          "This stats shows all corrective work created within the chosen period , chosen location and is overdue i.e. Is past due date and has not been closed. ",
      },
      {
        id: 7,
        point: "New",
        description:
          "This stats shows all corrective work created within the chosen period , chosen location and is new i.e. Work has not begun.",
      },
      {
        id: 8,
        point: "Ongoing",
        description:
          "This stats shows all corrective work created within the chosen period , chosen location and is ongoing i.e. Work has begun.",
      },
      {
        id: 9,
        point: "Closed",
        description:
          "This stats shows all corrective work created within the chosen period , chosen location and is closed i.e. Work has been completed.",
      },
    ],
  };

export  const preventiveWork = {
    title: "PREVENTIVE WORK CARD",
    list: [
      {
        id: 1,
        point: "Summary",
        description:
          "This section shows only Preventive Work Stats. By default, the stats are based on only work that is relevant to you.",
      },
      {
        id: 2,
        point: "Stats Basis",
        description:
          "All the statistics shown i.e. Work Items, Overdue Work, New, Ongoing and Closed work are based on the filter chosen i.e Period & Location.",
      },
      {
        id: 3,
        point: "Period Filter",
        description:
          "The choosen period will filter the preventive work items to only show stats for work that has been created within the chosen period. E.g. Last 7 days will only consider work that has been created within the last 7 days while current will consider all created work.",
      },
      {
        id: 4,
        point: "Location Filter",
        description:
          "This is used to filter preventive work based on the chosen locations. Selecting ALL shows data for all locations while selecting specific locations shows data for only the selected locations. ",
      },
      {
        id: 5,
        point: "Work Items",
        description:
          "This stats shows all work preventive created within the chosen period , chosen location and is overdue",
      },
      {
        id: 6,
        point: "Overdue Work",
        description:
          "This stats shows all preventive work created within the chosen period , chosen location and is overdue i.e. Is past due date and has not been closed. ",
      },
      {
        id: 7,
        point: "Upcoming Work",
        description:
          "This stats shows all upcoming preventive work and is as per the current date regardless of the chosen periods and as per the locations chosen.",
      },
      {
        id: 7,
        point: "New",
        description:
          "This stats shows all preventive work created within the chosen period , chosen location and is new i.e. Work has not begun.",
      },
      {
        id: 8,
        point: "Ongoing",
        description:
          "This stats shows all preventive work created within the chosen period , chosen location and is ongoing i.e. Work has begun.",
      },
      {
        id: 9,
        point: "Closed",
        description:
          "This stats shows all preventive work created within the chosen period , chosen location and is closed i.e. Work has been completed.",
      },
    ],
  };
export  const perfomanceStats = {
    title: "PERFORMANCE STATS CARD",
    list: [
      {
        id: 1,
        point: "Summary",
        description:
          "This section shows performance stats for work relevant to you in relation to all other work. The performance stats are based on the % of work closed on time as well as the % of work not closed on time. ",
      },
      {
        id: 2,
        point: "Period Filter",
        description:
          "The chosen period filters work items whose closing date is within the chosen period and only closed tickets are considered in the stats computation.",
      },
      {
        id: 3,
        point: "Location Filter",
        description:
          "This will filter work based on the chosen locations. Selecting ALL shows data for all your locations while selecting specific locations shows data for only the selected locations. ",
      },
      {
        id: 4,
        point: "My Locations",
        description:
          "This column shows stats for work relevant to you.",
      },
      {
        id: 5,
        point: "Overall",
        description:
          "This column shows stats for work in all locations including yours.",
      },
      {
        id: 6,
        point: "% performance last 30 days",
        description:
          "This shows the performance trend as a percentage for the last 30days. An upward facing brown arrow shows improving performance while a downward facing blackish arrow shows falling performance. ",
      }
    ],
  };

export  const monthlyPerformancedata = {
    title: "MONTHLY PERFORMANCE CARD",
    list: [
      {
        id: 1,
        point: "Summary",
        description:
          "This section shows work performance i.e. % of work closed on time for the last 12 months. By default, the stats are based on only work that is relevant to you.",
      },
      {
        id: 4,
        point: "Location Filter",
        description:
          "This is used to filter perfomance based on the chosen locations. Selecting ALL shows performance for all relevant locations while selecting specific locations shows performance for only the selected locations. ",
      }
    ],
  };