import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useMutateData from "../../../hooks/useMutateData";
import { resetForm } from "../../../redux/formSlice";
import { toast } from "react-toastify";
// import toast from "react-hot-toast";

const WorkOrderSummary = () => {
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.form);
  const [imageShow, setImageShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [documentShow, setDocumentShow] = useState(false);
  const handleClose = () => {
    setImageShow(false);
    setVideoShow(false);
    setDocumentShow(false);
  };

  const navigate = useNavigate();

  const tabChange = useSelector((state) => state.woCost);

  const { mutate, isLoading } = useMutateData({
    url: `Tickets/RaiseTicket`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        dispatch(resetForm());
        setTimeout(() => {
          // if(tabChange?.tabChangeState === "pm_work"){
          //   navigate("/my-work/preventive-works", 1000)
          // }else{
          //   navigate("/my-work/work-orders", 1000)
          // }

          if(tabChange?.tabChangeState === "cm_work"){
            navigate("/my-work/work-orders", 1000)
          }
          

        });
        // navigate("/work-orders");
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
  });

  const onSubmit = () => {
    let files = [];
    formState.files?.map((file) => {
      const fileObj = {
        encodedFile: file?.url,
        fileName: file?.fileName,
        url: file?.url,
        fileType: file?.fileName.split(".").pop(),
        fileTypeSource: file?.url === "" ? "" : "blob",
      };
      files.push(fileObj);
    });

    const payload = {
      locationId: formState.locationId ? parseInt(formState.locationId) : null,
      categoryOfWorkId: formState.categoryOfWorkId
        ? parseInt(formState.categoryOfWorkId)
        : null,
      assetListIds: formState.assetListIds.map((id) =>
        id ? parseInt(id) : null
      ),
      ticketTitle: formState.ticketTitle,
      ticketDescription: formState.ticketDescription,

      files: files,
      checklistIds: formState.checklistIds.map((id) =>
        id ? parseInt(id) : null
      ),
      ticketPriorityId: formState.ticketPriorityId
        ? parseInt(formState.ticketPriorityId)
        : null,
      assignedTeam: {
        id: formState.assignedTeam.id
          ? parseInt(formState.assignedTeam.id)
          : null,
        name: formState.assignedTeam.name || "",
      },
      assignedUser:
        formState.assignedUser === null
          ? null
          : {
              id: formState.assignedUser?.id
                ? parseInt(formState.assignedUser?.id)
                : null,
              name: formState.assignedUser?.name || "",
            },
      signatureRequiredToCompleteWork:
        formState.signatureRequiredToCompleteWork,
      isPTWRequired: formState.isPTWRequired,
      useSLATime: true,
      estimatedHours: parseInt(formState.estimatedHours)
        ? parseInt(formState.estimatedHours)
        : 0,
      expectedResponseHours: formState.expectedTimeValue
        ? parseInt(formState.expectedTimeValue)
        : null,
      expectedResolutionHours:
        parseInt(formState.expectedTime2Value) ||
        parseInt(formState?.ticketSLA?.expectedResolutionTime) ||
        null,
      estimateTime:
        parseInt(formState.ticketSLA.estimateTime) ||
        parseInt(formState.estimatedHours) ||
        null,
      estimateTimeCalendarTypeID:
        parseInt(formState.ticketSLA.estimateTimeCalendarTypeId) ||
        formState.selectedEstimateTypeId ||
        null,
      expectedResponseTimeCalendarTypeID: formState.selectedExpectedTimeId
        ? parseInt(formState.selectedExpectedTimeId)
        : null,
      expectedResolutionTimeCalendarTypeID:
        parseInt(formState.selectedExpectedTime2Id) ||
        parseInt(formState?.ticketSLA?.expectedResolutionTimeCalendarTypeId) ||
        null,
      projectedParts: formState.projectedParts.map((part) => ({
        spareId: part.partId ? parseInt(part.partId) : null,
        quantity: part.quantity ? parseInt(part.quantity) : null,
        locationId: part.locationId ? parseInt(part.locationId) : null,
        partDataSource: "PartForTicket",
      })),
      requestId: formState.selectedRequestId,
    };

    mutate(payload);
  };

  return (
    <>
      <div className="order-details-content pb-lg-4">
        <div className="fs-16 fw-semibold">Summary</div>

        <div className="work-order-summary mt-5">
          <div
            className="fs-14 fw-semibold pb-4"
            style={{ color: "#00000061" }}
          >
            ASSIGNED WORK
          </div>
          <div className="row" style={{ rowGap: "24px" }}>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Team
              </div>
              <div className="fs-14 fw-medium">
                {formState?.assignedTeam?.name}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Worker
              </div>
              <div className="fs-14 fw-medium">
                {formState?.assignedUser?.name ?? "NA"}
              </div>
            </div>
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-4"
            style={{ color: "#00000061" }}
          >
            WORK DETAILS
          </div>
          <div className="row" style={{ rowGap: "24px" }}>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Work Request Ref ID:
              </div>
              <div className="fs-14 fw-medium">
                {formState?.requestReference
                  ? formState?.requestReference
                  : "NA"}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Work Order Title:
              </div>
              <div className="fs-14 fw-medium">{formState?.ticketTitle}</div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Location:
              </div>
              <div className="fs-14 fw-medium">{formState?.location}</div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Category of Work:
              </div>
              <div className="fs-14 fw-medium">{formState?.categoryOfWork}</div>
            </div>

            <div className="col-md-6">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Description:
              </div>
              <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
                {formState?.ticketDescription}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Asset Category:
              </div>
              <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
                {formState?.categoryOfWork}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Assets
              </div>
              {formState?.selectedAssets?.map((item) => (
                <div className="fs-14 fw-medium">{item}</div>
              ))}
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Priority:
              </div>
              <div className="fs-14 fw-bold" style={{ color: "#D57D2A" }}>
                {formState?.ticketPriority.toUpperCase()}
              </div>
            </div>
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-3"
            style={{ color: "#00000061" }}
          >
            EXTRA WORK DETAILS
          </div>
          <div className="row">
            {formState.ticketSLA?.responseType !== null &&
              formState.ticketSLA?.responseType?.toLowerCase() ===
                "fulljob" && (
                <div className="col-md-3">
                  <div
                    className="fs-14 fw-semibold pb-2"
                    style={{ color: "#00000061" }}
                  >
                    Estimated Time
                  </div>
                  <div className="fs-14 fw-medium">
                    {formState?.estimatedHours}{" "}
                    {formState.estimatedHours
                      ? formState.selectedEstimateType
                      : "NA"}
                  </div>
                </div>
              )}
            {formState.ticketSLA?.responseType !== null &&
              formState.ticketSLA?.responseType?.toLowerCase() ===
                "responseresolution" && (
                <>
                  <div className="col-md-3">
                    <div
                      className="fs-14 fw-semibold pb-2"
                      style={{ color: "#00000061" }}
                    >
                      Expected Response Time
                    </div>
                    <div className="fs-14 fw-medium">
                      {formState?.expectedTimeValue}{" "}
                      {formState?.expectedTimeValue
                        ? formState.selectedExpectedTime
                        : "NA"}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="fs-14 fw-semibold pb-2"
                      style={{ color: "#00000061" }}
                    >
                      Expected Resolution Time
                    </div>
                    <div className="fs-14 fw-medium">
                      {formState?.expectedTime2Value}{" "}
                      {formState?.expectedTime2Value
                        ? formState?.selectedExpectedTime2
                        : "NA"}
                    </div>
                  </div>
                </>
              )}
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061", letterSpacing: "-.4px" }}
              >
                Technician Signature Required?
              </div>
              <div className="fs-14 fw-medium">
                {formState?.signatureRequiredToCompleteWork ? "Yes" : "No"}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061", letterSpacing: "-.4px" }}
              >
                Requires permit to work?
              </div>
              <div className="fs-14 fw-medium">
                {formState?.isPTWRequired ? "Yes" : "No"}
              </div>
            </div>
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-3"
            style={{ color: "#00000061" }}
          >
            PROJECTED PARTS DETAILS
          </div>
          <div className="col-md-3 d-grid gap-2">
            {formState?.projectedParts?.length > 0
              ? formState?.projectedParts?.map((part) => (
                  <div className="row" key={part.partId}>
                    <div className="fs-14 fw-medium">
                      {part?.partName}, {part?.quantity} Pieces
                    </div>
                  </div>
                ))
              : "NA"}
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-3"
            style={{ color: "#00000061" }}
          >
            CHECKLIST DETAILS
          </div>
          <div className="row">
            <div className="col-md-12 d-grid gap-2">
              {/* <div className="fs-14 fw-medium">Pump Nozzle Checklist</div>
              <div className="fs-14 fw-medium">Pump Monitor Checklist</div> */}

              {formState?.checklists?.length > 0
                ? formState?.checklists?.map((check) => (
                    <div id={check} className="fs-14 fw-medium">
                      {check}
                    </div>
                  ))
                : "NA"}
            </div>
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-3"
            style={{ color: "#00000061" }}
          >
            OTHER INFORMATION
          </div>
          {formState?.files?.length > 0 ? (
            <div className="row">
              <div className="col-md-3">
                <div
                  className="fs-14 fw-medium"
                  style={{ color: "rgba(0, 0, 0, 0.38)" }}
                >
                  Files:
                </div>

                {formState?.files?.map((file) => (
                  <div className="d-grid gap-2 mt-2">
                    <button
                      // onClick={() => setImageShow(true)}
                      className="fs-14 fw-medium text-start"
                      style={{ color: "#D57D2A" }}
                    >
                      {file?.fileName}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            "NA"
          )}
        </div>

        <Modal show={imageShow} onHide={handleClose} centered>
          <Modal.Body>
            <div className="delate-content p-2">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold">Image</span>
                <span className="fs-14" style={{ color: "#72777A" }}>
                  Pump1.jpg
                </span>
              </div>
              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                <div className="col-md-12">
                  <img
                    src="/images/Pump1.jpg"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="button-group"
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "end",
                  gap: "30px",
                }}
              >
                <a
                  href="/images/Pump1.jpg"
                  download
                  className="cancel-btn download-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
                <button className="delate-btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={videoShow} onHide={handleClose} centered size="lg">
          <Modal.Body>
            <div className="delate-content p-2">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold">Video</span>
                <span className="fs-14" style={{ color: "#72777A" }}>
                  Pump1 Meter.mp4
                </span>
              </div>
              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                <div className="col-md-12">
                  <video width="100%" height="auto" controls>
                    <source src="/images/Pump1 Meter.mp4" type="" />
                  </video>
                </div>
              </div>
              <div
                className="button-group"
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "end",
                  gap: "30px",
                }}
              >
                <a
                  href="/images/Pump1 Meter.mp4"
                  download
                  className="cancel-btn download-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
                <button className="delate-btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={documentShow} onHide={handleClose} centered size="lg">
          <Modal.Body>
            <div className="delate-content p-2">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold">Document</span>
                <span className="fs-14" style={{ color: "#72777A" }}>
                  Calibration manual.pdf
                </span>
              </div>
              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                <div className="col-md-12">
                  <iframe
                    title="pdf-viewer"
                    src="/images/Calibration manual.pdf"
                    width="100%"
                    height="500px"
                  ></iframe>
                </div>
              </div>
              <div
                className="button-group"
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "end",
                  gap: "30px",
                }}
              >
                <a
                  href="/images/Calibration manual.pdf"
                  download
                  className="cancel-btn download-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
                <button className="delate-btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="details-buttons pt-lg-5 d-flex justify-content-between">
          <Link
            to="/work-orders/add-work-order/details"
            className="next-btn ms-0"
          >
            Previous
          </Link>
          <button
            type="button"
            onClick={() => onSubmit()}
            className="next-btn ms-0"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default WorkOrderSummary;
