import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import AttachIcon from "../../../Assets/Icons/AttachIcon";
import { IoMdClose } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { useForm } from "react-hook-form";
import InputError from "../../Common/InputError";
// import { SharedEndpoints } from "../../../Services/ApiServiceCalls/SharedEndpoints";
import { get, post } from "../../../Services/ApiHelper";
import {
  CREATE_REQUEST,
  GETALLOPERATIONALSATATUS,
  GET_ASSETS_BY_LOCATION,
  GET_ASSET_FAULTS,
  GET_OPERATIONAL_STATUS_BY_ASSET_ID,
  GET_SIMPLE_LOCATION_LIST,
} from "../../../utls/constants";
// import { RequestService } from "../../../Services/ApiServiceCalls/RequestService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Astericks from "../../Common/Asterick";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { Dropdown } from "react-bootstrap/esm";
import ViewImage from "../../WorkOrders/Modal/ViewImage";
import { set } from "lodash";

const NewRequestContent = () => {
  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    location: "Select",
    locationId: "",
  });
  const navigate = useNavigate();
  const styleBtn = {
    borderRadius: "5px",
    background: "#D57D2A",
    color: "white",
    border: "0",
    width: "100%",
    outline: "none",
    height: "41px",
    padding: "0 15px",
  };

  const [selectedFaults, setSelectedFaults] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [faults, setFaults] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");
  const [operationalStatus, setOperationalStatus] = useState([]); // New state for operational status
  const [operationStatusOptions, setOperationStatusOptions] = useState([]); // State for dropdown options
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryAsset, setSearchQueryAsset] = useState("");
  const [searchQueryStatus, setSearchQueryStatus] = useState("");
  const [searchQueryFaults, setSearchQueryFaults] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [assetId, setAssetId] = useState([]);
  const [operationalStatusId, setOperationalStatusId] = useState(null);
  // const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      operationalStatus: operationalStatusId ? operationalStatusId : null,
    },
  });

  const handleFaultChange = (faultId, isChecked) => {
    if (isChecked) {
      // Add the selected fault ID to the list
      setSelectedFaults((prevSelected) => [...prevSelected, parseInt(faultId)]);
    } else {
      // Remove the deselected fault ID from the list
      setSelectedFaults((prevSelected) =>
        prevSelected.filter((id) => id !== parseInt(faultId))
      );
    }
  };

  const [viewFile, setViewFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setViewFile(selectedFiles);
    const newFiles = selectedFiles.filter(
      (file) => !attachments.includes(file.name)
    );
    setAttachments((prevAttachments) => [
      ...prevAttachments,
      ...newFiles.map((file) => file.name),
    ]);
    setAttachmentFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => file),
    ]);
  };

  const deleteFile = (fileName) => {
    // Remove file name from attachments
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment !== fileName)
    );

    // Remove file object from viewFile based on file name
    setViewFile((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );

    // Also update attachmentFiles to keep it in sync
    setAttachmentFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const getOperationalStatus = (assetId) => {
    get(
      GETALLOPERATIONALSATATUS(assetId),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      console.log("response", response);
      setOperationStatusOptions(
        response.map((status) => ({
          value: status.id,
          label: status.operationalStatusName,
        }))
      );
    });
  };

  const getAssetsByLocation = (location) => {
    get(
      GET_ASSETS_BY_LOCATION(location),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setAssets(response);
    });
  };

  const getAssetFaults = (asset) => {
    setFaults([]);
    get(GET_ASSET_FAULTS(asset), localStorage.getItem("bearerToken")).then(
      (response) => {
        setFaults(response);
      }
    );
  };

  const onSubmit = async (data) => {
    // Prevent submission if already loading
    if (isLoading) return;

    setIsLoading(true); // Set loading state before proceeding
    // const files = [];

    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const fileType = file.type.split("/")[1];
          const encodeFile = reader.result.split("base64,")[1];
          resolve({
            fileName: file.name,
            url: "",
            fileType: fileType,
            encodedFile: encodeFile,
          });
        };
        reader.onerror = (error) => reject(error);
      });
    };

    try {
      // Read and encode files asynchronously
      const promises = attachmentFiles.map((file) => readFile(file));
      const files = await Promise.all(promises);

      // Construct request body
      const body = {
        locactionId: locationId,
        assetId: [assetId[0]],
        selectedFaults,
        requestDetails: data.description,
        recurrence: selectedRadio === "recurrent",
        files: files,
        operationalStatusId: parseInt(operationalStatusId),
      };

      // Submit the request
      const response = await post(
        CREATE_REQUEST,
        body,
        localStorage.getItem("bearerToken")
      );

      if (response.succeeded === true) {
        toast.success("Request created successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        navigate("/my-work-new-requests");
      } else {
        throw new Error("Request creation failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get(GET_SIMPLE_LOCATION_LIST, localStorage.getItem("bearerToken"))
      .then((response) => {
        setLocations(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (locations.length === 1) {
      setSelectValue({ ...selectValue, location: locations[0].locationName });
      setLocationId(locations[0].id);
      getAssetsByLocation(locations[0].id);
    }
  }, [locations]);

  //filtered dropdown items data
  const filteredLocations =
    locations.length > 0 &&
    locations?.filter((location) =>
      location.locationName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const filteredFaults =
    faults.length > 0 &&
    faults?.filter((fault) =>
      fault.faultName.toLowerCase().includes(searchQueryFaults.toLowerCase())
    );

  const filteredAssets =
    assets.length > 0 &&
    assets?.filter((asset) =>
      asset.assetName.toLowerCase().includes(searchQueryAsset.toLowerCase())
    );

  // const filteredFaults = faults.filter((fault) =>
  //   fault.faultName.toLowerCase().includes(searchQueryFaults.toLowerCase())
  // );

  const filteredOperationalStatus =
    operationStatusOptions.length > 0 &&
    operationStatusOptions.filter((status) =>
      status.label.toLowerCase().includes(searchQueryStatus.toLowerCase())
    );

  console.log(selectValue);

  const handleSelect = (eventKey) => {
    const selectedLocation = locations.find(
      (location) => location.locationName === eventKey
    );
    setLocationId(selectedLocation.id);
    if (selectedLocation) {
      // Update the selectValue with both the location name and id
      setSelectValue({
        location: selectedLocation.locationName,
        locationId: selectedLocation.id,
      });
      // Optionally, you can fetch assets by location name or id
      getAssetsByLocation(selectedLocation.id);
    }
  };

  const handleSelectAsset = (eventKey) => {
    const selectedAsset = assets.find((asset) => asset.assetName === eventKey);
    if (selectedAsset) {
      setAssetId([parseInt(selectedAsset.id)]);
      setSelectValue({
        ...selectValue,
        asset: selectedAsset.assetName,
        assetId: selectedAsset.id,
      });
      getAssetFaults(selectedAsset.id);
      getOperationalStatus(selectedAsset.id);
    }
  };

  // Handle operational status selection
  const handleSelectOperational = (eventKey) => {
    const selectedAsset = operationStatusOptions.find(
      (asset) => asset.label === eventKey
    );
    if (selectedAsset) {
      setOperationalStatusId(parseInt(selectedAsset.value));
      setSelectValue({
        ...selectValue,
        status: selectedAsset.label,
        statusId: selectedAsset.value,
      });

      // Set the operational status value in the form to trigger validation
      setValue("operationalStatus", selectedAsset.value, {
        shouldValidate: true,
      });
    }
  };

  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
  });

  const handleShowImg = () => {
    setImageShow({
      isShow: true,
      image: "",
      file_name: "",
    });
  };

  return (
    <>
      <div
        className="order-details-content add-pm-content request-details"
        style={{ marginTop: "0" }}
      >
        <div className="fs-16 fw-bold">Request Details</div>
        <div style={{ margin: "20px 0" }}></div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="washroom-title-request">
            <div className="fs-13 fw-medium">REQUEST INFORMATION</div>
          </div> */}
          <hr
            style={{
              border: "none",
              borderTop: "1px solid #e0e0e0",
              margin: "10px 0",
            }}
          />
          <div className="row details-forms-one mt-5">
            <div className="col-md-6">
              <label>Where are you located</label>
              <Dropdown className="select__form" onSelect={handleSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectValue.location !== "Select" ? "selected" : ""
                  }`}
                >
                  {/* Display the selected location name or default to 'Select' */}
                  {selectValue.location || "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredLocations.length === 1 ? (
                      <Dropdown.Item
                        eventKey={filteredLocations[0]?.locationName}
                      >
                        {filteredLocations[0]?.locationName}
                      </Dropdown.Item>
                    ) : (
                      <>
                        <Dropdown.Item eventKey="" disabled>
                          Select
                        </Dropdown.Item>
                        {filteredLocations.length > 0 ? (
                          filteredLocations.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              eventKey={item.locationName}
                            >
                              {item.locationName}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item eventKey="" disabled>
                            No locations found
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {errors.location && (
                <div className="error">{errors.location}</div>
              )}
            </div>

            {/* Location Selection */}
            {/* <div className="col-lg-6">
              <div className="select-box">
                <label>
                  Where are you located? <Astericks />
                </label>
                <InputError>
                  {errors.location && <span>{errors.location.message}</span>}
                </InputError>
                <select
                  className="form-select gray-color"
                  {...register("location", {
                    required: locations.length === 1 ? "" : "* required",
                  })}
                  onChange={(e) => {
                    setSelectValue({
                      ...selectValue,
                      location: e.target.value,
                    });
                    getAssetsByLocation(e.target.value);
                  }}
                >
                  {locations.length === 1 ? (
                    <option value={locations[0]?.id}>
                      {locations[0]?.locationName}
                    </option>
                  ) : (
                    <option value="">Select</option>
                  )}
                  {locations.length > 0 &&
                    locations.map((location, index) => (
                      <option key={index} value={location.id}>
                        {location.locationName}
                      </option>
                    ))}
                </select>
              </div>
            </div> */}

            {/* Asset Selection */}
            <div className="col-lg-6">
              <div className="select-box">
                <label>
                  Select the faulty Asset <Astericks />
                </label>
                <InputError>
                  {errors.asset && <span>{errors.asset.message}</span>}
                </InputError>
                <Dropdown className="select__form" onSelect={handleSelectAsset}>
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectValue.asset !== "Select" ? "selected" : ""
                    }`}
                    style={{
                      color: assetId.length > 0 ? "" : "#c5c7cd",
                    }}
                  >
                    {/* Display the selected location name or default to 'Select' */}
                    {selectValue.asset || "Select"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={searchQueryAsset}
                        onChange={(e) => setSearchQueryAsset(e.target.value)}
                        type="text"
                        placeholder="Search Asset"
                      />
                    </div>
                    <div className="dropdown-item-content">
                      {filteredAssets.length > 0 ? (
                        filteredAssets?.map((asset, index) => (
                          <Dropdown.Item key={index} eventKey={asset.assetName}>
                            {asset.assetName}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item disabled>No assets found</Dropdown.Item>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {/* Fault Selection */}
            <div className="col-lg-6">
              <label className="select-box">Select Fault(s)</label>
              <InputError>
                {errors.fault && <span>{errors.fault.message}</span>}
              </InputError>
              <div className="dropdown Checklists-dropdown col-lg-12 bg-[#F1EFEF]">
                <button
                  className="col-lg-12 d-flex justify-content-between align-items-center "
                  data-bs-toggle="dropdown"
                  style={{
                    backgroundColor: "#F1EFEF",
                    border: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    color: selectedFaults.length > 0 ? "" : "#c5c7cd",
                  }}
                >
                  {selectedFaults.length > 0 ? (
                    selectedFaults
                      .map(
                        (faultId) =>
                          faults.find((fault) => fault.id === faultId)
                            ?.faultName
                      )
                      .filter(Boolean)
                      .join(", ")
                  ) : (
                    <p style={{ color: "#c5c7cd" }}>Add Faults</p>
                  )}
                  <DownIcon />
                </button>
                <ul className="dropdown-menu pb-2 col-lg-12">
                  <li>
                    {/* Search Input */}
                    <div
                      className="dropdown-search"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={searchQueryFaults}
                        onChange={(e) => setSearchQueryFaults(e.target.value)}
                        type="text"
                        className="fault-search"
                        placeholder="Search"
                        style={{
                          background: "none",
                          padding: "0",
                          paddingLeft: "10px",
                          width: "100%",
                          border: "0",
                          outline: "none",
                          height: "40px",
                          color: selectedFaults.length > 0 ? "" : "#C5C7CD",
                        }}
                      />
                    </div>
                  </li>
                  <ul className="dropdown-item-content my-2">
                    {filteredFaults.length > 0 ? (
                      filteredFaults.map((fault, index) => (
                        <li key={index}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={fault.id}
                              id={fault.faultName}
                              checked={selectedFaults.includes(fault.id)}
                              style={{
                                backgroundColor: selectedFaults.includes(
                                  fault.id
                                )
                                  ? "#D57D2A"
                                  : "",
                                borderColor: selectedFaults.includes(fault.id)
                                  ? "#D57D2A"
                                  : "",
                              }}
                              onChange={(e) =>
                                handleFaultChange(
                                  parseInt(e.target.value),
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={fault.faultName}
                            >
                              {fault.faultName}
                            </label>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="dropdown-item disabled">
                        No faults found
                      </li>
                    )}
                  </ul>
                </ul>
              </div>
            </div>

            {/* <div className="col-md-6">
              <label className="fw-medium pb-2">Faults</label>
              <Dropdown className="select__form">
                <Dropdown.Toggle className={`select-title ${selectedFaults.length ? "selected" : ""}`} style={{ height: "50px" }}>
                  {selectedFaults.length > 0
                    ? selectedFaults.map((id) => faults?.find((fault) => fault.id === id)?.faultName).join(", ")
                    : "Select Faults"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {faults?.map((fault) => (
                    <Dropdown.Item key={fault.id} eventKey={fault.id} onClick={() => handleFaultChange(fault.id)}>
                      <input
                        type="checkbox"
                        checked={selectedFaults.includes(fault.id)}
                        readOnly
                        style={{ marginRight: "10px" }}
                      />
                      {fault.faultName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}

            {/* Description */}
            <div className="col-lg-6">
              <div className="fs-15">
                Describe Fault <Astericks />
              </div>
              <InputError>
                {errors.description && (
                  <span>{errors.description.message}</span>
                )}
              </InputError>
              <textarea
                className="describe-input mt-3"
                onChange={(e) => setDescription(e.target.value)}
                {...register("description")}
              ></textarea>
            </div>
            {/* <div style={{ margin: "20px 0" }}></div> */}
            <div>
              {/* <div className="washroom-title-request">
                <div className="fs-13 fw-medium">FAULT RECURRENCE</div>
              </div> */}
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #e0e0e0",
                  margin: "10px 0",
                }}
              />
            </div>

            {/* Recurrence */}
            <div className="col-md-6">
              <label className="fs-15 ">
                Is this a first time or recurrent fault? <Astericks />
              </label>
              <div className="check-groupe d-flex gap-5">
                <div className="form-check round-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="first-time"
                    id="first-fault"
                    name="faultType"
                    checked={selectedRadio === "first-time"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" htmlFor="first-fault">
                    First Time Fault?
                  </label>
                </div>
                <div className="form-check round-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="recurrent"
                    id="recurrent-btn"
                    checked={selectedRadio === "recurrent"}
                    onChange={handleRadioChange}
                    name="faultType"
                  />
                  <label className="form-check-label" htmlFor="recurrent-btn">
                    Recurrent Fault?
                  </label>
                </div>
              </div>
            </div>

            {/* Operational Status */}
            <div>
              {/* <div className="washroom-title-request">
                <div className="fs-13 fw-medium">OPERATIONAL STATUS</div>
              </div> */}
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #e0e0e0",
                  margin: "10px 0",
                }}
              />
            </div>

            <div className="col-lg-6">
              <div className="select-box">
                <label>
                  Select operational status
                  {filteredOperationalStatus.length > 0 && <Astericks />}
                </label>

                <InputError>
                  {errors.operationalStatus && (
                    <span>{errors.operationalStatus.message}</span>
                  )}
                </InputError>
                <Dropdown
                  className="select__form"
                  onSelect={handleSelectOperational}
                >
                  <Dropdown.Toggle
                    className="select-title"
                    style={{
                      color: selectValue.status ? "black" : "",
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                    }}
                  >
                    {selectValue.status || "Select Operational Status"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={searchQueryStatus}
                        onChange={(e) => setSearchQueryStatus(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                    <div className="dropdown-item-content">
                      {filteredOperationalStatus.length > 0 ? (
                        filteredOperationalStatus.map((status, index) => (
                          <Dropdown.Item key={index} eventKey={status.label}>
                            {status.label}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item disabled>
                          No operational status found
                        </Dropdown.Item>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <InputError>
                  {operationStatusOptions.length > 0 &&
                    errors.operationalStatus && (
                      <span>{errors.operationalStatus.message}</span>
                    )}
                </InputError>
                {/* Register the operational status field conditionally based on
                operationStatusOptions */}
                {operationStatusOptions.length > 0 && (
                  <input
                    type="hidden"
                    {...register("operationalStatus", {
                      required: "* required",
                    })}
                  />
                )}
              </div>
            </div>
            <div>
              {/* <div className="washroom-title-request">
                <div className="fs-13 fw-medium">ATTACH FILES</div>
              </div> */}
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #e0e0e0",
                  margin: "10px 0",
                }}
              />
            </div>

            {/* Attach Files */}
            <div
              className="col-lg-12"
              style={{
                maxHeighteight: "350px",
                minHeight: attachments.length > 0 ? "250px" : "",
                marginBottom: "0",
                position: "relative",
                zIndex: "2",
              }}
            >
              <label
                htmlFor="fileInput"
                className="attach-button"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "-40%",
                  right: "-4%",
                  zIndex: "5",
                  backgroundColor: "white",
                  color: "rgb(213, 125, 42)",
                  textDecoration: "underline",
                }}
              >
                Add File
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  multiple
                  style={{ display: "none" }}
                />
              </label>
              <div className="fs-15 fadeColor">ATTACH FILES</div>
              <hr className="newHR" />
              <div
                className="attach-file mt-3 d-flex align-items-start"
                style={{ position: "relative" }}
              >
                <span className="d-grid gap-2" style={{ paddingTop: "12px" }}>
                  {attachments.length > 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "0",
                        width: "100%",
                        top: "140%",
                        height: "230px",
                        overflow: "scroll",
                      }}
                    >
                      <p style={{ marginBottom: "20px", color: "#00000061" }}>
                        Documents Uploaded
                      </p>
                      {attachments.map((attachment, index) => (
                        <div
                          key={index}
                          className="fs-14 fw-medium text-black"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            height: "54px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderBottom: "1px solid #00000061",
                            marginTop: "10px",
                          }}
                        >
                          <p
                            style={{
                              color: "#D57D2A",
                              textDecoration: "underline",
                              fontSize: "16px",
                              fontWeight: "500",
                              letterSpacing: "-0.33px",
                              lineHeight: "18.83px",
                            }}
                          >
                            {attachment}
                          </p>
                          <button
                            onClick={() => deleteFile(attachment)}
                            className="ms-2"
                            style={{
                              color: "#D57D2A",
                              fontSize: "16px",
                              gap: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* <IoIosEye size={25} color="#6C5B51" onClick={() => handleShowImg()}/> */}
                            <RiDeleteBinLine size={22} color="#6C5B51" />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span
                      className="fs-15 fw-medium text-black "
                      style={{ fontStyle: "italic" }}
                    ></span>
                  )}
                </span>
              </div>
            </div>
          </div>

          {<ViewImage show={imageShow} onHide={() => setImageShow(false)} />}

          <div className="details-buttons" style={{ position: "relative" }}>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                  position: "absolute",
                  right: "10%",
                  top: "10%",
                }}
              />
            ) : (
              <button
                style={styleBtn}
                type="submit"
                disabled={isLoading}
                className="details-buttons justify-content-center align-content-center"
              >
                {isLoading ? "Submitting" : "Submit"}
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default NewRequestContent;
