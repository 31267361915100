import { useState, useEffect } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DashboardInfoModal from "../WorkOrders/Modal/DashboardInfoModal";
import usefetchData from "../../hooks/useFetchData";
import qs from "qs";
import CountUp from "react-countup";
import { correctiveWork } from "../../InfoData";

const dropdownButtonStyle = {
  border: "none",
  boxShadow: "0px 2px 10px 0px #00000026",
  transition: "box-shadow 0.3s ease",
};

const dropdownButtonHoverStyle = {
  ...dropdownButtonStyle,
  boxShadow: "0px 4px 15px 0px #00000040",
};

const periods = ["Current", "Last 7 days", "Last 30 days", "Last month"];

export default function CorrectiveWork() {
  const [selectedPeriod, setSelectedPeriod] = useState("Current");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermPeriod, setSearchTermPeriod] = useState("");
  const [filteredPeriods, setFilteredPeriods] = useState(periods);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [filters, setFilters] = useState({
    ticketType: "corrective",
    "filter.StartDate": null,
    "filter.EndDate": null,
    "filter.LocationIds": [],
  });

  console.log("date range", dateRange);

  const [modalOpen, setModalOpen] = useState(false);

  const { data: locations } = usefetchData(
    ["partLocation"],
    `/Locations/LocationsList`,
    {},
    "Couldn't get part locations data. Please try again!",
    true
  );

  const { data: workCount, isLoading } = usefetchData(
    ["work-count-corrective", filters],
    `/Dashboard/GetWorkCount`,
    {
      params: filters,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get work count data",
    !!filters
  );
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [filteredLocations, setFilteredLocations] = useState(locations);

  useEffect(() => {
    const filtered =
      locations?.filter((location) =>
        location.locationName.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [];
    setFilteredLocations(filtered);
  }, [searchTerm, locations]);

  const handleLocationClick = (locationId) => {
    setSelectedLocations((prev) => {
      const newSelectedLocations = prev.includes(locationId)
        ? prev.filter((id) => id !== locationId)
        : [...prev, locationId];

      setFilters((prevFilters) => ({
        ...prevFilters,
        "filter.LocationIds": newSelectedLocations,
      }));

      return newSelectedLocations;
    });
  };

  const handleLocationClickAll = () => {
    setSelectedLocations(() => {
      const newSelectedLocations = []; // Clear all selected locations
  
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters["filter.LocationIds"]; // Remove the key entirely
        return updatedFilters;
      });
  
      return newSelectedLocations;
    });
  };

  const isSelected = (locationId) => selectedLocations.includes(locationId);

  useEffect(() => {
    const filtered = periods.filter((period) =>
      period.toLowerCase().includes(searchTermPeriod.toLowerCase())
    );
    setFilteredPeriods(filtered);
  }, [searchTermPeriod]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchChangePeriod = (e) => {
    setSearchTermPeriod(e.target.value);
  };

  const computeDateRange = (period) => {
    const today = new Date();
    let startDate = null;
    let endDate = null;

    // eslint-disable-next-line default-case
    switch (period) {
      case "Current":
        // No date range for current
        break;
      case "Last 7 days":
        endDate = today;
        startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "Last 30 days":
        endDate = today;
        startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "Last month":
        endDate = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        break;
    }

    return { startDate, endDate };
  };

  const handlePeriodSelection = (period) => {
    setSelectedPeriod(period);
    const newDateRange = computeDateRange(period);
    setDateRange(newDateRange);
  };

  useEffect(() => {
    const formatToLocalISOString = (date) => {
      if (!date) return null;
      const offset = date.getTimezoneOffset() * 60000; // Get offset in milliseconds
      const localDate = new Date(date.getTime() - offset); // Adjust to local time
      return localDate.toISOString().slice(0, 19); // Remove 'Z' at the end for local representation
    };

    setFilters((prevFilters) => ({
      ...prevFilters,
      "filter.StartDate": formatToLocalISOString(dateRange.startDate),
      "filter.EndDate": formatToLocalISOString(dateRange.endDate),
    }));
  }, [dateRange]);

  const workItemCount = workCount?.workItemCount;
  const overdueCount = workCount?.overdueCount;

  return (
    <>
      <div className="card " style={{ padding: "17px 34px" }}>
        <div className="card-body p-0">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fs-20 fw-bold card-title mb-0 d-flex align-items-center gap-2">
                Corrective Work
                <div onClick={toggleModal} className="cursor-pointer">
                  <img src="/info.svg" alt="" className="w-[24px] h-[24px]" />
                </div>
              </h5>
            </div>
            <div className="d-flex gap-3">
              <div className="dropdown select-dropdown">
                <button
                  className="select-title"
                  type="button"
                  data-bs-toggle="dropdown"
                  style={
                    hoveredButton === "period"
                      ? dropdownButtonHoverStyle
                      : dropdownButtonStyle
                  }
                  onMouseEnter={() => setHoveredButton("period")}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  <span className="fs-12">Period:</span>
                  <span
                    className="fs-12 d-flex align-items-center gap-1 text-[##00000061]"
                    style={{ color: "#00000061" }}
                  >
                    {selectedPeriod}{" "}
                    <DownIcon className="fs-12" style={{ color: "#000000" }} />
                  </span>
                </button>
                <ul
                  className="dropdown-menu select-menu"
                  style={{ width: "100%", padding: "10px" }}
                >
                  <div
                    className="dropdown-search"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      color: "#c5c7cd",
                      position: "relative",
                      borderRadius: "5px",
                      border: "0.5px solid #f1efef",
                      background: "#fff",
                      marginBottom: "10px",
                      padding: "5px",
                      paddingLeft: "15px",
                    }}
                  >
                    <SearchIcon />
                    <input
                      type="text"
                      placeholder="Search"
                      style={{ border: "none", outline: "none", width: "100%" }}
                      className="dropDownSearch"
                      value={searchTermPeriod}
                      onChange={handleSearchChangePeriod}
                    />
                  </div>
                  <div className="dropdown-item-content">
                    {filteredPeriods.map((period, i) => (
                      <li
                        style={{
                          padding: "6px 0px",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        key={i}
                        onClick={() => handlePeriodSelection(period)}
                      >
                        {period}
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
              <div className="dropdown select-dropdown">
                <button
                  className="select-title"
                  type="button"
                  data-bs-toggle="dropdown"
                  style={
                    hoveredButton === "location"
                      ? dropdownButtonHoverStyle
                      : dropdownButtonStyle
                  }
                  onMouseEnter={() => setHoveredButton("location")}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  <span className="fs-12">Locations:</span>
                  <span
                    className="fs-12 d-flex align-items-center gap-1"
                    style={{ color: "#00000061" }}
                  >
                    {selectedLocations.length === 0
                      ? "All"
                      : `${selectedLocations.length} selected`}{" "}
                    <DownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu select-menu"
                  style={{ width: "100%", padding: "10px" }}
                >
                  <div
                    className="dropdown-search"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      color: "#c5c7cd",
                      position: "relative",
                      borderRadius: "5px",
                      border: "0.5px solid #f1efef",
                      background: "#fff",
                      marginBottom: "10px",
                      padding: "5px",
                      paddingLeft: "15px",
                    }}
                  >
                    <SearchIcon />
                    <input
                      type="text"
                      placeholder="Search"
                      style={{ border: "none", outline: "none", width: "100%" }}
                      className="dropDownSearch"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="dropdown-item-content">
                    <li onClick={handleLocationClickAll}>
                      <b>All</b>
                    </li>
                    <hr />
                    {filteredLocations?.map((location, i) => (
                      <li
                        style={{
                          padding: "4px 0",
                          fontSize: "14px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={location.id}
                        onClick={() => handleLocationClick(location.id)}
                      >
                        {isSelected(location.id) ? (
                          <CheckBoxIcon
                            style={{ color: "#D57D2A", marginRight: "4px" }}
                          />
                        ) : (
                          <CheckBoxOutlineBlankIcon
                            style={{
                              color: "#DFE0EB",
                              marginRight: "4px",
                              border: "1px",
                            }}
                          />
                        )}
                        {location.locationName}
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="d-flex align-item-center justify-content-between">
              <div className="col-md-6">
                <div className="d-flex flex-column align-items-start">
                  <h2 className="display-5 fs-28 fw-bold mb-0">
                    <CountUp
                      start={0}
                      end={workItemCount}
                      duration={2.5}
                      separator=","
                    />
                  </h2>
                  <span className=" fs-16">Work Items</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column fs-12">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <span
                      className="bullet bg-[#6C5B51] rounded-circle"
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#6C5B51",
                      }}
                    ></span>
                    <span>
                      {workCount?.newCount === undefined
                        ? 0
                        : (
                            workCount?.newCount + workCount?.rejectedCount
                          ).toLocaleString()}{" "}
                      New
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <span
                      className="bullet bg-[#D57D2A] rounded-circle"
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#D57D2A",
                      }}
                    ></span>
                    <span>
                      {workCount?.ongoingCount === undefined
                        ? 0
                        : (
                            workCount?.ongoingCount + workCount?.closeMeCount
                          ).toLocaleString()}{" "}
                      Ongoing
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span
                      className="bullet bg-[#EE9C39] rounded-circle"
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#EE9C39",
                      }}
                    ></span>
                    <span>
                      {workCount?.closedCount === undefined
                        ? 0
                        : workCount?.closedCount.toLocaleString()}{" "}
                      Closed
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-top pt-3 text-center">
            <div className="text-muted mb-2">Overdue Work</div>
            <h3 className="fw-bold mb-0">
              <CountUp
                start={0}
                end={overdueCount}
                duration={2.5}
                separator=","
              />
            </h3>
          </div>
        </div>
      </div>
      <DashboardInfoModal
        show={modalOpen}
        toggleModal={toggleModal}
        infoData={correctiveWork}
      />
    </>
  );
}
