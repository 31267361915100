import React, { useState, useContext } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import AddIcon from "../../../Assets/Icons/AddIcon";
import AssingModal from "../Modal/AssingModal";
import AcceptModal from "../Modal/AcceptModal";
import RequestModal from "../Modal/RequestModal";
import ConfirmModal from "../Modal/ConfirmModal";
import RequestClosureModal from "../Modal/RequestClosureModal";
import StartWorkModal from "../Modal/StartWorkModal";
import ConfirmWorkModal from "../Modal/ConfirmWorkModal";
import CloseWorkModal from "../Modal/CloseWorkModal";
import ViewImage from "../Modal/ViewImage";
import ViewVideo from "../Modal/ViewVideo";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import HoldModal from "../Modal/HoldModal";
import AcceptHoldModal from "../Modal/AcceptHoldModal";
import ResumeModal from "../Modal/ResumeModal";
import { toast } from "react-toastify";
import { Add_Ticket_TO_ONHOLD } from "../../../utls/constants";
import { post } from "../../../Services/ApiHelper";
import RequestPtwModal from "../Modal/RequestPtwModal";
import AddDiagnosisRedirectModal from "../Modal/AddDiagnosisRedirectModal";
import { useNavigate } from "react-router-dom";

const ViewHeader = () => {
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [assignShow, setAssignShow] = useState(false);
  const [acceptShow, setAcceptShow] = useState(false);
  const [requestShow, setRequestShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [startWorkShow, setStartWorkShow] = useState(false);
  const [holdShow, setHoldShow] = useState(false);
  const [acceptHoldShow, setAcceptHoldShow] = useState(false);
  const [resumeShow, setResumeShow] = useState(false);
  const [requestClosureShow, setRequestClosureShow] = useState(false);
  const [addDiagnosisRedirectShow, setAddDiagnosisRedirectShow] =
    useState(false);
  const [confirmWorkShow, setConfirmWorkShow] = useState(false);
  const [closeWorkShow, setCloseWorkShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const navigate = useNavigate()
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  let isPTWExternal = JSON.parse(localStorage.getItem("company")).isPTWExternal;
  const IsPTWGenerated = ticketSummary?.workOrderSummary?.isPTWGenerated;
  const ticketId = ticketSummary?.workOrderSummary?.ticketId;
  const assetData = ticketSummary?.assetSummary?.workOrderAssetList;

  return (
    <>
      <div className="work-header">
        <div className="fs-20">View Work</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Assign_Ticket") && (
                <li>
                  <button onClick={() => setAssignShow(true)}>
                    <AddIcon /> Assign Work
                  </button>
                  <AssingModal
                    show={assignShow}
                    onHide={() => setAssignShow(false)}
                  />
                </li>
              )}
            {ticketSummary?.workOrderSummary?.acceptOptionOnly && (
              <li>
                <button onClick={() => setAcceptShow(true)}>
                  <AddIcon /> Accept Work
                </button>
                <AcceptModal
                  show={acceptShow}
                  onHide={() => setAcceptShow(false)}
                />
              </li>
            )}
            {isPTWExternal !== null &&
              IsPTWGenerated === false &&
              claims("Can_Request_PTW") && (
                <li>
                  <button onClick={() => setRequestShow(true)}>
                    <AddIcon /> Request PTW
                  </button>
                  <RequestPtwModal
                    show={requestShow}
                    onHide={() => setRequestShow(false)}
                  />
                </li>
              )}

            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Request_ArrivalOnSite") && (
                <li>
                  <button onClick={() => setRequestShow(true)}>
                    <AddIcon /> Request Arrival
                  </button>
                  <RequestModal
                    show={requestShow}
                    onHide={() => setRequestShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Confirm_ArrivalOnsite") && (
                <li>
                  <button onClick={() => setConfirmShow(true)}>
                    <AddIcon /> Confirm Arrival
                  </button>
                  <ConfirmModal
                    show={confirmShow}
                    onHide={() => setConfirmShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Start_Work") && (
                <li>
                  <button onClick={() => setStartWorkShow(true)}>
                    <AddIcon /> Start Work
                  </button>
                  <StartWorkModal
                    show={startWorkShow}
                    onHide={() => setStartWorkShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Hold_Ticket") && (
                <li>
                  <button onClick={() => setHoldShow(true)}>
                    <AddIcon /> Hold Work
                  </button>
                  <HoldModal
                    show={holdShow}
                    onHide={() => setHoldShow(false)}
                    ticketId={ticketId}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Approve_HoldTicket") && (
                <li>
                  <button onClick={() => setAcceptHoldShow(true)}>
                    <AddIcon /> Confirm Hold Work
                  </button>
                  <AcceptHoldModal
                    show={acceptHoldShow}
                    onHide={() => setAcceptHoldShow(false)}
                    ticketId={ticketId}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Resume_HeldTicket") && (
                <li>
                  <button onClick={() => setResumeShow(true)}>
                    <AddIcon /> Resume Work
                  </button>
                  <ResumeModal
                    show={resumeShow}
                    onHide={() => setResumeShow(false)}
                    ticketId={ticketId}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Request_JobClosure") && (
                <li>
                  <button
                    onClick={() => {
                      if (assetData[0]?.assetDiagnosisList?.length > 0) {
                        setRequestClosureShow(true);
                      } else {
                        setAddDiagnosisRedirectShow(true);
                      }
                    }}
                  >
                    <AddIcon /> Request Closure
                  </button>
                  <RequestClosureModal
                    show={requestClosureShow}
                    onHide={() => setRequestClosureShow(false)}
                  />
                  <AddDiagnosisRedirectModal
                    show={addDiagnosisRedirectShow}
                    onHide={() => setAddDiagnosisRedirectShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Confirm_WorkDone") && (
                <li>
                  <button onClick={() => navigate(`/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/confirm-work`)}>
                    <AddIcon /> Confirm Work
                  </button>
                  <ConfirmWorkModal
                    show={confirmWorkShow}
                    onHide={() => setConfirmWorkShow(false)}
                  />
                </li>
              )}
            {/*
            <li>
              <button onClick={() => setCloseWorkShow(true)}>
                <AddIcon /> Close Work
              </button>
              <CloseWorkModal
                show={closeWorkShow}
                onHide={() => setCloseWorkShow(false)}
              />
            </li>
            */}
            {/* <li>
              <button onClick={() => setImageShow(true)}>
                <AddIcon /> Image
              </button>
              <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
            </li>
            <li>
              <button onClick={() => setVideoShow(true)}>
                <AddIcon /> Video
              </button>
              <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ViewHeader;
