import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { post } from "../../../Services/ApiHelper";
import { ADD_TICKET_DIAGNOSIS } from "../../../utls/constants";
import Astericks from "../../Common/Asterick";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { useSelector } from "react-redux";

const UpdatedAddDiagnosisModal = (props) => {
  const [fault, setFault] = useState("");
  const [cause, setCause] = useState("");
  const [solution, setSolution] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [recommendationError, setRecommendationError] = useState(null);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);

  const formState = useSelector((state) => state.form);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  const [assets, setAssets] = useState([]);

  const [faultError, setFaultError] = useState(null);
  const [causeError, setCauseError] = useState(null);
  const [solutionError, setSolutionError] = useState(null);

  const [assetSearch, setAssetSearch] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("Select Asset");
  const [selectedAssetId, setSelectedCategoryId] = useState(null);

  const filteredAssetData =
    ticketSummary?.assetSummary?.workOrderAssetList?.filter((item) => {
      return item?.assetName.toLowerCase().includes(assetSearch.toLowerCase());
    });

  const handleCategorySelect = (eventKey) => {
    const id = ticketSummary?.assetSummary?.workOrderAssetList?.find(
      (item) => item.assetName === eventKey
    )?.id;

    setSelectedCategoryId(id);

    setSelectedAsset(eventKey);
    setAssetSearch("");
  };

  useEffect(() => {
    if (ticketSummary?.assetSummary?.workOrderAssetList?.length === 1) {
      setSelectedAsset(
        ticketSummary?.assetSummary?.workOrderAssetList[0].assetName
      );
      setSelectedCategoryId(
        ticketSummary?.assetSummary?.workOrderAssetList[0].id
      );
    } else {
      setSelectedAsset("Select Asset");
      setSelectedCategoryId(null);
    }
    setSolution("");
    setCause("");
    setFault("");
    setRecommendation("");
  }, [props.show]);

  const validateInputs = () => {
    let isValid = true;

    setFaultError(null);
    setCauseError(null);
    setSolutionError(null);
    setRecommendationError(null);

    if (!fault) {
      setFaultError("Required *");
      isValid = false;
    }

    if (!cause) {
      setCauseError("Required *");
      isValid = false;
    }

    if (!solution) {
      setSolutionError("Required *");
      isValid = false;
    }

    if (!recommendation) {
      setRecommendationError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }
    setIsLoading(true);

    const payload = {
      ticketAssetId: selectedAssetId,
      part: selectedAsset,
      diagnosis: cause,
      diagnosisDescription: cause,
      solution: solution,
      recommendations: recommendation,
    };
    console.log(payload);

    post(ADD_TICKET_DIAGNOSIS, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          const newPartsData = {
            id: response.data,
            diagnosis: cause,
            diagnosisDescription: cause,
            part: fault,
            solution: solution,
            ticketAssetId: selectedAssetId,
            recommendations: recommendation,
          };
          const updatedWorkOrderAssetList =
            ticketSummary?.assetSummary?.workOrderAssetList?.map((asset) => {
              if (asset.id === selectedAssetId) {
                return {
                  ...asset,
                  assetDiagnosisList: [
                    ...(asset.assetDiagnosisList || []),
                    newPartsData,
                  ],
                };
              }

              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));

          toast.success(response.messages[0], { autoClose: 3000 });
        } else {
          toast.error(response.messages[0], { autoClose: 3000 });
        }
      })
      .catch(() => {
        toast.error("An error occurred", { autoClose: 3000 });
      })
      .finally(() => {
        setIsLoading(false);
        props.onHide();
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Diagnosis</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Provide diagnosis for work done
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Asset/ Equipment
                  <Astericks />
                </label>
                {solutionError && (
                  <p style={{ color: "red" }}>{solutionError}</p>
                )}
              </div>
              <Dropdown
                className="select__form"
                onSelect={(eventKey) => handleCategorySelect(eventKey)}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedAsset !== "Select Asset" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedAsset}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={assetSearch}
                      onChange={(e) => setAssetSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredAssetData &&
                      filteredAssetData?.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.assetName}>
                          {item.assetName}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  What was faulty?
                  <Astericks />
                </label>
                {faultError && <p style={{ color: "red" }}>{faultError}</p>}
              </div>
              <input
                onChange={(e) => setFault(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter the part that was worked on"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  What caused the fault (Diagnosis)?
                  <Astericks />
                </label>
                {causeError && <p style={{ color: "red" }}>{causeError}</p>}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setCause(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter description of the issue"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  How did you solve the fault (Solution)?
                  <Astericks />
                </label>
                {solutionError && (
                  <p style={{ color: "red" }}>{solutionError}</p>
                )}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setSolution(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter solution provided"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Recommendations - What can be done to avert the issue in the
                  future?
                  <Astericks />
                </label>
                {recommendationError && (
                  <p style={{ color: "red" }}>{recommendationError}</p>
                )}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setRecommendation(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter solution provided"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={handleSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatedAddDiagnosisModal;
