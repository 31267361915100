import React, { useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import "./workOrderConfirmWork.scss";
import { WorkOrderContext } from "../../../../Context/Inspections/WorkOrderContext";
import DeleteIcon from "../../../../Assets/Icons/DeleteIcon";
import EditIcon2 from "../../../../Assets/Icons/EditIcon2";
import EyesIcon from "../../../../Assets/Icons/EyesIcon";
import AddDiagnosisModal from "../../Modal/AddDiagnosisModal";
import UpdatedAddDiagnosisModal from "../../Modal/UpdatedAddDiagnosisModal";
import AddFileModal from "../../Modal/AddFileModal";
import {
  DELETE_TICKET_DIAGNOSIS,
  DELETE_TICKET_FILE,
  DELETE_TICKET_LABOUR_COST,
  DELETE_TICKET_PART_COST,
  DELETE_TICKET_RETURNED_PARTS,
  DELETE_TICKET_USED_PARTS,
} from "../../../../utls/constants";
import ViewImage from "../../Modal/ViewImage";
import ViewVideo from "../../Modal/ViewVideo";
import ViewDocument from "../../Modal/ViewDocument";
import DeleteModal from "../../Modal/DeleteModal";
import { toast } from "react-toastify";
import { post } from "../../../../Services/ApiHelper";
import { Accordion, Nav, Tab } from "react-bootstrap";
import AddPartsModal from "../../Modal/AddPartsModal";
import usefetchData from "../../../../hooks/useFetchData";
import { useDispatch } from "react-redux";
import { setPartCategories } from "../../../../redux/formSlice";
import EditReturnedPartsModal from "../../Modal/EditReturnedPartsModal";
import EditUsedPartsModal from "../../Modal/EditUsedPartsModal";
import AddCommentModal from "../../Modal/AddCommentModal";
import { useLocation, useNavigate } from "react-router-dom";
import RequestClosureModal from "../../Modal/RequestClosureModal";
import AddDiagnosisRedirectModal from "../../Modal/AddDiagnosisRedirectModal";
import ConfirmWorkModal from "../../Modal/ConfirmWorkModal";

function WorkOrderConfirmWorkPage() {
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const assetData = ticketSummary?.assetSummary?.workOrderAssetList;
  const workData = ticketSummary?.workOrderSummary;
  const showPage = ticketSummary != undefined;

  const tableData = [
    { id: 1, partName: "Engine", asset: "Car", quantity: 2 },
    { id: 2, partName: "Propeller", asset: "Boat", quantity: 1 },
    { id: 3, partName: "Wing", asset: "Plane", quantity: 4 },
  ];

  const [addDiagnosisShow, setAddDiagnosisShow] = useState(false);
  const [addFileShow, setAddFileShow] = useState(false);
  const [addUsedPartsShow, setAddUsedPartsShow] = useState(false);
  const [confirmWorkShow, setConfirmWorkShow] = useState(false);

  const [selectedParts, setSelectedParts] = useState([]);
  const [action, setAction] = useState("addticketusedparts");
  const [editReturnData, setEditReturnData] = useState({});
  const [editReturnedPartsShow, setEditReturnedPartsShow] = useState(false);
  const [editData, setEditData] = useState({});
  const [editUsedShow, setEditUsedShow] = useState(false);
  const [requestClosureShow, setRequestClosureShow] = useState(false);
  const [commentShow, setCommentShow] = useState(false);

  const [labourDelete, setLabourDelete] = useState(false);
  const [others, setOthers] = useState(false);
  const [used, setUsed] = useState(false);
  const [diagnosis, setDiagnosis] = useState(false);
  const [addDiagnosisRedirectShow, setAddDiagnosisRedirectShow] =
    useState(false);

  const [delateModalShow, setDelateModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [fileDelete, setFileDelete] = useState(false);
  const [returned, setReturned] = useState(false);
  const [diagnosisArray, setDiagnosisArray] = useState([]);
  const [modalId, setModalId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [eventKey, setEventKey] = useState("");

  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
    isFileLoading: false,
  });

  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: "",
    file_name: "",
  });

  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: "",
    file_name: "",
  });

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${period}`;
  }

  const handleDelete = async () => {
    setIsLoading(false);
    try {
      const bearerToken = localStorage.getItem("bearerToken");

      const endPoint = fileDelete
        ? DELETE_TICKET_FILE
        : labourDelete
        ? DELETE_TICKET_LABOUR_COST
        : others
        ? DELETE_TICKET_PART_COST
        : used
        ? DELETE_TICKET_USED_PARTS
        : returned
        ? DELETE_TICKET_RETURNED_PARTS
        : diagnosis
        ? DELETE_TICKET_DIAGNOSIS
        : "";

      const data = { id: itemIdToDelete };
      await post(endPoint, data, bearerToken);

      const updatedAssetData = assetData.map((asset) => {
        if (asset.partsUsedList) {
          asset.partsUsedList = asset.partsUsedList.filter(
            (item) => item.id !== itemIdToDelete
          );
        }
        if (asset.partsReturnedList) {
          asset.partsReturnedList = asset.partsReturnedList.filter(
            (item) => item.id !== itemIdToDelete
          );
        }
        if (asset.assetDiagnosisList) {
          asset.assetDiagnosisList = asset.assetDiagnosisList.filter(
            (item) => item.id !== itemIdToDelete
          );
        }
        return asset;
      });

      setTicketSummary((prevState) => ({
        ...prevState,
        assetSummary: {
          ...prevState.assetSummary,
          workOrderAssetList: updatedAssetData,
        },
      }));

      if (fileDelete) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketFileList: prevState.workOrderSummary.ticketFileList.filter(
              (item) => item.id !== itemIdToDelete
            ),
          },
        }));
      } else if (labourDelete) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketLabourList:
              prevState.workOrderSummary.ticketLabourList.filter(
                (item) => item.id !== itemIdToDelete
              ),
          },
        }));
      } else if (others) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketOtherCostList:
              prevState.workOrderSummary.ticketOtherCostList.filter(
                (item) => item.id !== itemIdToDelete
              ),
          },
        }));
      } else if (used) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketOtherCostList:
              prevState.workOrderSummary.ticketOtherCostList.filter(
                (item) => item.id !== itemIdToDelete
              ),
          },
        }));
      }

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsLoading(true);

      setTimeout(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const { data: parts } = usefetchData(
    ["parts"],
    `/Parts/GetPartsForTicket`,
    {},
    "Couldn't get parts data. Please try again!",
    true
  );

  const { data: partCategories } = usefetchData(
    ["parts-categories"],
    `/Parts/GetPartForTicketCategories`,
    {},
    "Couldn't get parts data. Please try again!",
    true
  );

  const dispatch = useDispatch();

  dispatch(setPartCategories(partCategories));
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/diagnosis`
    ) {
      setEventKey("4");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/confirm-work`
    ) {
      setEventKey("5");
    }
  }, [location, ticketSummary]);

  const [confirmState, setConfirmState] = useState("");

  const returnedParts = assetData?.map((item) => item.partsReturnedList);
  const usedParts = assetData?.map((item) => item.partsUsedList);
  const commentsList =
    ticketSummary?.workOrderSummary?.ticketSparePartCommentList;

  const handleReload = () => {
    setEventKey(0);
    navigate(
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
    );
    window.location.reload();
  };

  const isClosedOrRejected =
    ticketSummary?.workOrderSummary?.currentTicketActivity?.toLowerCase() ===
      "rejected" ||
    ticketSummary?.workOrderSummary?.currentTicketActivity?.toLowerCase() ===
      "closed";
  console.log(
    "canViewApproval",
    ticketSummary?.workOrderSummary?.currentTicketActivity?.toLowerCase()
  );
  return (
    eventKey && (
      <>
        <div className="w_o_d_main">
          <div className="w_o_d_TopNav">
            <div
              className="w_o_d_TopNav_inner"
              onClick={() =>
                navigate(
                  `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
                )
              }
            >
              <FaChevronLeft size={20} className="brown cursor" />
              <h1 className="w_o_d_MainH1 w_o_d_MainH1NoMargin brown cursor">
                BACK TO WORK ORDER INFO
              </h1>
            </div>
          </div>
          <div className="w_o_d_TopHeader">
            <div className="w_o_d_TopHeader_inner flex_space_btw">
              <h1 className="w_o_d_MainH1 gray">
                ADD DIAGNOSIS, PARTS INFORMATION & FILES
              </h1>
              {isClosedOrRejected === true ? (
                ""
              ) : (
                <div className="approveRejectBtn">
                  <button
                    className={`backgroundBrown white ${
                      assetData[0]?.assetDiagnosisList?.length === 0
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      setConfirmWorkShow(true);
                      setConfirmState("Approve");
                    }}
                    // disabled={assetData?.every(
                    //   (asset) => asset?.assetDiagnosisList?.length === 0
                    // )}
                  >
                    Approve
                  </button>
                  <button
                    className={`backgroundBrown white darkBrownBG ${
                      assetData[0]?.assetDiagnosisList?.length === 0
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      setConfirmWorkShow(true);
                      setConfirmState("Reject");
                    }}
                  >
                    Reject
                  </button>
                  <ConfirmWorkModal
                    show={confirmWorkShow}
                    onHide={() => setConfirmWorkShow(false)}
                    confirmState={confirmState}
                    onPostSuccess={handleReload}
                  />
                </div>
              )}
              {/* {isClosedOrRejected && (
                <div className="approveRejectBtn">
                  <button
                    className={`backgroundBrown white ${
                      assetData[0]?.assetDiagnosisList?.length === 0
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      setConfirmWorkShow(true);
                      setConfirmState("Approve");
                    }}
                    // disabled={assetData?.every(
                    //   (asset) => asset?.assetDiagnosisList?.length === 0
                    // )}
                  >
                    Approve
                  </button>
                  <button
                    className={`backgroundBrown white darkBrownBG ${
                      assetData[0]?.assetDiagnosisList?.length === 0
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      setConfirmWorkShow(true);
                      setConfirmState("Reject");
                    }}
                  >
                    Reject
                  </button>
                  <ConfirmWorkModal
                    show={confirmWorkShow}
                    onHide={() => setConfirmWorkShow(false)}
                    confirmState={confirmState}
                    onPostSuccess={handleReload}
                  />
                </div>
              )} */}
            </div>
          </div>

          <div className="mainBorder">
            <div className="diagnosisInfoArea">
              <div className="diagnosisInfoInner">
                <div className="d_i_i_TopHeader borderBottom1 flex_space_btw">
                  <h1 className="darkBrown">
                    {" "}
                    Work Order Ref:{" "}
                    <span className="gray">
                      {ticketSummary?.workOrderSummary?.ticketRef}
                    </span>
                  </h1>
                  <h1 className="darkBrown">
                    {" "}
                    Location:{" "}
                    <span className="gray txtCapitalized">
                      {ticketSummary?.workOrderSummary?.locationName}
                    </span>
                  </h1>
                </div>

                <div className="workOverViewArea">
                  <div className="w-o-a-Container">
                    <h1 className="brown w_o_d_MainH1 borderBottom2 w-o-a_MainH">
                      WORK OVERVIEW
                    </h1>
                    <div className="flex_space_btw2 margin12">
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Work Title:</h3>
                        <p className="w-o-aPtag black">
                          {workData?.ticketTitle}{" "}
                        </p>
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container60">
                        <h3 className="w-o-aH3 gray">Description:</h3>
                        <p className="w-o-aPtag black">
                          {workData?.ticketDescription}
                        </p>
                      </div>
                    </div>
                    <div className="flex_space_btw2 marginTop20">
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Equipment:</h3>

                        {ticketSummary?.assetSummary?.workOrderAssetList
                          ?.length > 0
                          ? ticketSummary.assetSummary.workOrderAssetList
                              .flatMap((item) => item.assetName.split(", "))
                              .map((pump, i) => (
                                <div key={i} style={{ marginBottom: "4px" }}>
                                  {pump.trim()}
                                </div>
                              ))
                          : "null"}
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Work Category:</h3>
                        <p className="w-o-aPtag black">
                          {workData?.categoryOfWorkName}
                        </p>
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Work Priority:</h3>
                        <p className="w-o-aPtag black">
                          {workData?.priorityName}
                        </p>
                      </div>
                    </div>
                    <div className="flex_space_btw2 marginTop20">
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Team Assigned:</h3>
                        <p className="w-o-aPtag black">
                          {workData?.assignedTeamName}
                        </p>
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">User Assigned:</h3>
                        <p className="w-o-aPtag black">
                          {workData?.assignedUserName}
                        </p>
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Completion Details:</h3>
                        <p className="w-o-aPtag black">
                          Is signature required:{" "}
                          {workData?.technicianSignature ? "YES" : "NO"}
                        </p>
                      </div>
                    </div>
                    <div className="flex_space_btw2 marginTop20">
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">PTW Details:</h3>
                        <p className="w-o-aPtag black">
                          Is PTW Required:{" "}
                          {workData?.requiresPTW ? "YES" : "NO"}
                        </p>
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Submitted On:</h3>
                        <p className="w-o-aPtag black">
                          {formatDate(workData?.dateSubmitted)}
                        </p>
                      </div>
                      <div className="w-o-aH3Container w-o-aH3Container40">
                        <h3 className="w-o-aH3 gray">Completed On:</h3>
                        <p className="w-o-aPtag black">
                          {formatDate(workData?.currentSLAEndDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="diagnosisTable">
                  <div className="diagnosisTableHeader">
                    <h1 className="brown w_o_d_MainH1 borderBottom2 diagnosisTableH1">
                      DIAGNOSIS INFORMATION
                    </h1>

                    {assetData?.length > 0 ? (
                      assetData?.map((item, i) =>
                        item.assetDiagnosisList?.length > 0
                          ? item?.assetDiagnosisList?.map((diag, j) => (
                              <div
                                className="diagnosisTableContent borderBottom3"
                                key={`${i}-${j}`}
                              >
                                <div className="d_t_c_left">
                                  <div className="d_t_c_Part">
                                    <span className="d_t_c_spanBold d_t_c_spanBoldPart gray2">
                                      PART:
                                    </span>{" "}
                                    <span className="gray">
                                      {item?.assetName}
                                    </span>
                                  </div>
                                  <div className="d_t_c_Asset">
                                    <span className="d_t_c_spanBold gray2">
                                      Asset/ Equipment:
                                    </span>{" "}
                                    <span className="gray">
                                      {item?.assetName}
                                    </span>
                                  </div>
                                  <div className="d_t_c_Asset">
                                    <span className="d_t_c_spanBold gray2">
                                      Diagnosis:
                                    </span>{" "}
                                    <span className="gray">
                                      {diag?.diagnosis}
                                    </span>
                                  </div>
                                  <div className="d_t_c_Solution">
                                    <span className="d_t_c_spanBold gray2">
                                      Solution:
                                    </span>{" "}
                                    <span className="gray">
                                      {diag?.solution}
                                    </span>
                                  </div>
                                  <div className="d_t_c_Recommendation">
                                    <span className="d_t_c_spanBold gray2">
                                      Recommendation:
                                    </span>{" "}
                                    <span className="gray">
                                      {diag?.recommendations}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""
                      )
                    ) : (
                      <div className="flexCenter">
                        <p className="gray">No Data Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <UpdatedAddDiagnosisModal
                ticketAssetId={modalId}
                show={addDiagnosisShow}
                onHide={() => setAddDiagnosisShow(false)}
              />
            </div>

            <div className="diagnosisPartsInfoArea">
              <div className="d_p_i_Inner">
                <div className="d_p_i_MainHeader">
                  <h1 className="brown w_o_d_MainH1 borderBottom2 diagnosisTableH1">
                    PARTS INFORMATION
                  </h1>
                </div>

                <div className="usedReturnedComments">
                  <div>
                    <h5>Used Parts:</h5>
                    {!usedParts?.some((subArray) => subArray.length > 0) ? (
                      <div>
                        <b
                          style={{
                            color: "rgba(0, 0, 0, 0.4)",
                            fontSize: "14px",
                          }}
                        >
                          N/A
                        </b>
                      </div>
                    ) : (
                      <div className="usedReturnedCommentContent">
                        {assetData?.map((item, i) =>
                          item?.partsUsedList?.map((part, index) => {
                            return (
                              <div key={index}>
                                <span>{index + 1}. </span>
                                <span>{part?.partName}</span>
                                <span>-</span>
                                <span>{part?.assetName}</span>
                                <span>-</span>
                                <span>{part?.quantityUsed}</span>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    <h5>Returned Parts:</h5>

                    {!returnedParts?.some((subArray) => subArray.length > 0) ? (
                      <div>
                        <b
                          style={{
                            color: "rgba(0, 0, 0, 0.4)",
                            fontSize: "14px",
                          }}
                        >
                          N/A
                        </b>
                      </div>
                    ) : (
                      <div className="usedReturnedCommentContent">
                        {assetData
                          ?.flatMap((item) =>
                            item?.partsReturnedList?.map((part, index) => part)
                          )
                          .map((part, index) => (
                            <div key={index}>
                              <span>{index + 1}. </span>
                              <span>{part?.partName}</span>
                              <span> - </span>
                              <span>{part?.assetName}</span>
                              <span> - </span>
                              <span>{part?.quantityReturned}</span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <h5>Comments:</h5>
                    <div className="usedReturnedCommentContent">
                      {commentsList.length === 0 ? (
                        <div>
                          <b
                            style={{
                              color: "rgba(0, 0, 0, 0.4)",
                              fontSize: "14px",
                            }}
                          >
                            N/A
                          </b>
                        </div>
                      ) : (
                        <ul>
                          {ticketSummary?.workOrderSummary?.ticketSparePartCommentList
                            ?.slice(-1)
                            .map((comment, index) => (
                              <li key={index} className="customListItem">
                                <span>1. </span>
                                <span>{comment?.sparesComment}</span>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="diagnosisWorkFilesInfo">
              <div className="d-w-i-inner">
                <div className="d_p_i_MainHeader">
                  <h1 className="brown w_o_d_MainH1 borderBottom2 diagnosisTableH1">
                    WORK PHOTOS
                  </h1>
                </div>

                <div className="diagnosisImgContainer">
                  {ticketSummary?.workOrderSummary?.ticketFileList?.length >
                  0 ? (
                    ticketSummary?.workOrderSummary?.ticketFileList?.map(
                      (item, i) => {
                        return (
                          <div className="w-o-aH3Container29" key={i}>
                            <div
                              className="d-w-i-Content-left "
                              onClick={() => {
                                const url = item?.url;
                                const ext = url
                                  .substring(url.lastIndexOf(".") + 1)
                                  .toLowerCase(); // Normalize extension to lowercase

                                if (["jpg", "jpeg", "png"].includes(ext)) {
                                  setImageShow({
                                    isShow: true,
                                    image: item.url,
                                    file_name: item.fileName,
                                  });
                                } else if (ext === "pdf") {
                                  setDocumentShow({
                                    isShow: true,
                                    document: item.url,
                                    file_name: item.fileName,
                                  });
                                } else if (
                                  ["mp4", "avi", "3gp"].includes(ext)
                                ) {
                                  setVideoShow({
                                    isShow: true,
                                    video: item.url,
                                    file_name: item.fileName,
                                  });
                                }
                              }}
                            >
                              <img
                                src={item?.url}
                                className="imgDiagnosis"
                                alt={item?.fileName}
                              />
                              <p className="white imgDiagnosisPtag">
                                {item?.fileName}
                              </p>
                            </div>
                            {/* <div className="d-w-i-Content-right">
                          <button
                            onClick={() => {
                              const url = item?.url;
                              const ext = url
                                .substring(url.lastIndexOf(".") + 1)
                                .toLowerCase(); // Normalize extension to lowercase

                              if (["jpg", "jpeg", "png"].includes(ext)) {
                                setImageShow({
                                  isShow: true,
                                  image: item.url,
                                  file_name: item.fileName,
                                });
                              } else if (ext === "pdf") {
                                setDocumentShow({
                                  isShow: true,
                                  document: item.url,
                                  file_name: item.fileName,
                                });
                              } else if (["mp4", "avi", "3gp"].includes(ext)) {
                                setVideoShow({
                                  isShow: true,
                                  video: item.url,
                                  file_name: item.fileName,
                                });
                              }
                            }}
                            style={{ marginRight: "5px" }}
                          >
                            <EyesIcon />
                          </button>

                          <button
                            onClick={() => {
                              setDelateModalShow(true);
                              setFileDelete(true);
                              setItemIdToDelete(item?.id);
                            }}
                            style={{ marginRight: "5px" }}
                          >
                            <DeleteIcon />
                          </button>
                        </div> */}
                            <ViewImage
                              show={imageShow}
                              onHide={() => setImageShow(false)}
                            />
                            <ViewVideo
                              show={videoShow}
                              onHide={() => setVideoShow(false)}
                            />
                            <ViewDocument
                              show={documentShow}
                              onHide={() => setDocumentShow(false)}
                            />
                            <DeleteModal
                              show={delateModalShow}
                              onHide={() => setDelateModalShow(false)}
                              onDelete={handleDelete}
                            />
                            <EditReturnedPartsModal
                              data={editReturnData}
                              show={editReturnedPartsShow}
                              onHide={() => setEditReturnedPartsShow(false)}
                            />
                            <EditUsedPartsModal
                              data={editData}
                              show={editUsedShow}
                              onHide={() => setEditUsedShow(false)}
                            />
                            <AddCommentModal
                              show={commentShow}
                              onHide={() => setCommentShow(false)}
                            />
                            <AddDiagnosisRedirectModal
                              show={addDiagnosisRedirectShow}
                              onHide={() => setAddDiagnosisRedirectShow(false)}
                            />
                            <RequestClosureModal
                              show={requestClosureShow}
                              onHide={() => setRequestClosureShow(false)}
                              handleReload={handleReload}
                            />
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="flexCenter">
                      <p className="gray">No File Data Found</p>
                    </div>
                  )}
                </div>

                {/* <div className="addFilesInDiagnosisBTN">
                <button
                  className="brown underline cursor"
                  onClick={() => setAddFileShow(true)}
                >
                  Add Files
                </button>
              </div> */}
                <AddFileModal
                  show={addFileShow}
                  onHide={() => setAddFileShow(false)}
                />
              </div>
            </div>
            <div className="diagnosisWorkFilesInfo">
              <div className="d-w-i-inner">
                <div className="d_p_i_MainHeader">
                  <h1 className="brown w_o_d_MainH1 borderBottom2 diagnosisTableH1">
                    SIGNATURE
                  </h1>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "normal",
                    gap: "1.5rem",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <p className="w-o-aPtag black">Close Work Requestor</p>
                    <h3 className="w-o-aH3 gray">
                      <span style={{ marginRight: "5px" }}>Name :</span>
                      {
                        ticketSummary?.workOrderSummary
                          ?.ticketRequestorSignature?.userName
                      }{" "}
                      {}
                    </h3>
                    <div className="diagnosisImgContainer2">
                      {ticketSummary?.workOrderSummary
                        ?.ticketRequestorSignature ? (
                        <div className="">
                          <div className="d-w-i-Content-left ">
                            <img
                              src={
                                ticketSummary?.workOrderSummary
                                  ?.ticketRequestorSignature?.url
                              }
                              className="imgDiagnosis2"
                              alt={
                                ticketSummary?.workOrderSummary
                                  ?.ticketRequestorSignature?.fileName
                              }
                            />
                          </div>

                          <ViewImage
                            show={imageShow}
                            onHide={() => setImageShow(false)}
                          />
                          <ViewVideo
                            show={videoShow}
                            onHide={() => setVideoShow(false)}
                          />
                          <ViewDocument
                            show={documentShow}
                            onHide={() => setDocumentShow(false)}
                          />
                        </div>
                      ) : (
                        <div className="flexCenter">
                          <p className="gray">No File Data Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p className="w-o-aPtag black">Close Work Approver</p>
                    <h3 className="w-o-aH3 gray">
                      {/* <span style={{ marginRight: "5px" }}>Name :</span>
                      {
                        ticketSummary?.workOrderSummary
                          ?.ticketApproverSignature?.userName
                      }{" "}
                      {} */}
                    </h3>
                    <div className="diagnosisImgContainer2">
                      {ticketSummary?.workOrderSummary
                        ?.ticketApproverSignature ? (
                        <div className="">
                          <div className="d-w-i-Content-left ">
                            <img
                              src={
                                ticketSummary?.workOrderSummary
                                  ?.ticketApproverSignature?.url
                              }
                              className="imgDiagnosis2"
                              alt={
                                ticketSummary?.workOrderSummary
                                  ?.ticketApproverSignature?.fileName
                              }
                            />
                          </div>

                          <ViewImage
                            show={imageShow}
                            onHide={() => setImageShow(false)}
                          />
                          <ViewVideo
                            show={videoShow}
                            onHide={() => setVideoShow(false)}
                          />
                          <ViewDocument
                            show={documentShow}
                            onHide={() => setDocumentShow(false)}
                          />
                        </div>
                      ) : (
                        <div className="flexCenter">
                          <p className="gray">No File Data Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default WorkOrderConfirmWorkPage;
