import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  EDIT_TICKET_ASSET_USED_PARTS,
  EDIT_TICKET_USED_PARTS,
  GET_ALL_PARTS,
  GET_SIMPLE_LOCATION_LIST,
} from "../../../utls/constants";
import { set } from "lodash";

const EditUsedPartsModal = ({ show, onHide, data }) => {
  const [selectedPart, setSelectedPart] = useState(data.selectedPart);
  const [selectedPartNumber, setSelectedPartNumber] = useState(
    data.partSerialNumber
  );
  const [selectedPartId, setSelectedPartId] = useState(data.partId);
  const [selectedPartLocationId, setSelectedPartLocationId] = useState(
    data.partLocationId
  );
  const [selectedPartLocation, setSelectedPartLocation] = useState(
    data.locationName
  );
  const [quantity, setQuantity] = useState(data.quantityUsed);
  const [location, setLocation] = useState([]);
  const [parts, setParts] = useState([]);
  const token = localStorage.getItem("bearerToken");
  //get search input
  const [partSearch, setPartSearch] = useState("");
  const [partLocationSearch, setPartLocationSearch] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedPart(data.partName);
    setSelectedPartLocation(data.locationName);
    setQuantity(data.quantityUsed);
    setSelectedPartId(data.partId);
    setSelectedPartLocationId(data.partLocationId);
    setSelectedPartNumber(data.partSerialNumber);

    get(GET_SIMPLE_LOCATION_LIST, token)
      .then((data) => setLocation(data))
      .catch((error) => console.log(error));

    get(GET_ALL_PARTS, token)
      .then((response) => setParts(response))
      .catch((error) => console.log(error));
  }, [data]);
  useEffect(() => {
    setSelectedPart("Select Part");
    setSelectedPartLocation("Select location of part");
    setQuantity(0);
  }, [show]);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  //filter part data  by search input
  const filteredPartData = parts.filter((item) =>
    item?.partName.toLowerCase().includes(partSearch.toLowerCase())
  );
  //filter part location data  by search input
  const filteredPartLocationData = location.filter((item) =>
    item.locationName.toLowerCase().includes(partLocationSearch.toLowerCase())
  );

  //handle select part
  const handlePartSelect = (eventKey) => {
    const partObject = parts.find((p) => p.partName === eventKey);

    if (partObject) {
      setSelectedPart(eventKey);
      setSelectedPartId(partObject.id);
      setSelectedPartNumber(partObject.partNumber);
    }

    setPartSearch("");
  };
  const handlePartLocationSelect = (eventKey) => {
    const locationObject = location.find((l) => l.locationName === eventKey);

    if (locationObject) {
      setSelectedPartLocation(eventKey);
      setSelectedPartLocationId(locationObject.id);
    }
    setPartLocationSearch("");
  };

  const onSubmit = () => {
    setIsLoading(true);
    const payload = {
      id: data.id,
      quantityUsed: quantity == 0 ? data.quantityUsed : quantity,
    };

    post(EDIT_TICKET_ASSET_USED_PARTS, payload, token)
      .then((response) => {
        setIsLoading(false);
        if (response.succeeded) {
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const newPartsData = {
            partLocationId:
              selectedPartLocationId == 0
                ? data.partLocationId
                : selectedPartLocationId,
                assetName: data?.assetName,
            locationName:
              selectedPartLocation == "Select location of part"
                ? data.locationName
                : selectedPartLocation,
            partId: selectedPartId == 0 ? data.partId : selectedPartId,
            partName:
              selectedPart == "Select Part" ? data.partName : selectedPart,
            quantityUsed: quantity == 0 ? data.quantityUsed : quantity,
            ticketAssetId: ticketId == 0 ? data.ticketAssetId : ticketId,
            partSerialNumber:
              selectedPartNumber == 0
                ? data.partSerialNumber
                : selectedPartNumber,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList.map((asset) => {
              if (asset.partsUsedList) {
                const index = asset.partsUsedList.findIndex(
                  (part) => part.id === data.id
                );
                if (index !== -1) {
                  return {
                    ...asset,
                    partsUsedList: [
                      ...asset.partsUsedList.slice(0, index),
                      newPartsData,
                      ...asset.partsUsedList.slice(index + 1),
                    ],
                  };
                }
              }
              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));

          onHide();
        } else {
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Used Parts</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Parts used in the work
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            {/* <div className="col-md-12">
              <label className="fw-medium pb-2">Part Location</label>
              <Dropdown
                className="select__form"
                onSelect={handlePartLocationSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPartLocation !== "Select location of part"
                      ? "selected"
                      : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPartLocation !== "Select location of part"
                    ? selectedPartLocation
                    : data.locationName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partLocationSearch}
                      onChange={(e) => setPartLocationSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartLocationData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.locationName}>
                        {item.locationName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <div className="col-md-12">
              <label className="fw-medium pb-2">Select Part</label>
              <Dropdown className="select__form" onSelect={handlePartSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPart !== "Select Part" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                  disabled={true}
                >
                  {selectedPart !== "Select Part"
                    ? selectedPart
                    : data.partName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partSearch}
                      onChange={(e) => setPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.partName}>
                        {item.partName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Quantity</label>
              <input
                defaultValue={data.quantityUsed}
                className="modal-input-box"
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                type="number"
                min={1}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity required"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUsedPartsModal;
