import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { useDispatch } from "react-redux";
import { addProjectedPart } from "../../../redux/formSlice";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_PROJECTED_SIMPLE_PARTS_WITH_CATEGORY,
  ADD_SIMPLE_PART_WITH_CATEGORY,
  ADD_TICKET_ASSET_RETURNED_SIMPLE_PARTS_WITH_CATEGORY_ID,
  ADD_TICKET_ASSET_USED_SIMPLE_PARTS_WITH_CATEGORY_ID,
  GET_PART_BY_CATEGORY,
} from "../../../utls/constants";
import AddQuickCategoryModal from "./AddQuickCategoryModal";
import { toast } from "react-toastify";
import {
  DiagnosisDetailsContext,
  WorkOrderContext,
} from "../../../Context/Inspections/WorkOrderContext";

const AddQuickPartModal = ({
  onHide,
  show,
  partData,
  partCategories,
  defaultValues,
  selectedParts,
  action,
  ticketAssetId,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (action === "addticketusedparts") {
      setHeader("Add Used Parts");
    } else if (action === "addticketreturnedparts") {
      setHeader("Add Returned Parts");
    }
    setPartName("");
    setSelectedCategory("Select Category");
    setQuantity("");
    setQuantityError("");
    setPartError("");
    setCategorySearch("");
    setQuantityErrorNew("");
  }, [show]);

  const [selectedPart, setSelectedPart] = useState("Select Part");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [quantity, setQuantity] = useState("");
  const [partName, setPartName] = useState("");
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [header, setHeader] = useState("Add Parts");
  const [isLoading, setIsLoading] = useState(false);

  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );

  // Part category search input
  const [partSearch, setPartSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [parts, setParts] = useState(partData);
  const [partId, setPartId] = useState(0);

  const [partError, setPartError] = useState("");
  const [quantityErrorNew, setQuantityErrorNew] = useState("");

  const [quantityError, setQuantityError] = useState("* quantity is required");

  // Filter part data
  // remove parts that are already selected

  const uniqueFilteredPartData = parts?.filter(
    (obj) => !selectedParts?.some((obj2) => obj.partName === obj2.partName)
  );

  const filteredPartData =
    uniqueFilteredPartData &&
    uniqueFilteredPartData?.filter((item) => {
      const uniqueData = !selectedParts?.some(
        (part) => part.partId === item.id
      );

      return item.name?.toLowerCase().includes(partSearch.toLowerCase());
    });

  const filteredCategoryData = partCategories?.filter((item) => {
    return item?.name.toLowerCase().includes(categorySearch.toLowerCase());
  });

  const handlePartSelect = (eventKey) => {
    setSelectedPart(eventKey);
    setPartSearch("");
  };
  const handleCategorySelect = (eventKey) => {
    const selectedCategoryId = partCategories.find(
      (item) => item.name === eventKey
    )?.id;

    setSelectedCategory(eventKey);
    setCategorySearch("");
    get(
      GET_PART_BY_CATEGORY(selectedCategoryId),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setParts(response);
    });
  };
  const success = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const error = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleAddPart = () => {
    setQuantityErrorNew("");

    setIsLoading(true);

    if (!partName) {
      setIsLoading(false);
      setPartError("* part is required");
      return;
    }

    if (!quantity) {
      setIsLoading(false);
      setQuantityErrorNew("* quantity is required");
      return;
    }

    const part = partData?.find((item) => item.name === selectedPart);
    const categoryId = partCategories.find(
      (item) => item.name === selectedCategory
    )?.id;
    let data = {};
    if (action === "addwo") {
      data = {
        partCategoryId: categoryId,
        partName: partName,
      };
      post(
        ADD_SIMPLE_PART_WITH_CATEGORY,
        data,
        localStorage.getItem("bearerToken")
      ).then((response) => {
        if (response.succeeded) {
          setIsLoading(false);
          const partObject = {
            partId: response.data,
            partName: partName,
            quantity: Number(quantity),
          };

          success(response.messages[0]);
          setPartName("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          dispatch(addProjectedPart(partObject));
          onHide();
        } else {
          setIsLoading(false);
          error(response.messages[0]);
          setPartName("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          onHide();
        }
      });
    } else if (action === "addticketusedparts") {
      const data = {
        ticketAssetId: ticketAssetId,
        partCategoryId:categoryId,
        partName: partName,
        quantityUsed: quantity,
      };
      post(
        ADD_TICKET_ASSET_USED_SIMPLE_PARTS_WITH_CATEGORY_ID,
        data,
        localStorage.getItem("bearerToken")
      ).then((response) => {
        if (response.succeeded) {
          setIsLoading(false);
          setPartId(response.data);

          const newPartsData = {
            id: response.data,
            partName: partName,
            quantityUsed: quantity,
            // partSerialNumber: selectedPartNumber,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList?.map((asset) => {
              if (asset.partsUsedList) {
                return {
                  ...asset,
                  partsUsedList: [...asset.partsUsedList, newPartsData],
                };
              }
              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));
          success(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        } else {
          setIsLoading(false);
          error(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        }
      });
    } else if (action === "addticketreturnedparts") {
      const data = {
        ticketAssetId: ticketAssetId,
        partCategoryId: categoryId,
        partName: partName,
        quantityReturned: quantity,
      };
      post(
        ADD_TICKET_ASSET_RETURNED_SIMPLE_PARTS_WITH_CATEGORY_ID,
        data,
        localStorage.getItem("bearerToken")
      ).then((response) => {
        console.log(response);
        if (response.succeeded) {
          setIsLoading(false);
          setPartId(response.data);

          const newPartsData = {
            id: response.data,
            partName: partName,
            quantityReturned: quantity,

            // partSerialNumber: selectedPartNumber,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList.map((asset) => {
              if (asset.partsReturnedList) {
                return {
                  ...asset,
                  partsReturnedList: [...asset.partsReturnedList, newPartsData],
                };
              }
              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));
          success(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        } else {
          setIsLoading(false);
          error(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        }
      });
    } else if (action === "adddiagnosisprojectedparts") {
      const payload = {
        requestAssetId: diagnosisSummary?.requestDetailSummary?.requestAssetId,
        partCategoryId: categoryId,
        partName: partName,
        quantityProjected: quantity,
      };

      post(
        ADD_PROJECTED_SIMPLE_PARTS_WITH_CATEGORY,
        payload,
        localStorage.getItem("bearerToken")
      )
        .then((response) => {
          if (response.succeeded === true) {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            const newPartsData = {
              id: 1,
              partId: partId,
              partName: partName,
              quantityProjected: quantity,
              requestAssetId:
                diagnosisSummary?.requestDetailSummary?.requestAssetId,
              //partSerialNumber: selectedPartNumber,
            };
            setDiagnosisSummary((prevDiagnosisSummary) => ({
              ...prevDiagnosisSummary,
              requestPartsSummary: [
                ...prevDiagnosisSummary.requestPartsSummary,
                newPartsData,
              ],
            }));
          } else {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((error) => {
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          toast.error("An error occured", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        })
        .finally(() => {
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
        });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">{header}</span>
              <span className="fs-14" style={{ color: "#72777A" }}>
                Select parts and quantities required.
              </span>
            </div>
            <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
              <div className="col-md-12">
                {/* <div className="d-flex align-items-center gap-4">
                  <span className="fw-medium pb-2 fs-14">
                    Select Part Category
                  </span>
                  <span
                    className="fs-14 pb-2 "
                    style={{
                      color: "#D57D2A",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setCategoryName(e.target.value);
                      onHide();
                      setCategoryModalShow(true);
                    }}
                  >
                    Add News
                  </span>
                </div> */}
                <div className="d-flex align-items-center gap-4 flexNew">
                  <span className="fw-medium pb-2 fs-14">
                    Select Part Category
                  </span>
                  <span
                    className="fs-14 pb-2 "
                    style={{
                      color: "#D57D2A",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setCategoryName(e.target.value);
                      onHide();
                      setCategoryModalShow(true);
                    }}
                  >
                    Add New
                  </span>
                </div>
                <Dropdown
                  className="select__form"
                  onSelect={(eventKey) => handleCategorySelect(eventKey)}
                >
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectedCategory !== "Select Category" ? "selected" : ""
                    }`}
                    style={{ height: "50px" }}
                  >
                    {selectedCategory}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={categorySearch}
                        onChange={(e) => setCategorySearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filteredCategoryData &&
                        filteredCategoryData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label className="fw-medium pb-2 fs-14">Select Part</label>
                  <div
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {partError}
                  </div>
                </div>

                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  placeholder="Enter part name"
                  value={partName}
                  onChange={(e) => setPartName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label className="fw-medium pb-2 fs-14">Quantity</label>
                  <div
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {quantityErrorNew}
                  </div>
                </div>

                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  placeholder="Enter quantity required"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              {isLoading ? (
                <Spinner
                  animation="border"
                  style={{
                    color: "#D57D2A",
                  }}
                />
              ) : (
                <button className="delate-btn" onClick={handleAddPart}>
                  Add
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddQuickCategoryModal
        onHide={() => setCategoryModalShow(false)}
        show={categoryModalShow}
        partCategories={partCategories}
      />
    </>
  );
};

export default AddQuickPartModal;
