import React, { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import ViewRequestSide from "../Components/Requests/ViewRequests/ViewWorkSide";
import ViewRequestHeader from "../Components/Requests/ViewRequests/ViewHeader";
import { get } from "../Services/ApiHelper";
import {
  GET_REQUEST_DETAILS_BY_ID,
  GET_REQUEST_SUMMARY_BY_ID,
} from "../utls/constants";
import { RequestDetailsContext } from "../Context/Inspections/WorkOrderContext";
import { log } from "util";
import { useDispatch } from "react-redux";
import {
  resetForm,
  setAssetCategory,
  setAssetListId,
  setFiles,
  setLocation,
  setRequestId,
  setResponseType,
  setSelectedAssets,
  setTicketDescription,
  setTicketPriority,
  setTicketTitle,
  setRequestReference,
} from "../redux/formSlice";
import { fileToBase64, urlToBase64 } from "../utls/convertToBase64";

const ViewRequests = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState();
  const [selectValue, setSelectValue] = useState({
    asset: null,
    location: null,
    assetCategory: null,
    assignAdditionalTeamId: 0,
    assignAdditionalTeam: "Select",
    requestTitle: "",
    requestDescription: "",
    workOrderRef: "",
    projectedParts: [],
    file: [],
  });

  useEffect(() => {
    get(GET_REQUEST_SUMMARY_BY_ID(id), localStorage.getItem("bearerToken"))
      .then((response) => {
        setRequest(response);
      })
      .catch((error) => {
        console.log("An Error Ocurred");
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    // Initialize the arrays
    const arrayNames = [];
    const arrayIds = [];

    // sla responseType
    const company = JSON.parse(localStorage.getItem("company"));
    const responseType = company?.slaPredefinedOrManual;

    // Check if the request object is present
    if (request) {
      // Push the values into the respective arrays
      if (request?.requestSummaryDetailDto?.asset?.name) {
        arrayNames.push(request.requestSummaryDetailDto.asset.name);
      }
      if (request?.requestSummaryDetailDto?.asset?.id) {
        arrayIds.push(request.requestSummaryDetailDto.asset.id);
      }

      let partList = [];
      let requestfiles = [];

      request?.requestProjectedPartList?.forEach((part) => {
        partList.push({
          id: part.id,
          partId: part.partId,
          part: part.partName,
          quantity: part.quantityProjected,
        });
      });

      // request?.requestSummaryDetailDto?.requestFiles?.forEach((file) => {
      //   requestfiles.push({
      //     fileName: file.fileName,
      //     url: file.url,
      //     fileType: file.fileType,
      //     encodedFile: file.encodedFile,
      //   });
      // });

      // setSelectValue({
      //   asset: request?.requestSummaryDetailDto?.asset,
      //   location: request?.requestSummaryDetailDto?.location,
      //   assetCategory: request?.requestSummaryDetailDto?.assetCategory,
      //   requestDescription: request?.requestSummaryDetailDto?.faultDescription,
      //   workOrderRef: request?.requestSummaryDetailDto?.workOrderRef,
      //   projectedParts: partList,
      //   file: requestfiles,
      // });

      const processFiles = async () => {
        const processedFiles = await Promise.all(
          request?.requestSummaryDetailDto?.requestFiles?.map(async (file) => {
            const fileType = file.fileName.split(".").pop(); // Get file extension
            const encodedFile = await urlToBase64(file.url); // Convert URL to Base64
            return {
              ...file,
              encodedFile,
              fileType,
            };
          })
        );

        setSelectValue({
          asset: request?.requestSummaryDetailDto?.asset,
          location: request?.requestSummaryDetailDto?.location,
          assetCategory: request?.requestSummaryDetailDto?.assetCategory,
          requestDescription:
            request?.requestSummaryDetailDto?.faultDescription,
          workOrderRef: request?.requestSummaryDetailDto?.workOrderRef,
          projectedParts: partList,
          file: processedFiles,
        });

        // Dispatch processed files with additional fields
        dispatch(setFiles(processedFiles));
        localStorage.setItem("selectedFiles", JSON.stringify(processedFiles));
      };

      processFiles();

      // Ensure arrayNames and arrayIds are arrays before dispatching actions
      if (Array.isArray(arrayNames) && Array.isArray(arrayIds)) {
        dispatch(setSelectedAssets(arrayNames));
        dispatch(setAssetListId(arrayIds));
      }
      const priority = {
        priority: request?.requestSummaryDetailDto?.ticketPriority?.name,
        priorityId: request?.requestSummaryDetailDto?.ticketPriority?.id,
      };

      dispatch(
        setTicketTitle(
          request?.requestSummaryDetailDto?.requestFaults
            .map((fault) => fault.name)
            .join(", ")
        )
      );
      dispatch(
        setRequestReference(request?.requestSummaryDetailDto?.requestRef)
      );
      dispatch(setTicketPriority(priority));
      dispatch(setRequestId(request?.requestSummaryDetailDto?.id));
      dispatch(
        setTicketDescription(request?.requestSummaryDetailDto?.faultDescription)
      );
      dispatch(setLocation(request?.requestSummaryDetailDto?.location));
      dispatch(
        setAssetCategory(request?.requestSummaryDetailDto?.assetCategory)
      );
      dispatch(setFiles(request?.requestSummaryDetailDto?.requestFiles));
      dispatch(setResponseType(responseType));
      localStorage.setItem("selectedFiles", JSON.stringify(requestfiles));
      localStorage.removeItem("wOClick");
      localStorage.setItem("wOClick", JSON.stringify(selectValue));
    }
  }, [request, dispatch]);

  const handleRequestClick = () => {
    localStorage.removeItem("wOClick");
    localStorage.setItem("wOClick", JSON.stringify(selectValue));
  };

  const createWorkOrder = async () => {
    await dispatch(resetForm());
    console.log("Requeseeet", request);
    dispatch(resetForm());
    dispatch(setRequestId(request?.requestSummaryDetailDto?.id));
    dispatch(
      setTicketDescription(request?.requestSummaryDetailDto?.faultDescription)
    );
    dispatch(setLocation(request?.requestSummaryDetailDto?.location));
    dispatch(setAssetCategory(request?.requestSummaryDetailDto?.assetCategory));
    dispatch(setSelectedAssets(request?.requestSummaryDetailDto?.asset));
  };
  

  return (
    <div className="work-orders-area f3washroom-area">
      <RequestDetailsContext.Provider value={{ request, setRequest }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ViewRequestHeader
                handleRequestClick={handleRequestClick}
                createWorkOrder={createWorkOrder}
              />
            </div>
            <div className="col-lg-12">
              <div className="other-nav">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/my-work-new-requests">Requests</Link>
                  </li>

                  <li>
                    <Link>{request?.requestSummaryDetailDto?.requestRef}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="order-request-area view-order-content">
                <ViewRequestSide />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </RequestDetailsContext.Provider>
    </div>
  );
};

export default ViewRequests;
