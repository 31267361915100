"use client";

import { useState, useEffect } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DownIcon from "../../Assets/Icons/DownIcon";
import DashboardInfoModal from "../WorkOrders/Modal/DashboardInfoModal";
import usefetchData from "../../hooks/useFetchData";
import { IoTriangleSharp } from "react-icons/io5";
import qs from "qs";
import { monthlyPerformancedata } from "../../InfoData";

const dropdownButtonStyle = {
  border: "none",
  boxShadow: "0px 2px 10px 0px #00000026",
  transition: "box-shadow 0.3s ease",
  // change direction of arrow
};

const dropdownButtonHoverStyle = {
  ...dropdownButtonStyle,
  boxShadow: "0px 4px 15px 0px #00000040",
};

const style = {
  background: "#EE9C39",
  height: "10px",
  width: "10px",
  borderRadius: "50%",
};

const toolTipDiv = {
  borderRadius: "0.375rem",
  position: "relative",
};

const tooltipTop = {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  margin: "0",
  // marginBottom: "0.5rem"
};

const mPerformance = {
  color: "#B0B3BE",
  fontSize: "10px",
};

const toolTipBottom = {
  fontSize: "12px",
};
const toolTipBottomSpan = {
  fontSize: "20px",
  fontWeight: "bold",
};

const tooltipIcon = {
  position: "absolute",
  top: "35%",
  left: "-8%",
  transform: "rotate(-90deg)",
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg bg-white p-4 shadow-lg" style={toolTipDiv}>
        <div style={tooltipIcon}>
          <IoTriangleSharp color="white" size={17} />
        </div>
        <div style={tooltipTop}>
          <div style={style}></div>
          <p className="text-sm text-gray-500 m-0" style={mPerformance}>
            Monthly Performance
          </p>
        </div>
        <p className="text-sm font-semibold" style={toolTipBottom}>
          Closed on time{" "}
          <span style={toolTipBottomSpan}>
            {(payload[0].value % 1).toFixed(2) !== "0.01"
              ? payload[0].value
              : Math.round(payload[0].value) * 10}
            %
          </span>
        </p>
      </div>
    );
  }
  return null;
};

const CustomBar = (props) => {
  const { x, y, width, height, value, fill } = props;
  const radius = 8;

  const fullHeight = height / (value / 100 || 1); // Avoid division by zero
  return (
    <g>
      {/* Gray background (always full height) */}
      <rect
        x={x}
        y={y - (fullHeight - height)}
        width={width}
        height={fullHeight}
        fill={fullHeight === 0 ? "#F3F4F6" : fill || "#F3F4F6"} // If height is 0, show gray
        rx={radius}
        ry={radius}
      />
      {/* Foreground bar */}
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fullHeight === 0 ? "#F3F4F6" : fill || "#EE9C39"} // If height is 0, show gray
        rx={radius}
        ry={radius}
      />
    </g>
  );
};

export default function MonthlyPerformance() {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredLocations, setFilteredLocations] = useState(locations);
  // const [modalOpen, setModalOpen] = useState(false);/
  // const [dateRange, setDateRange] = useState({
  //   startDate: null,
  //   endDate: null,
  // });
  const [filters, setFilters] = useState({
    "filter.LocationIds": [],
  });

  const [modalOpen, setModalOpen] = useState(false);

  const { data: locations } = usefetchData(
    ["partLocation"],
    `/Locations/LocationsList`,
    {},
    "Couldn't get part locations data. Please try again!",
    true
  );

  const { data: monthlyPerformance } = usefetchData(
    ["monthly-performance", filters],
    `/Dashboard/GetMonthlyPerformance`,
    {
      params: filters,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get work count data",
    !!filters
  );

  const chartData = monthlyPerformance?.map((item) => {
    if (item.performanceCountPercentage === 0) {
      item.performanceCountPercentage = 0.01;
    }

    return item;
  });

  // console.log("date range", monthlyPerformance);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [filteredLocations, setFilteredLocations] = useState(locations);

  useEffect(() => {
    const filtered =
      locations?.filter((location) =>
        location.locationName.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [];
    setFilteredLocations(filtered);
  }, [searchTerm, locations]);

  const handleLocationClick = (locationId) => {
    setSelectedLocations((prev) => {
      const newSelectedLocations = prev.includes(locationId)
        ? prev.filter((id) => id !== locationId)
        : [...prev, locationId];

      setFilters((prevFilters) => ({
        ...prevFilters,
        "filter.LocationIds": newSelectedLocations,
      }));

      return newSelectedLocations;
    });
  };

  const handleLocationClickAll = () => {
    setSelectedLocations(() => {
      const newSelectedLocations = []; // Clear all selected locations
  
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters["filter.LocationIds"]; // Remove the key entirely
        return updatedFilters;
      });
  
      return newSelectedLocations;
    });
  };

  const isSelected = (locationId) => selectedLocations.includes(locationId);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const ascendingOrder = [
    ...new Set(
      monthlyPerformance?.map((item) => item.performanceCountPercentage)
    ),
  ].sort((a, b) => a - b);

  const lowest = Math.min(...ascendingOrder);
  const highest = Math.max(...ascendingOrder);
  const listWithLowestAndHighest = [lowest, highest];

  return (
    <>
      <div
        className="w-full space-y-4 rounded-lg bg-white p-[40px] border-[8px]"
        style={{ padding: "20px 40px" }}
      >
        <style jsx>{`
          .dropdown-item:hover {
            background-color: transparent !important;
          }
          .btn-outline-secondary {
            border: none;
          }
        `}</style>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ paddingBottom: "20px" }}
        >
          <div>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <h2 className="fs-4 fw-bold mb-0">Monthly Performance</h2>
              <p className="text-muted small mb-0">Work closed on time (%)</p>
              {/* <InfoIcon style={{ color: '#EE9C39', fontSize: '1.25rem' }} /> */}
              <div onClick={toggleModal} className="cursor-pointer">
                <img src="/info.svg" alt="" className="w-[24px] h-[24px]" />
              </div>
            </div>
          </div>
          <div className="dropdown select-dropdown">
            <button
              className="select-title"
              type="button"
              data-bs-toggle="dropdown"
              style={
                hoveredButton === "location"
                  ? dropdownButtonHoverStyle
                  : dropdownButtonStyle
              }
              onMouseEnter={() => setHoveredButton("location")}
              onMouseLeave={() => setHoveredButton(null)}
            >
              <span className="fs-12">Locations:</span>
              <span
                className="fs-12 d-flex align-items-center gap-1"
                style={{ color: "#00000061" }}
              >
                {selectedLocations.length === 0
                  ? "All"
                  : `${selectedLocations.length} selected`}{" "}
                <DownIcon />
              </span>
            </button>
            <ul
              className="dropdown-menu select-menu"
              style={{ width: "100%", padding: "10px" }}
            >
              <div
                className="dropdown-search"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "#c5c7cd",
                  position: "relative",
                  borderRadius: "5px",
                  border: "0.5px solid #f1efef",
                  background: "#fff",
                  marginBottom: "10px",
                  padding: "5px",
                  paddingLeft: "15px",
                }}
              >
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  style={{ border: "none", outline: "none", width: "100%" }}
                  className="dropDownSearch"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="dropdown-item-content">
                <li onClick={handleLocationClickAll}>
                  <b>All</b>
                </li>
                <hr />
                {filteredLocations?.map((location, i) => (
                  <li
                    style={{
                      padding: "4px 0",
                      fontSize: "14px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={location.id}
                    onClick={() => handleLocationClick(location.id)}
                  >
                    {isSelected(location.id) ? (
                      <CheckBoxIcon
                        style={{ color: "#D57D2A", marginRight: "4px" }}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        style={{
                          color: "#DFE0EB",
                          marginRight: "4px",
                          border: "1px",
                        }}
                      />
                    )}
                    {location.locationName}
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>

        <div style={{ height: "300px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData?.reverse()}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            >
              <CartesianGrid
                vertical={false}
                horizontal={true}
                strokeDasharray="3 3"
                stroke="#F3F4F6"
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#6B7280", fontSize: 12 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#6B7280", fontSize: 12 }}
                domain={[0, 100]}
                ticks={[0, 20, 40, 60, 80, 100]}
              />
              <Tooltip content={<CustomTooltip />} cursor={false} />
              <Bar
                dataKey="performanceCountPercentage"
                shape={<CustomBar />}
                barSize={15}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <DashboardInfoModal
        show={modalOpen}
        toggleModal={toggleModal}
        infoData={monthlyPerformancedata}
      />
    </>
  );
}
