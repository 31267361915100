import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import ViewWorkSide from "../Components/WorkOrders/ViewWorkOrder/ViewWorkSide";
import ViewHeader from "../Components/WorkOrders/ViewWorkOrder/ViewHeader";
import { useParams } from "react-router-dom";
import { WorkOrderContext } from "../Context/Inspections/WorkOrderContext";
import { GET_WORKORDER_DETAILS_BY_ID } from "../utls/constants";
import { get } from "../Services/ApiHelper";

const TicketSummaryDto = () => ({
  workOrderSummary: {},
  assetSummary: {},
  costingSummary: {},
  jobCardSummary: [],
});

const ViewWorkOrder = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ticketSummary, setTicketSummary] = useState(TicketSummaryDto());
  const [locationCategoryStatus, setLocationCategoryStatus] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    get(GET_WORKORDER_DETAILS_BY_ID(id), localStorage.getItem("bearerToken"))
      .then((data) => {
        // Populate the TicketSummary ObjectsetIsLoading
        setIsLoading(false);
        setTicketSummary({
          workOrderSummary: data.workOrderSummary,
          assetSummary: data.assetSummary,
          costingSummary: data.costingSummary,
          jobCardSummary: data.jobCardSummary,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div className="work-orders-area f3washroom-area">
      <WorkOrderContext.Provider
        value={{
          ticketSummary,
          setTicketSummary,
          locationCategoryStatus,
          setLocationCategoryStatus,
          isLoading,
          setIsLoading,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ViewHeader />
            </div>
            <div className="col-lg-12">
              <div className="other-nav">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/my-work/work-orders">CM Work</Link>
                  </li>
                  <li>
                    <Link> View Work</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="order-request-area view-order-content">
                <ViewWorkSide />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </WorkOrderContext.Provider>
    </div>
  );
};

export default ViewWorkOrder;
