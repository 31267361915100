import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import AddIcon from "../../Assets/Icons/AddIcon";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import exportToExcelMain from "../../utls/exportToExecelMain";
const RequestsHeader = (report) => {

  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };
  
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Requests</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
          {claims("Can_Add_Request") && (
            <li>
              <Link to="/requests/new-request">
                <AddIcon /> New Request
              </Link>
            </li>
          )}
            <li onClick={() => exportToExcelMain(report.report, "Requests")}>
              <Link to="">
                <AddIcon />
                Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};



export default RequestsHeader;
