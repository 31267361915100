import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoadingIcon from "../../../Assets/Icons/LoadingIcon";
import { ProgressBar, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import CloseIcon from "../../../Assets/Icons/CloseIcon";
import CompletedIcon from "../../../Assets/Icons/CompletedIcon";
import CloudAddIcon from "../../../Assets/Icons/CloudAddIcon";
import { FileUploader } from "react-drag-drop-files";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_TICKET_FILES,
  GET_WORKORDER_DETAILS_BY_ID,
} from "../../../utls/constants";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AddFileModal = (props) => {
  const fileTypes = ["PDF", "JPEG", "JPG", "PNG", "3GP", "AVI", "MP4"];
  const fileTypesNotPDF = ["JPEG", "JPG", "PNG"];
  const [files, setFiles] = useState([]);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const ticketId = ticketSummary?.workOrderSummary?.ticketId;
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (newFiles) => {
    const filesToAdd = Array.isArray(newFiles) ? newFiles : [...newFiles];

    const filteredFiles = filesToAdd.filter((newFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === newFile.name &&
          existingFile.size === newFile.size
      );
    });

    for (const file of filteredFiles) {
      const fileType = file.name.split(".").pop().toUpperCase();

      if (!props.notPDF) {
        if (!fileTypes.includes(fileType)) {
          toast.error("Invalid file type");
          return;
        }
      }

      if (props.notPDF) {
        if (!fileTypesNotPDF.includes(fileType)) {
          toast.error("Invalid file type");
          return;
        }
      }
    }

    if (filteredFiles.some((file) => file.size > 50 * 1024 * 1024)) {
      toast.error("File size should not exceed 50MB");
      return;
    }

    const filesWithBase64 = await Promise.all(
      filteredFiles.map(async (file) => ({
        ...file,
        status: "Uploading...",
        uploading: 50,
        base64: (await readFileAsBase64(file)).split(",")[1], // Splitting and taking the second part
        fileType: file.name.split(".").pop().toUpperCase(),
        originalName: file.name,
        url: "",
      }))
    );
    filesWithBase64.forEach((file) => {
      file.size = file.base64.length;
    });

    setFiles((prevFiles) => [...prevFiles, ...filesWithBase64]);
  };

  const token = localStorage.getItem("bearerToken");
  useEffect(() => {
    const timer = setTimeout(() => {
      const newFiles = files.map((file) => {
        if (file.status === "Uploading...") {
          file.status = "Completed";
          file.uploading = 100;
        }
        return file;
      });
      setFiles(newFiles);
    }, 2000);
    return () => clearTimeout(timer);
  }, [files]);

  const onSubmit = () => {
    setIsLoading(true);
    for (const file of files) {
      const payload = {
        ticketId: ticketId,
        file: {
          encodedFile: file.base64,
          fileName: file.originalName,
          url: file.url,
          fileType: file.fileType,
        },
      };

      post(ADD_TICKET_FILES, payload, token)
        .then((response) => {
          if (response.succeeded) {
            setIsLoading(false);
            toast.success("File added successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setFiles([]);
            setTicketSummary((prevTicketSummary) => {
              const updatedTicketSummary = { ...prevTicketSummary };
              updatedTicketSummary.workOrderSummary.ticketFileList.push({
                id: response.data,
                fileName: file.originalName,
                url: file.url,
                ticketId: ticketId,
                createdDate: Date.now(),
              });
              return updatedTicketSummary;
            });
            get(GET_WORKORDER_DETAILS_BY_ID(ticketId), token)
              .then((data) => {
                setTicketSummary({
                  id: data.id,
                  workOrderSummary: data.workOrderSummary,
                  assetSummary: data.assetSummary,
                  costingSummary: data.costingSummary,
                  jobCardSummary: data.jobCardSummary,
                });
                props.onHide();
              })
              .catch((err) => {
                console.log(err);
              });
            props.onHide();
          } else {
            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setFiles([]);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleDelete = (file) => {
    const newFiles = files.filter((f) => f.originalName !== file.originalName);
    setFiles(newFiles);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const newFiles = files.map((file) => {
        if (file.status === "Uploading...") {
          file.status = "Completed";
          file.uploading = 100;
        }
        return file;
      });
      // add size to the file
      newFiles.forEach((file) => {
        file.size = file.base64.length;
      });
      setFiles(newFiles);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    setFiles([]);
  }, [props.show]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Add File</span>
            <span className="fs-14 ps-3">
              Select a file to add to the work order.
            </span>
          </div>
          <div className="browse-file-upload mt-4">
            <FileUploader
              hoverTitle=" "
              children={
                <div>
                  <CloudAddIcon />
                  <div
                    className="fs-16 fw-medium pt-3 pb-2"
                    style={{ color: "#292D32" }}
                  >
                    Choose a file or drag & drop it here
                  </div>
                  <div className="fs-16 fw-medium" style={{ color: "#A9ACB4" }}>
                    PDF, JPEG, PNG, 3GP, AVI, and MP4 formats, up to 50MB
                  </div>
                  <input
                    fileOrFiles="files"
                    type="file"
                    accept=".pdf,.jpeg,.jpg,.png,.3gp,.avi,.mp4"
                    /* onChange={handleFileChange} */
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    className="browse-file cursor-pointer"
                  >
                    Browse File
                  </label>
                </div>
              }
              classes="cursor-pointer"
              maxFileSize={50}
              multiple={true}
              handleChange={handleChange}
              name="file"
              types={ props.notPDF ? fileTypesNotPDF : fileTypes}
              onTypeError={(error) => toast.error(error)}
              onSizeError={(error) => toast.error(error)}
            />
          </div>
          <div className="d-grid gap-3 mt-3">
            {files.map((f) =>
              f.status === "Uploading..." ? (
                <div className="uploading-content">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div
                        className="fs-16 fw-medium"
                        style={{ color: "#292D32" }}
                      >
                        {f.originalName}
                      </div>
                      <div
                        className="fs-13 pt-1 pb-2"
                        style={{ color: "#A9ACB4" }}
                      >
                        {parseInt(f.size / 1024) * (f.uploading / 100)} KB of{" "}
                        {parseInt(f.size / 1024)} KB •
                        <span style={{ color: "#292D32", paddingLeft: "10px" }}>
                          <LoadingIcon /> Uploading...
                        </span>
                      </div>
                    </div>
                    <button>
                      <CloseIcon />
                    </button>
                  </div>
                  <ProgressBar variant="warning" now={f.uploading} />
                </div>
              ) : (
                <div>
                  <div className="uploading-content d-flex align-items-center justify-content-between">
                    <div>
                      <div
                        className="fs-16 fw-medium"
                        style={{ color: "#292D32" }}
                      >
                        {f.originalName}
                      </div>
                      <div
                        className="fs-13 pt-1 pb-2"
                        style={{ color: "#A9ACB4" }}
                      >
                        {parseInt(f.size / 1024)} KB •
                        {f.status === "Completed" && (
                          <span
                            style={{ color: "#292D32", paddingLeft: "10px" }}
                          >
                            <CompletedIcon /> {f.status}
                          </span>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => handleDelete(f)}
                      style={{ color: "#6C5B51" }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button
                className="delate-btn"
                onClick={onSubmit}
                disabled={files.length === 0} // Disable when no files are present
              >
                Add
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddFileModal;
