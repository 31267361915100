import React, { useEffect, useState } from "react";
import "../Components/WorkOrders/WorkOrders.scss";
import "react-datepicker/dist/react-datepicker.css";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import WorkHeader from "../Components/WorkOrders/WorkHeader";
import { get } from "../Services/ApiHelper";
import { FaRegCheckSquare } from "react-icons/fa";
import { FaHandHoldingWater } from "react-icons/fa";
import { TbSettingsCheck } from "react-icons/tb";
import { TfiTimer } from "react-icons/tfi";
import { TbTimeline } from "react-icons/tb";
import NewRequests from "../Components/Requests/TabNewRequests";
import {
  GET_ALL_TICKET_COUNT,
  GET_ALL_TICKET_COUNT_APPROVED,
  GET_ALL_TICKET_COUNT_DECLINED,
  GET_ALL_TICKET_COUNT_PENDING,
  GET_ALL_TICKET_COUNT_REQUESTS,
  GET_CLOSED_WORKORDERS,
  GET_NEW_WORKORDERS,
  GET_ONGOING_WORKORDERS,
  GET_PENDING_WORKORDERS,
  GET_REJECTED_WORKORDERS,
  GET_REQUESTS,
} from "../utls/constants";
import RequestsHeader from "../Components/Requests/RequestsHeader";
import RequestsNavbar from "../Components/Requests/RequestsNavbar";
import { Nav, Tab } from "react-bootstrap";
import { RequestService } from "../Services/ApiServiceCalls/RequestService";
import TabApprovedRequests from "../Components/Requests/TabApprovedRequests";
import TabDeclinedRequests from "../Components/Requests/TabDeclinedRequests";
import PreventWorkHeader from "../Components/PreventiveWorks/PreventWorksHeader";
import { useDispatch, useSelector } from "react-redux";
import { setTabChangeState } from "../redux/woCostSlice";

const MyWork = () => {
  const [report, setReport] = useState([]);
  const [action, setAction] = useState([]);
  const [count, setCount] = useState(0);
  const [newS, setNewS] = useState(true);
  const [pending, setPending] = useState(false);
  const [ongoing, setOngoing] = useState(false);
  const [ongoingPM, setOngoingPM] = useState(false);
  const [ongoingCM, setOngoingCM] = useState(false);
  const [closed, setClosed] = useState(false);
  const [reject, setReject] = useState(false);
  const [resetCount, setResetCount] = useState(false);
  const [resetCountNew, setResetCountNew] = useState(false);
  const ticketType = "corrective";
  const ticketTypePending = "pending";
  const ticketTypeApproved = "approved";
  const ticketTypeDeclined = "declined";
  const [ticketTypePendingCount, setTicketTypePendingCount] = useState(0);
  const [ticketTypeApprovedCount, setTicketTypeApprovedCount] = useState(0);
  const [ticketTypeDeclinedCount, setTicketTypeDeclinedCount] = useState(0);
  const dispatch = useDispatch();

  const bearerToken = localStorage.getItem("bearerToken");
  useEffect(() => {
    handleNewWorkOrderRepotrt();
    countResetRequestsPending();
    countResetRequestsApproved();
    countResetRequestsDeclined();
  }, []);

  const countReset = () => {
    get(GET_ALL_TICKET_COUNT(ticketType), localStorage.getItem("bearerToken"))
      .then((response) => {
        setCount(response);
      })
      .catch((error) => {
        console.log("An Error Ocurred");
      });
  };

  const countResetRequestsPending = () => {
    get(
      GET_ALL_TICKET_COUNT_PENDING(ticketTypePending),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        setTicketTypePendingCount(response);
      })
      .catch((error) => {
        console.log("An Error Ocurred");
      });
  };
  const countResetRequestsApproved = () => {
    get(
      GET_ALL_TICKET_COUNT_APPROVED(ticketTypeApproved),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        setTicketTypeApprovedCount(response);
      })
      .catch((error) => {
        console.log("An Error Ocurred");
      });
  };
  const countResetRequestsDeclined = () => {
    get(
      GET_ALL_TICKET_COUNT_DECLINED(ticketTypeDeclined),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        setTicketTypeDeclinedCount(response);
      })
      .catch((error) => {
        console.log("An Error Ocurred");
      });
  };

  const handleNewWorkOrderRepotrt = () => {
    setNewS(true);
    setPending(false);
    setOngoing(false);
    setOngoingPM(false);
    setClosed(false);
    setReject(false);
    countReset();
    get(GET_NEW_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("New_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };

  const handleRejectedWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(false);
    setOngoing(false);
    setOngoingPM(false);
    setClosed(false);
    setReject(true);
    countReset();
    get(GET_REJECTED_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Rejected_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleOngoingWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(false);
    setOngoing(true);
    setOngoingPM(true);
    setClosed(false);
    setReject(false);
    countReset();
    get(GET_ONGOING_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Ongoing_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handlePendingClosureWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(true);
    setOngoing(false);
    setOngoingPM(false);
    setClosed(false);
    setReject(false);
    countReset();
    get(GET_PENDING_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Pending_Closure_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleClosedWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(false);
    setOngoing(false);
    setOngoingPM(false);
    setClosed(true);
    setReject(false);
    countReset();
    get(GET_CLOSED_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Closed_CM_Work");
        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };

  const handleUpcomingWorkOrderRepotrt = () => {
    setReport([]);
  };
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  const ticketCounts = count
    ? count.reduce((acc, { ticketStatusName, ticketCount }) => {
        acc[ticketStatusName.toLowerCase()] = ticketCount;
        return acc;
      }, {})
    : {};

  // REQUEST AREA

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const navigate = useNavigate();
  const requestService = RequestService();
  const [newRequests, setNewRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [declinedRequests, setDeclinedRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // let [report, setReport] = useState([]);

  const tabChange = useSelector((state) => state.woCost);

  const [tabActive, setTabActive] = useState("new-requests");

  const handleTabChange = (tabName) => {
    setTabActive(tabName);
    navigate(`?tab=${tabName}`);
  };

  const [navColor, setNavColor] = useState(true);

  useEffect(() => {
    newRequestReport();
  }, []);

  const newRequestReport = () => {
    setIsLoading(true);
    get(GET_REQUESTS(1000, 1, "Pending"), localStorage.getItem("bearerToken"))
      .then((response) => {
        setReport(response["data"]);
        setIsLoading(false);
      })
      .catch((error) => console.log("An Error Ocurred"));
  };
  const handleNewReport = () => {
    newRequestReport();
  };
  const handleApprovedReport = () => {
    setIsLoading(true);
    get(GET_REQUESTS(1000, 1, "Approved"), localStorage.getItem("bearerToken"))
      .then((response) => {
        setReport(response["data"]);
        setIsLoading(false);
      })
      .catch((error) => console.log("An Error Ocurred"));
  };

  const handlDeclinedReport = () => {
    setIsLoading(true);
    get(GET_REQUESTS(1000, 1, "Declined"), localStorage.getItem("bearerToken"))
      .then((response) => {
        setReport(response["data"]);
        setIsLoading(false);
      })
      .catch((error) => console.log("An error has occured"));
  };

  // PREVENTIVE WORK


  return (
    <>
      <div className="mainTabUlMAinContainer container">
        <ul
          className="nav nav-tabs mainTabUl col-lg-12"
          id="myTab"
          role="tablist"
        >
          {
            claims("Can_View_MyWork") && (<NavLink
              to="/my-work-new-requests"
              className="nav-item"
              role="presentation"
            >
              <button
                className={`nav-item ${tabChange?.tabChangeState === "" ? "active" : ""}`}
                id="my-work"
                data-bs-toggle="tab"
                data-bs-target="#myWork"
                type="button"
                role="tab"
                aria-controls="myWork"
                aria-selected="true"
                onClick={() => {
                  dispatch(setTabChangeState(""));
               }}
              >
                <FaRegCheckSquare color={`${tabChange?.tabChangeState === "" ? "white" : ""}`} size={20} />
                <span>My Work</span>
              </button>
            </NavLink>)
          }
          
          {
            claims("Can_View_Request") && (
              <li className="nav-item" role="presentation">
            <button
              className={`nav-item ${tabChange?.tabChangeState === "request_work" ? "active" : ""}`}
              id="my-requests"
              data-bs-toggle="tab"
              data-bs-target="#requests"
              type="button"
              role="tab"
              aria-controls="requests"
              aria-selected="false"
              onClick={() => {
                dispatch(setTabChangeState("request_work"));
             }}
            >
              <FaHandHoldingWater color={`${tabChange?.tabChangeState === "request_work" ? "white" : ""}`} size={20} />
              <span>Requests</span>
            </button>
          </li>
            )
          }
          
          <NavLink
            to="/my-work/work-orders"
            className="nav-item"
            role="presentation"
          >
            <button
              className={`nav-link ${tabChange?.tabChangeState === "cm_work" ? "active" : ""} `}
              id="cm-work"
              data-bs-toggle="tab"
              data-bs-target="#CMWork"
              type="button"
              role="tab"
              aria-controls="CMWork"
              aria-selected="false"
              onClick={() => {
                 dispatch(setTabChangeState("cm_work"));
              }}
            >
              <TbSettingsCheck color={`${tabChange?.tabChangeState === "cm_work" ? "white" : ""}`} size={20} />
              <span>Corrective Work (CM)</span>
            </button>
          </NavLink>
          {/* <NavLink
            to="/my-work/preventive-works"
            className="nav-item"
            role="presentation"
          >
            <button
              className={`nav-link ${tabChange?.tabChangeState === "pm_work" ? "active" : ""} `}
              id="pm-work"
              data-bs-toggle="tab"
              data-bs-target="#PMWork"
              type="button"
              role="tab"
              aria-controls="PMWork"
              aria-selected="false"
              onClick={() => {
                handleNewWorkOrderRepotrt();
                 dispatch(setTabChangeState("pm_work"));
              }}
            >
              <TfiTimer size={20} className="TfiTimerIcon" color={`${tabChange?.tabChangeState === "pm_work" ? "white" : ""}`}/>
              <span>Preventive Work (PM)</span>
            </button>
          </NavLink> */}
        </ul>
      </div>

      <div class="tab-content" id="myTabContent">
        {
          claims("Can_View_MyWork") && (
            <div
          className={`tab-pane fade ${tabChange?.tabChangeState === "" ? "show active" : ""}`}
          id="myWork"
          role="tabpanel"
          aria-labelledby="my-work"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <WorkHeader report={report} action={action} />
              </div>
              <div className="col-lg-12">
                <div className="other-nav">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="">My Work</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="inspection-cate">
                  <div className="work-table-nav">
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work-new-requests"
                      onClick={() => {
                        handleNewWorkOrderRepotrt();
                        setOngoingCM(false);
                      }}
                      end
                    >
                      {/* /my-work/preventive-works/ongoing-work */}
                      <span style={{color: navColor ? "#D57D2A" : "", fontWeight: navColor ? "bold" : ""}}>New Requests</span>
                      {ticketTypePendingCount >= 0 && ticketCounts["ongoing"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: newS ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketTypePendingCount}
                        </span>
                      ) : null}
                    </NavLink>
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/new-cm-work"
                      onClick={() => {
                        handleOngoingWorkOrderRepotrt();
                        setNavColor(false);
                        setOngoingPM(false);
                        setOngoingCM(true);
                      }}
                    >
                      <span>New CM Work</span>
                      {ticketCounts["ongoing"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: ongoingCM ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["ongoing"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/new-pm-work"
                      onClick={() => {
                        handleOngoingWorkOrderRepotrt();
                        setNavColor(false);
                        setOngoingPM(true);
                        setOngoingCM(false);
                      }}
                    >
                      <span>New PM Work</span>
                      {ticketCounts["ongoing"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: ongoingPM ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["ongoing"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/cm-pending-closure"
                      onClick={() => {
                        handlePendingClosureWorkOrderRepotrt();
                        setNavColor(false);
                        setOngoingCM(false);
                      }}
                    >
                      <span>CM Pending Closure</span>
                      {ticketCounts["pendingclosure"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: pending ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["pendingclosure"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>

                    {claims("Can_View_RejectedWork") && (
                      <NavLink
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        to="/my-work/rejected-cm-work"
                        onClick={() => {
                          handleRejectedWorkOrderRepotrt();
                          setNavColor(false);
                          setOngoingCM(false);
                        }}
                      >
                        <span>Rejected CM Work</span>
                        {ticketCounts["rejected"] >= 0 ? (
                          <span
                            style={{
                              width: "50px",
                              height: "25px",
                              backgroundColor: "red",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderRadius: "36.5px",
                            }}
                          >
                            {ticketCounts["rejected"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    )}
                  </div>
                  <div className="work-table-tables">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        }
        
        {
          claims("Can_View_Request") && (
            <div
           className={`tab-pane fade ${tabChange?.tabChangeState === "request_work" ? "show active" : ""}`}
          id="requests"
          role="tabpanel"
          aria-labelledby="my-requests"
        >
          <div className="work-orders-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <RequestsHeader report={report} />
                </div>
                <div className="col-lg-12">
                  <RequestsNavbar />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="inspection-cate">
                    <Tab.Container
                      id="f3washroom-table"
                      defaultActiveKey={tab ? tab : "new-requests"}
                    >
                      <Nav
                        variant="pills"
                        className="f3washroom-table-nav mt-0"
                        style={{
                          display: "flex",
                          gap: "40px",
                          alignItems: "center",
                        }}
                      >
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              handleNewReport();
                              navigate("?tab=new-requests");
                              handleTabChange("new-requests");
                            }}
                            eventKey="new-requests"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            New Requests
                            {ticketTypePendingCount >= 0 ? (
                              <span
                                style={{
                                  width: "50px",
                                  height: "25px",
                                  backgroundColor:
                                    tabActive === "new-requests"
                                      ? "#D57D2A"
                                      : "#6C5B51",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                  borderRadius: "36.5px",
                                }}
                              >
                                {ticketTypePendingCount}
                              </span>
                            ) : null}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              handleApprovedReport();
                              navigate("?tab=approved-requests");
                              handleTabChange("approved-requests");
                            }}
                            eventKey="approved-requests"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            Approved Requests
                            {ticketTypeApprovedCount >= 0 ? (
                              <span
                                style={{
                                  width: "50px",
                                  height: "25px",
                                  backgroundColor:
                                    tabActive === "approved-requests"
                                      ? "#D57D2A"
                                      : "#6C5B51",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                  borderRadius: "36.5px",
                                }}
                              >
                                {ticketTypeApprovedCount}
                              </span>
                            ) : null}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              handlDeclinedReport();
                              navigate("?tab=declined-requests");
                              handleTabChange("declined-requests");
                            }}
                            eventKey="declined-requests"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            Declined Requests
                            {ticketTypeDeclinedCount >= 0 ? (
                              <span
                                style={{
                                  width: "50px",
                                  height: "25px",
                                  backgroundColor:
                                    tabActive === "declined-requests"
                                      ? "#D57D2A"
                                      : "#6C5B51",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                  borderRadius: "36.5px",
                                }}
                              >
                                {ticketTypeDeclinedCount}
                              </span>
                            ) : null}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="new-requests">
                          <div className="f3washroom-min-content request-tab-content">
                            <NewRequests
                              newRequests={newRequests}
                              setIsLoadingNew={setIsLoading}
                              isLoadingNew={isLoading}
                              tabActive={tabActive}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="approved-requests">
                          <div className="f3washroom-min-content request-tab-content">
                            <TabApprovedRequests
                              setIsLoadingNew={setIsLoading}
                              isLoadingNew={isLoading}
                              tabActive={tabActive}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="declined-requests">
                          <div className="f3washroom-min-content request-tab-content">
                            <TabDeclinedRequests
                              setIsLoadingNew={setIsLoading}
                              isLoadingNew={isLoading}
                              tabActive={tabActive}
                            />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        }
        
        <div
          className={`tab-pane fade ${tabChange?.tabChangeState === "cm_work" ? "show active" : ""}`}
          id="CMWork"
          role="tabpanel"
          aria-labelledby="cm-work"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <WorkHeader report={report} action={action} />
              </div>
              <div className="col-lg-12">
                <div className="other-nav">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="">CM Work</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="inspection-cate">
                  <div className="work-table-nav">
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/work-orders"
                      onClick={() => {
                        handleNewWorkOrderRepotrt();
                      }}
                      end
                    >
                      <span>New Work</span>
                      {ticketCounts["new"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: newS ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["new"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/work-orders/ongoing-work"
                      onClick={() => {
                        handleOngoingWorkOrderRepotrt();
                      }}
                    >
                      <span>Ongoing Work</span>
                      {ticketCounts["ongoing"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: ongoing ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["ongoing"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/work-orders/pending-closure"
                      onClick={() => {
                        handlePendingClosureWorkOrderRepotrt();
                      }}
                    >
                      <span>Pending Closure</span>
                      {ticketCounts["pendingclosure"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: pending ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["pendingclosure"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    <NavLink
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      to="/my-work/work-orders/closed-work"
                      onClick={() => {
                        handleClosedWorkOrderRepotrt();
                      }}
                    >
                      <span>Closed Work</span>
                      {ticketCounts["closed"] >= 0 ? (
                        <span
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: closed ? "#D57D2A" : "#6C5B51",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRadius: "36.5px",
                          }}
                        >
                          {ticketCounts["closed"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    {claims("Can_View_RejectedWork") && (
                      <NavLink
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        to="/my-work/work-orders/rejected-work"
                        onClick={() => {
                          handleRejectedWorkOrderRepotrt();
                        }}
                      >
                        <span>Rejected Work</span>
                        {ticketCounts["rejected"] >= 0 ? (
                          <span
                            style={{
                              width: "50px",
                              height: "25px",
                              backgroundColor: "red",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderRadius: "36.5px",
                            }}
                          >
                            {ticketCounts["rejected"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    )}
                  </div>
                  <div className="work-table-tables">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={`tab-pane fade ${tabChange?.tabChangeState === "pm_work" ? "show active" : ""}`}
          id="PMWork"
          role="tabpanel"
          aria-labelledby="pm-work"
        >
          <div className="work-orders-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <PreventWorkHeader report={report} />
                </div>
                <div className="col-lg-12">
                  <div className="other-nav">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="">Preventive Maintenance Work</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="inspection-cate">
                    <div className="work-table-nav">
                      <NavLink
                        to="/my-work/preventive-works"
                        onClick={() => {
                          handleNewWorkOrderRepotrt();
                        }}
                        end
                      >
                        New Work
                      </NavLink>
                      <NavLink
                        to="/my-work/preventive-works/ongoing-work"
                        onClick={() => {
                          handleOngoingWorkOrderRepotrt();
                        }}
                      >
                        Ongoing Work
                      </NavLink>
                      <NavLink
                        to="/my-work/preventive-works/pending-closure"
                        onClick={() => {
                          handlePendingClosureWorkOrderRepotrt();
                        }}
                      >
                        Pending Closure
                      </NavLink>
                      <NavLink
                        to="/my-work/preventive-works/closed-work"
                        onClick={() => {
                          handleClosedWorkOrderRepotrt();
                        }}
                      >
                        Closed Work
                      </NavLink>
                      <NavLink
                        to="/my-work/preventive-works/upcoming-work"
                        onClick={() => {
                          handleUpcomingWorkOrderRepotrt();
                        }}
                      >
                        Upcoming Work
                      </NavLink>
                    </div>
                    <div className="work-table-tables">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="work-orders-area"></div>
    </>
  );
};

export default MyWork;
