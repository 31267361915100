"use client";

import React, { useState, useEffect } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DashboardInfoModal from "../WorkOrders/Modal/DashboardInfoModal";
import usefetchData from "../../hooks/useFetchData";
import qs from "qs";
import { perfomanceStats } from "../../InfoData";

const dropdownButtonStyle = {
  border: "none",
  boxShadow: "0px 2px 10px 0px #00000026",
  transition: "box-shadow 0.3s ease",
};

const dropdownButtonHoverStyle = {
  ...dropdownButtonStyle,
  boxShadow: "0px 4px 15px 0px #00000040",
};

const periods = ["Current", "Last 7 days", "Last 30 days", "Last month"];

function StatsCard({ title, value, change, changeType, period }) {
  function roundToOneDecimal(number) {
    return Math.round(number * 10) / 10;
  }

  function transformAndRoundPercentage(percentage) {
    const numericValue = parseFloat(percentage.replace("%", ""));
    return roundToOneDecimal(numericValue);
  }

  const result = transformAndRoundPercentage(value);

  return (
    <div className="" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <p className="fs-22 fw-bold mb-2">
        {value === "undefined%" ? 0 : `${result}%`} Work
      </p>
      <p className="text-muted fs-16 small mb-2">{title}</p>
      <div className="small">
        <div className="d-flex align-items-center">
          {changeType === "increase" ? (
            <CallMadeIcon style={{ color: "#D57D2A", fontSize: "1rem" }} />
          ) : (
            <CallReceivedIcon style={{ color: "#6C5B51", fontSize: "1rem" }} />
          )}
          <span
            className={`ms-1 fs-14 ${
              changeType === "increase" ? "text-[#7C8DB5]" : "text-{#7C8DB5}"
            }`}
            style={{ color: "#00000066" }}
          >
            {change === "NaN%" ? 0 : change}
          </span>
        </div>

        <span className=" ms-1" style={{ color: "#00000066" }}>
          {period}
        </span>
      </div>
    </div>
  );
}

export default function PerformanceStats() {
  const [selectedPeriod, setSelectedPeriod] = useState("Current");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermPeriod, setSearchTermPeriod] = useState("");
  const [filteredPeriods, setFilteredPeriods] = useState(periods);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [filters, setFilters] = useState({
    // ticketType: "preventive",
    "filter.StartDate": null,
    "filter.EndDate": null,
    "filter.LocationIds": [],
  });

  const [modalOpen, setModalOpen] = useState(false);

  const { data: locations } = usefetchData(
    ["partLocation"],
    `/Locations/LocationsList`,
    {},
    "Couldn't get part locations data. Please try again!",
    true
  );

  const { data: performanceStats, isLoading } = usefetchData(
    ["work-performance-stats", filters],
    `/Dashboard/GetWorkPerformanceStats`,
    {
      params: filters,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get work count data",
    !!filters
  );
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [filteredLocations, setFilteredLocations] = useState(locations);

  useEffect(() => {
    const filtered =
      locations?.filter((location) =>
        location.locationName.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [];
    setFilteredLocations(filtered);
  }, [searchTerm, locations]);

  const handleLocationClick = (locationId) => {
    setSelectedLocations((prev) => {
      const newSelectedLocations = prev.includes(locationId)
        ? prev.filter((id) => id !== locationId)
        : [...prev, locationId];

      setFilters((prevFilters) => ({
        ...prevFilters,
        "filter.LocationIds": newSelectedLocations,
      }));

      return newSelectedLocations;
    });
  };

  const handleLocationClickAll = () => {
    setSelectedLocations(() => {
      const newSelectedLocations = []; // Clear all selected locations
  
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters["filter.LocationIds"]; // Remove the key entirely
        return updatedFilters;
      });
  
      return newSelectedLocations;
    });
  };

  const isSelected = (locationId) => selectedLocations.includes(locationId);

  useEffect(() => {
    const filtered = periods.filter((period) =>
      period.toLowerCase().includes(searchTermPeriod.toLowerCase())
    );
    setFilteredPeriods(filtered);
  }, [searchTermPeriod]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchChangePeriod = (e) => {
    setSearchTermPeriod(e.target.value);
  };

  const computeDateRange = (period) => {
    const today = new Date();
    let startDate = null;
    let endDate = null;

    // eslint-disable-next-line default-case
    switch (period) {
      case "Current":
        // No date range for current
        break;
      case "Last 7 days":
        endDate = today;
        startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "Last 30 days":
        endDate = today;
        startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "Last month":
        endDate = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        break;
    }

    return { startDate, endDate };
  };

  const handlePeriodSelection = (period) => {
    setSelectedPeriod(period);
    const newDateRange = computeDateRange(period);
    setDateRange(newDateRange);
  };

  useEffect(() => {
    const formatToLocalISOString = (date) => {
      if (!date) return null;
      const offset = date.getTimezoneOffset() * 60000; // Get offset in milliseconds
      const localDate = new Date(date.getTime() - offset); // Adjust to local time
      return localDate.toISOString().slice(0, 19); // Remove 'Z' at the end for local representation
    };

    const formatDifferenceISOString = (date) => {
      if (!date) return null;
      const diffDate = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
      const offset = diffDate.getTimezoneOffset() * 60000; // Get offset in milliseconds
      const localDate = new Date(diffDate.getTime() - offset); // Adjust to local time
      return localDate.toISOString().slice(0, 19); // Remove 'Z' at the end for local representation
    };

    setFilters((prevFilters) => ({
      ...prevFilters,
      "filter.StartDate": formatToLocalISOString(dateRange.startDate),
      "filter.EndDate": formatToLocalISOString(dateRange.endDate),
      "filter.PreviousStartDate": formatDifferenceISOString(
        new Date(new Date() - 30 * 24 * 60 * 60 * 1000)
      ),
      "filter.PreviousEndDate": formatDifferenceISOString(new Date()),

      "filter.CurrentStartDate": formatDifferenceISOString(
        new Date(new Date())
      ),
      "filter.CurrentEndDate": formatDifferenceISOString(
        new Date(new Date() + 30 * 24 * 60 * 60 * 1000)
      ),
    }));
  }, [dateRange]);

  return (
    <>
      <div style={{ padding: "17px 34px" }}>
        <div className="mb-4">
          <div className="d-flex align-items-center mb-2">
            <h5 className="mb-0 me-2 fs-20 fw-bold">
              Performance Stats - All Work
            </h5>
            {/* <InfoIcon style={{ color: '#ffa500', fontSize: '1rem' }} /> */}
            <div onClick={toggleModal} className="cursor-pointer">
              <img src="/info.svg" alt="" className="w-[24px] h-[24px]" />
            </div>
          </div>
          <div className="d-flex gap-3">
            <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === "period"
                    ? dropdownButtonHoverStyle
                    : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton("period")}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Period:</span>
                <span
                  className="fs-12 d-flex align-items-center gap-1 text-[##00000061]"
                  style={{ color: "#00000061" }}
                >
                  {selectedPeriod}{" "}
                  <DownIcon className="fs-12" style={{ color: "#000000" }} />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTermPeriod}
                    onChange={handleSearchChangePeriod}
                  />
                </div>
                <div className="dropdown-item-content">
                  {filteredPeriods.map((period, i) => (
                    <li
                      style={{
                        padding: "6px 0px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => handlePeriodSelection(period)}
                    >
                      {period}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
            <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === "location"
                    ? dropdownButtonHoverStyle
                    : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton("location")}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Locations:</span>
                <span
                  className="fs-12 d-flex align-items-center gap-1"
                  style={{ color: "#00000061" }}
                >
                  {selectedLocations.length === 0
                    ? "All"
                    : `${selectedLocations.length} selected`}{" "}
                  <DownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="dropdown-item-content">
                  <li onClick={handleLocationClickAll}>
                    <b>All</b>
                  </li>
                  <hr />
                  {filteredLocations?.map((location, i) => (
                    <li
                      style={{
                        padding: "4px 0",
                        fontSize: "14px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={location.id}
                      onClick={() => handleLocationClick(location.id)}
                    >
                      {isSelected(location.id) ? (
                        <CheckBoxIcon
                          style={{ color: "#D57D2A", marginRight: "4px" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          style={{
                            color: "#DFE0EB",
                            marginRight: "4px",
                            border: "1px",
                          }}
                        />
                      )}
                      {location.locationName}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="row gy-4">
          <div
            className="col-md-6 pe-md-3"
            style={{ borderRight: "1px solid #dee2e6" }}
          >
            <h6
              className=" mb-3 fw-bold text-[#00000066]"
              style={{ color: "#00000066" }}
            >
              MY LOCATIONS
            </h6>
            <hr />
            <StatsCard
              title="Closed on time"
              value={
                `${performanceStats?.myLocationStats?.workClosedOnTimePercentage}%` ??
                "__"
              }
              change={
                `${
                  Math.round(
                    (performanceStats?.myLocationStats
                      ?.currentWorkClosedOnTimePercentage -
                      performanceStats?.myLocationStats
                        ?.previousWorkClosedOnTimePercentage) *
                      100
                  ) / 100
                }%` ?? "__"
              }
              //change="+1.01%"
              changeType={
                performanceStats?.myLocationStats
                  ?.currentWorkClosedOnTimePercentage -
                  performanceStats?.myLocationStats
                    ?.previousWorkClosedOnTimePercentage >
                0
                  ? "increase"
                  : "decrease"
              }
              period="Last 30 days"
            />
            <hr className="my-0" />
            <StatsCard
              title="Not closed on time"
              value={
                `${performanceStats?.myLocationStats?.workNotClosedOnTimePercentage}%` ??
                "__"
              }
              change={
                `${
                  Math.round(
                    (performanceStats?.myLocationStats
                      ?.currentWorkNotClosedOnTimePercentage -
                      performanceStats?.myLocationStats
                        ?.previousWorkNotClosedOnTimePercentage) *
                      100
                  ) / 100
                }%` ?? "__"
              }
              changeType={
                performanceStats?.myLocationStats
                  ?.currentWorkNotClosedOnTimePercentage -
                  performanceStats?.myLocationStats
                    ?.previousWorkNotClosedOnTimePercentage >
                0
                  ? "increase"
                  : "decrease"
              }
              period="Last 30 days"
            />
          </div>
          <div className="col-md-6 ps-md-3">
            <h6 className=" mb-3 fw-bold" style={{ color: "#00000066" }}>
              OVERALL
            </h6>
            <hr />
            <StatsCard
              title="Closed on time"
              value={
                `${performanceStats?.overallStats?.workClosedOnTimePercentage}%` ??
                "__"
              }
              change={
                `${
                  Math.round(
                    (performanceStats?.overallStats
                      ?.currentWorkClosedOnTimePercentage -
                      performanceStats?.overallStats
                        ?.previousWorkClosedOnTimePercentage) *
                      100
                  ) / 100
                }%` ?? "__"
              }
              changeType={
                performanceStats?.overallStats
                  ?.currentWorkClosedOnTimePercentage -
                  performanceStats?.overallStats
                    ?.previousWorkClosedOnTimePercentage >
                0
                  ? "increase"
                  : "decrease"
              }
              period="Last 30 days"
            />
            <hr className="my-0" />
            <StatsCard
              title="Not closed on time"
              value={
                `${performanceStats?.overallStats?.workNotClosedOnTimePercentage}%` ??
                "__"
              }
              change={
                `${
                  Math.round(
                    (performanceStats?.overallStats
                      ?.currentWorkNotClosedOnTimePercentage -
                      performanceStats?.overallStats
                        ?.previousWorkNotClosedOnTimePercentage) *
                      100
                  ) / 100
                }%` ?? "__"
              }
              changeType={
                performanceStats?.overallStats
                  ?.currentWorkNotClosedOnTimePercentage -
                  performanceStats?.overallStats
                    ?.previousWorkNotClosedOnTimePercentage >
                0
                  ? "increase"
                  : "decrease"
              }
              period="Last 30 days"
            />
          </div>
        </div>
      </div>
      <DashboardInfoModal
        show={modalOpen}
        toggleModal={toggleModal}
        infoData={perfomanceStats}
      />
    </>
  );
}
