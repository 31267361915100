import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { setTabChangeState } from "../../../redux/woCostSlice";
const OtherPageNav = () => {

  const dispatch = useDispatch()
  const tabChange = useSelector((state) => state.woCost);

  console.log("tbt", tabChange)

  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {
          tabChange?.tabChangeState === "pm_work" ? <li>
          <Link to="/my-work/preventive-works" onClick={() => {
             dispatch(setTabChangeState("pm_work"));
          }}>PM Work</Link>
        </li> : <li>
          <Link to="/my-work/work-orders" onClick={() => {
             dispatch(setTabChangeState("cm_work"));
          }}>CM Work</Link>
        </li>
        }
        
        <li>
          <Link>Add Work Orders</Link>
        </li>
      </ul>
    </div>
  );
};

export default OtherPageNav;
