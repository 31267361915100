import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../Assets/Icons/AddIcon";
import DownIcon from "../../Assets/Icons/DownIcon";
import exportToExcelMain from "../../utls/exportToExecelMain";
import { useDispatch, useSelector } from "react-redux";
import { resetForm } from "../../redux/formSlice";

const WorkHeader = ({ report, action }) => {
  const dispatch = useDispatch();
  const hancleClick = () => {
    dispatch(resetForm());
    if (localStorage.getItem("wOClick") === null) {
    } else {
      localStorage.removeItem("wOClick");
      localStorage.removeItem("selectedInfoDets");
      localStorage.removeItem("selectedFiles");
      localStorage.removeItem("selectedInfo");
    }
  };

  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      return claimsArray.includes(claimValue);
    } else {
      return false;
    }
  };

  const tabChange = useSelector((state) => state.woCost);

  return (
    <div className="work-header">
      <div className="fs-20">
        {tabChange?.tabChangeState === "cm_work"
          ? "Corrective Maitenance (CM) Work"
          : "My Work"}
      </div>
      <div className="dropdown select-dropdown">
        <button className="select-title" data-bs-toggle="dropdown">
          <span className="fs-15 d-flex align-items-center gap-2">
            Actions <DownIcon />
          </span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end select-menu">
          {claims("Can_Add_Ticket_Portal") &&
            (tabChange?.tabChangeState === "cm_work" ? (
              <li>
                <Link to="/work-orders/add-work-order" >
                  <AddIcon /> New CM Work
                </Link>
              </li>
            ) : (
              <>
                {/* <li>
                <Link to="/work-orders/add-work-order" onClick={hancleClick}>
                  <AddIcon /> New Work Order
                </Link>
              </li> */}
                <li>
                  <Link to="/requests/new-request">
                    <AddIcon /> New Requests
                  </Link>
                </li>
                <li>
                  <Link to="/work-orders/add-work-order" onClick={hancleClick}>
                    <AddIcon /> New CM Work
                  </Link>
                </li>
                {/* <li>
                <Link to="/preventive-works/create-pm-work">
                  <AddIcon /> New PM Work
                </Link>
              </li> */}
              </>
            ))}

          {tabChange?.tabChangeState === "cm_work" ? (
            <li onClick={() => exportToExcelMain(report, action)}>
              <Link to="#">
                <AddIcon /> Export to Excel
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default WorkHeader;
