import React, { useEffect, useState } from "react";
import { Accordion, Nav, Tab } from "react-bootstrap";
import QuotesDownload from "./QuotesDownload";
import ApproveCostsModal from "../../WorkOrders/Modal/ApproveCostsModal";
import ApproveCostsSecandModal from "../../WorkOrders/Modal/ApproveCostsSecandModal";
import ApprovedPending from "./WorkCosting/ApprovedPending";
import usefetchData from "../../../hooks/useFetchData";
import { useParams } from "react-router-dom";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";
// import toast from "react-hot-toast";

const ViewWorkCosting = () => {
  const [approveCostsModalShow, setApproveCostsModalShow] = useState(false);
  const [secandCostsShow, setSecandCostsShow] = useState(false);
  // const [approvalStatus, setApprovalStatus] = useState("PENDING APPROVAL");

  const { id } = useParams();

  const ticketId = Number(id);
  const { data: ticketSummary, isLoading } = usefetchData(
    ["costing-summary", ticketId],
    `/Tickets/GetTicketCostSummaryById/${ticketId}`,
    {},
    "Couldn't get ticket summary",
    true
  );

  const quotes = ticketSummary?.costDetails?.quoteItemList;
  const approvalStatus = ticketSummary?.costDetails?.approvalLevelStateName;

  const { mutate: approve, isLoading: isApproving } = useMutateData({
    url: `Tickets/ApproveTicketCosting`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      toast.success("Costing approved");
      setApproveCostsModalShow(false);
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["costing-summary", ticketId]],
  });

  const handleApproveCosts = () => {
    const requestBody = {
      costingBasisGroupId: 5,
      comments: "approved just now",
      isApproved: true,
    };
    approve(requestBody);
  };

  const handleRecallQuote = () => {
    setSecandCostsShow(false);
  };

  const handleRejectCosts = () => {
    const requestBody = {
      costingBasisGroupId: 0,
      comments: "",
      isApproved: false,
    };
    approve(requestBody);
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">WORK COST DETAILS</div>
          <div className="text-end">
            <div className="fs-13 fw-bold">
              STATUS: {""}
              <button className="primary-text fw-bold">
                { approvalStatus?.toUpperCase()}
              </button>
            </div>
            {approvalStatus !== "Not Approved" && (
              <div className="fs-13 fw-bold pt-1">
                By: {ticketSummary?.costDetails?.approvedRejectedBy}
              </div>
            )}
          </div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="request-box pe-0">
              <Accordion.Header className="request-box-title pe-3">
                <div className="fs-13 fw-medium">COST INFORMATION</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <Tab.Container defaultActiveKey="first">
                  <Nav variant="pills" className="under-line-none pe-3">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Quote Line Items</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Quote Document</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="first" className="pe-3">
                      <ApprovedPending
                        setApproveCostsModalShow={setApproveCostsModalShow}
                        quotes={quotes}
                        approvalStatus={approvalStatus}
                      />
                      {/* {approvalStatus === "REJECTED" && <Rejected />} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <QuotesDownload documentData={ticketSummary} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <ApproveCostsModal
        show={approveCostsModalShow}
        onHide={() => setApproveCostsModalShow(false)}
        // handleApproveCosts={handleApproveCosts}
        // handleRejectCosts={handleRejectCosts}
        isLoading={isApproving}
        data={ticketSummary?.costDetails}
      />
      <ApproveCostsSecandModal
        setShowApproveCostsContent={() => approvalStatus}
        show={secandCostsShow}
        onHide={() => setSecandCostsShow(false)}
        handleRecallQuote={handleRecallQuote}
        handleRejectCosts={handleRejectCosts}
      />
    </>
  );
};

export default ViewWorkCosting;
