import React from "react";

const ApprovedPending = ({
  setApproveCostsModalShow,
  quotes,
  approvalStatus,
}) => {
  const company = localStorage.getItem("company");
  const companyObj = company && JSON.parse(company);
  const currency = companyObj.currency;

  // Function to calculate the total cost
  const calculateTotalCost = () => {
    return quotes?.reduce((total, part) => {
      return total + part.quantity * part.unitCost;
    }, 0);
  };

  // Calculate the total cost
  const totalCost = calculateTotalCost();

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) return text;
    return text?.substring(0, maxLength - 3) + "...";
  };

  return (
    <>
      <div className="submit-quote-content">
        <div className="d-flex align-items-center justify-content-end flex-wrap pb-3">
          {approvalStatus == "Approved" || approvalStatus == "Rejected" ? (
            <div></div>
          ) : (
            <button
              className="fs-15 fw-bold pt-3 pt-md-0"
              style={{ color: "#D57D2A" }}
              onClick={() => setApproveCostsModalShow(true)}
            >
              APPROVE/ REJECT
            </button>
          )}
        </div>
        <div
          className="table-responsive-sm request_table"
          style={{ maxWidth: "100%" }}
        >
          <table className="table newTable">
            <thead>
              <tr>
                <th scope="col">Item Description</th>
                <th scope="col">Quantity</th>
                <th scope="col">Amount</th>
                <th scope="col" className="text-end">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {quotes?.map((part, index) => (
                <>
                  <tr key={index} style={{ height: "55px" }}>
                    <td
                    className="hover-text newTD"
                          style={{ width: "310px" }}
                    >
                      <div>
                         {truncateText(part.description, 38)}
                         <span class="tooltiptext">{part.description}</span>
                      </div>
                    </td>
                    <td>{part.quantity}</td>
                    <td>{part.unitCost}</td>
                    <td className="text-end" style={{ minWidth: "200px" }}>
                      {part.quantity * part.unitCost}
                    </td>
                  </tr>

                  {part.remarks === "" ? (
                    <tr
                      style={{
                        position: "relative",
                        paddingBottom: "30px",
                      }}
                    >
                      <td
                        style={{
                          position: "absolute",
                          width: "92%",
                          top: "-30px",
                          color: "rgba(22,33,40,0.38)",
                          fontSize: "14px",
                        }}
                        className="hover-text"
                      >
                        <span
                          style={{
                            zIndex: "10000",
                            width: "fitContent",
                            whiteSpace: "wrap",
                            textOverflow: "ellipsis",
                            height: "fitContent",
                            color: "rgba(27,33,40,0.38)",
                            // maxHeight: "100px",
                          }}
                        >
                          No Comment
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr
                      className="hover-text"
                      style={{
                        position: "relative",
                        paddingBottom: "30px",
                      }}
                    >
                      <td
                        style={{
                          position: "absolute",
                          width: "92%",
                          top: "-30px",
                          color: "rgba(22,33,40,0.38)",
                          fontSize: "14px",
                        }}
                        className="hover-text"
                      >
                        {truncateText(part.remarks, 100)}
                        <span
                          class="tooltiptext"
                          style={{
                            zIndex: "10000",
                            width: "fitContent",
                            whiteSpace: "wrap",
                            textOverflow: "ellipsis",
                            height: "fitContent",
                            // maxHeight: "100px",
                          }}
                        >
                          {part.remarks}
                        </span>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className="d-flex align-items-center justify-content-between flex-wrap fs-20 fw-bold mb-5"
          style={{ color: "#00000061" }}
        >
          <div className="">TOTAL COST</div>
          <div className="">
            {currency} {totalCost}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedPending;
