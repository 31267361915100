import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { REQUEST_ARRIVAL } from "../../../utls/constants";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { useNavigate } from "react-router-dom";

const AddDiagnosisRedirectModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const navigate = useNavigate()
  const onSubmit = () => {
    props.onHide();
    navigate(`/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/diagnosis`)

  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Request Closure Error</span>
            <span className="fs-14 ps-3">
            Diagnosis information missing
            </span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <FaCircleCheck style={{ color: "#D57D2A", fontSize: "70px" }} />
              <div
                className="fs-20 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#72777A",
                }}
              >
                Diagnosis information is missing. Please provide the information before proceeding to request closure. 
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {loading ? (
              <button className="delate-btn" disabled={loading}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            ) : (
              <button className="delate-btn" onClick={onSubmit}>
                Add Diagnosis Info
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddDiagnosisRedirectModal;
