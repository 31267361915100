import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PiWarningCircleFill } from "react-icons/pi";
import { Spinner } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";

const DashboardInfoModal = ({ show, toggleModal, infoData }) => { 

  return (
    <>
    <Modal
      show={show}
      onHide={toggleModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div>
            <span className="fs-16 fw-bold">{infoData?.title}</span>
            <span className="fs-14 ps-3">Extra information regarding the section</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              overflow: "auto",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
             
              <div
                className="fs-20 fw-medium"
                style={{ lineHeight: "1.4", marginTop: "10px" }}
              >
                {
                  infoData?.list?.map((item,index) => {
                    return(
                      <div key={index} style={{marginBottom: "1rem", position: "relative"}}>
                        <GoDotFill size={10} style={{position: "absolute", top: "0.7rem", left: "-3%"}}/>
                        <span style={{fontSize: "16px", fontWeight: "500"}}><b>{item?.point}:</b> {item?.description}</span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            
            <button className="delate-btn" onClick={toggleModal}>
            Okay
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default DashboardInfoModal;
