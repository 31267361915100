import React, { useEffect, useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import ActivityModal from "../Modal/ActivityModal";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";

const ViewWorkSide = () => {
  const [activityShow, setActivityShow] = React.useState(false);
  const [eventKey, setEventKey] = React.useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [timeRemaining, setTimeRemaining] = useState(0); //Time in seconds
  //get url by using useLocation
  const location = useLocation();
  /*const endDate = new Date(ticketSummary.currentSLAEndDate);
  console.log(endDate);
  // Get the current date and time
  const now = new Date();
  // Calculate the difference in milliseconds
  const timeDifferenceInMillis = endDate - now;
  // Convert milliseconds to seconds
  const timeDifferenceInSeconds = Math.floor(timeDifferenceInMillis / 1000);
  // Set the time remaining in state
  setTimeRemaining(timeDifferenceInSeconds);*/
  useEffect(() => {
    if (ticketSummary?.workOrderSummary?.currentSLAEndDate) {
      // Convert endDate to a Date object
      console.log("Date");
      const endDate = new Date(
        ticketSummary.workOrderSummary.currentSLAEndDate
      );

      // Get the current date and time
      const now = new Date();

      // Calculate the difference in milliseconds
      const timeDifferenceInMillis = endDate - now;

      // Check if the time difference is negative
      if (timeDifferenceInMillis < 0) {
        setTimeRemaining(0);
      } else {
        // Convert milliseconds to seconds
        const timeDifferenceInSeconds = Math.floor(
          timeDifferenceInMillis / 1000
        );
        // Set the time remaining in state
        setTimeRemaining(timeDifferenceInSeconds);
      }
    }
  }, [ticketSummary?.workOrderSummary?.currentSLAEndDate]);

  //Confirm if below will have sideeffect when we update context
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    } else if (
      location.pathname === `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/diagnosis`
    ) {
      setEventKey("4")
    }else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/confirm-work`
    ) {
      setEventKey("5");
    }
  }, [location, ticketSummary]);
  useEffect(() => {
    if (timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(seconds / 60) % 60;
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(
      remainingMinutes
    ).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };
  const projectedParts = ticketSummary?.assetSummary?.projectedPartList?.filter(
    (item) => item.partName !== null
  );
  const checkList = ticketSummary?.assetSummary?.checklistNameList;

  return (
    eventKey && (
      <>
        <div className="order-sidebar-content p-0">
          <div className="p-4">
            <div className="fs-13 fw-medium">WORK ORDER INFORMATION</div>
            <div className="washroom-sidebar mt-3 align-items-end">
              <div>
                <div
                  className="fs-16 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Work Order
                </div>
                <div className="fs-40 fw-bold" style={{ color: "#D57D2A" }}>
                  {ticketSummary?.workOrderSummary?.ticketRef}
                </div>
              </div>
              <div>
                <div
                  className="fs-16 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Location
                </div>
                <div className="fs-14 fw-bold" style={{ color: "#D57D2A" }}>
                  {ticketSummary?.workOrderSummary?.locationName}
                </div>
              </div>
              <div>
                <button
                  onClick={() => setActivityShow(true)}
                  className="fs-16 fw-bold "
                  style={{
                    color: "#D57D2A",
                    textDecoration: "underline",
                    marginTop: "25px",
                  }}
                >
                  Activity Tracker
                </button>
                <ActivityModal
                  show={activityShow}
                  onHide={() => setActivityShow(false)}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className="time-order-sidebar fs-16 fw-bold">
              Time Remaining {formatTime(timeRemaining)}
            </div>
          </div>

          <Accordion
            defaultActiveKey={eventKey}
            className="view-work-sidebar pt-0"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <NavLink
                  to={`/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`}
                  end
                >
                  Summary
                </NavLink>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row pb-2">
                  <div className="col-12">
                    <div className="fs-14 fw-medium">Title</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {ticketSummary?.workOrderSummary?.ticketTitle}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="fs-14 fw-medium">Description:</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {ticketSummary?.workOrderSummary?.ticketDescription}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Category of Work</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {ticketSummary?.workOrderSummary?.categoryOfWorkName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Priority</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {ticketSummary?.workOrderSummary?.priorityName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Primary Team Assigned</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {ticketSummary?.workOrderSummary?.assignedTeamName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">User Assigned</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {ticketSummary?.workOrderSummary?.assignedUserName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Completion Details</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      Is signature required:{" "}
                      {ticketSummary?.workOrderSummary?.technicianSignature ===
                      true
                        ? "YES"
                        : "NO"}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Requires PTW</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      Is PTW required:{" "}
                      {ticketSummary?.workOrderSummary?.requiresPTW === true
                        ? "YES"
                        : "NO"}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <NavLink
                  to={`/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`}
                >
                  Assets
                </NavLink>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row pb-2">
                  <div className="col-12">
                    <div className="fs-14 fw-medium">Projected Parts</div>
                    {projectedParts?.length === 0
                      ? "N/A"
                      : projectedParts?.map((item) => (
                          <div
                            key={item.id}
                            className="fs-14 fw-medium"
                            style={{ color: "#6C5B51" }}
                          >
                            {item.partName} -{" "}
                            {item.partSerialNumber === null
                              ? ""
                              : item.partSerialNumber}{" "}
                            {item.quantityProjected} Pieces
                          </div>
                        ))}
                  </div>
                  {/*<div className="col-12 pt-2">
                    <div className="fs-14 fw-medium">Checklists</div>
                    {checkList.map((item) => (
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "#6C5B51" }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>*/}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <NavLink
                  to={`/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`}
                >
                  Costings
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <NavLink
                  to={`/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`}
                >
                  Job Cards
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
      </>
    )
  );
};

export default ViewWorkSide;
