import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./formSlice";
import pmFormReducer from "./pmFormSlice";
import assetReducer from "./assetSlice";
import locationReducer from "./locationSlice";
import woCostReducer from "./woCostSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, formReducer);
const persistedPmReducer = persistReducer(persistConfig, pmFormReducer)
const persistedAssetReducer = persistReducer(persistConfig, assetReducer)

const store = configureStore({
  reducer: {
    form: persistedReducer,
    pmform: persistedPmReducer,
    asset: assetReducer,
    location:locationReducer,
    woCost: woCostReducer
  },
});

const persistor = persistStore(store);

export { store, persistor };
